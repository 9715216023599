import { animate, query, stagger, style, transition, trigger } from "@angular/animations";
import { Component, ContentChildren, Input, OnInit, QueryList } from "@angular/core";
import { FalainaaccordianItemComponent } from "./falainaaccordian-item/falainaaccordian-item.component";

@Component({
  selector: "falaina-accordian",
  templateUrl: "./falainaaccordian.component.html"
})

export class FalainaaccordianComponent implements OnInit {

  @Input()
  accordianHeight : any;
  @ContentChildren(FalainaaccordianItemComponent) items: QueryList<FalainaaccordianItemComponent>;
  constructor() { }

  ngOnInit() {
  }

  ngAfterContentInit() {
    this.items.toArray().forEach((item) => {
      item.onToggle.subscribe(() => {
        this.itemToggleHandler(item);
      });
    });
  }

  itemToggleHandler(item: FalainaaccordianItemComponent) {
  }
}
