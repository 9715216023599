import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    ViewChild,
    TemplateRef,
    ContentChild
  } from "@angular/core";
  import { FalainaStepperContentComponent } from "./falainasteppercontent";
  
  @Component({
    selector: "falaina-step-item",
    template: `
    <div class="f_stepper_item " >
      <ng-content></ng-content>
    </div>
    `,
  })
  export class FalainaStepComponent implements OnInit {

    @Input()
    label: string;

@Input()
stepperInFullPage : boolean =false;
    // @Input()
    // isValid: boolean = false;
  
    @Input()
    isActive: boolean;

    @Input()
    isnextStepValid : boolean = false;

    @Input()
    title : string = "Step1";

    @Input()
    isDisabled: boolean = false;
  
    @ContentChild(FalainaStepperContentComponent)
    bodyComponent: FalainaStepperContentComponent;
  
    constructor() {}
  
    private i:number = 0;

    ngOnInit(): void {
      this.i = this.i+1;
      var liston, index;
    liston = document.getElementsByClassName("k-step-text");
    
    
     
      setTimeout(() => {
        for (index = 0; index < liston.length; ++index) {
          liston[index].setAttribute('translate','');
        }   
      }, 1000);
    }
  
    ngAfterViewInit(): void{
      
    }
  }