export const themes = ["green10",
"darkGreen20",
"teal10",
"cyan30",
"lightBlue30",
"blue20",
"darkBlue10",
"violet10",
"purple10",
"magenta10",
"lightPink10",
"pink10",
"pinkRed10",
"red10",
"darkRed20",
"orange10",
"orange30",
"orangeYellow20",
"gray30",
"gray20"];