export enum ControlTypeEnum
{
    TextBox="textbox",
    NumericTextBox = "numerictextbox",
    Radio="radio",
    Checkbox="checkbox",
    AutoComplete="autocomplete",
    DropDown="dropdown",
    MultiSelect="multiselect",
    TextArea="textarea",
    FormArray="formarray",

    Input="input",

    DatePicker="datepicker"
    
   
}

export enum ValidatorEnum 
{
    Email="email",
    MinLength="minlength",
    MaxLength="maxlength",
    Pattern="pattern",
    maxDate="maxdate",
    minDate="mindate"
}