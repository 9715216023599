import {  HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { customHttpclient } from '@services/shared/customhttpclient';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DynamicCRUDService {
  getChildFormDetail: any;
  listURL: any;

  constructor( private customHttp : customHttpclient ) {

  }
   headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');
  
  get(url:string):Observable<any>
  { 
    let params = new HttpParams();
    return this.customHttp.getdomainapi(url,"", params);
  //  return this._httpClient.get<any>(this.baseUrl+url,{headers:this.headers});

  }
  
  doesExist(url:string,tenatCode:string):Observable<any>
  { 
    let params = new HttpParams().set('tenantCode',tenatCode);
    return this.customHttp.getdomainapi(url,"", params);


  }

  post(url : string,postData={})
  { 
    let params = new HttpParams();

   
    return this.customHttp.postdomainapi(url,"",postData, params);

   // return this._httpClient.post<any>(this.baseUrl+url,postData);

  }
 
  put(url : string,putData={}):Observable<any>
  { 

    let params = new HttpParams();
    return this.customHttp.postdomainapi(url,"",putData, params);

   // return this._httpClient.post<any>(this.baseUrl+url,putData);

  }
  delete(url:string):Observable<any>
  {
    let params = new HttpParams();
    return this.customHttp.deletedomainapi(url,"",null, params);
   // return this._httpClient.delete<any>(this.baseUrl+url);
  }
}
