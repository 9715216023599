import { Component, OnInit, SimpleChange, ChangeDetectorRef, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppConfig } from '@models/config/appconfig';
import { AppConfigService } from '@services/shared/app-config-service';
import { Observable } from 'rxjs/internal/Observable';
import { FalainaloadingService } from '../falainaloading.service';

@Component({
  selector: 'falaina-loader',
  templateUrl: './falainaloading.component.html',
  styleUrls: ['./falainaloading.component.css']
})
export class FalainaloadingComponent implements OnInit {
  showLoader: boolean = false;
  @Input()
 showBackGround:boolean=false;
 enableUIEvent:boolean=false
  allowEventOnLoading: boolean=true;

  constructor(private loader: FalainaloadingService, private router: Router, 
    private cdr:ChangeDetectorRef,private config : AppConfigService) {
      var a:Observable<AppConfig>= this.config.loadConfigurations();
      a.subscribe(x=>{
      this.allowEventOnLoading= x?.AllowEventsOnLoading
      })
    this.showLoader = false
      this.enableUIEvent=false
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.themeConfig()
      }
    });
  }

  ngOnInit(): void {
    this.themeConfig()
    
  }

  themeConfig() {
    if (this.router.url.includes('pam/pamaccess') || this.router.url.includes('pam/pasrdp')) {
      this.showLoader = false;
    } else if (!this.router.url.includes('pam/pamaccess') || !this.router.url.includes('pam/pasrdp')) {
      this.loader.showLoaderEvent.subscribe((x: boolean) => {
        this.showLoader = x;
        this.enableUIEvent=x;
        this.cdr.detectChanges();
      })
    }
  }

  
}
