// Core imports
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { DatePipe } from "@angular/common";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, APP_INITIALIZER } from "@angular/core";

// Application imports
import { AppComponent } from "./app.component";
import { AuthInterceptorService, RequestInterceptor } from "./core/authentication/auth-interceptor.service";

import 'hammerjs';
import { AuthModule, OidcConfigService } from "angular-auth-oidc-client";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule,MissingTranslationHandler, MissingTranslationHandlerParams} from "@ngx-translate/core";
 
import { FalainaCoreModule } from "./core/core.module";
import { NotificationService, NotificationModule } from '@progress/kendo-angular-notification';
import { AngularSvgIconModule } from "angular-svg-icon";
import { FalainaloadingsymbolModule } from "./shared/customcontrols/falainaloadingsymbol/falainaloadingsymbol.module";
import { FalainapopupModule } from './shared/customcontrols/falainapopup/falainapopup.module';
import { FalainapipesModule } from "./shared/pipes/pipe.module";
import { MFASharedModule } from "./shared/mfashared/mfashared.module";
import { AppConfigService } from "@services/shared/app-config-service";
import { FormsModule } from "@angular/forms";


export function configureAuth(oidcConfigService: OidcConfigService,appConfig:AppConfigService) {
  return () =>{
        appConfig.loadConfigurations().subscribe(x=>{
          oidcConfigService.withConfig(x.OidcConfig);
        })
       
 // var passedConfig =environment.OidcConfig as OpenIdConfiguration  
   
  }
}
 export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  /**
   *
   */
  private isDevinProgress : boolean = true;
  constructor(appConfig:AppConfigService) {
    appConfig.loadConfigurations().subscribe(x=>{
      this.isDevinProgress = x.DevInProgress
    })
  }
    handle(params: MissingTranslationHandlerParams) {
      
      if(this.isDevinProgress)
        // return 'Missing text';
        // else
        return params.key;
    }
}


@NgModule({
  declarations: [
    AppComponent,
  ],

  imports: [
    FormsModule,
    FalainaCoreModule,
    FalainapipesModule,
    AuthModule.forRoot(),
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
     AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
         useClass: MyMissingTranslationHandler,
         deps:[AppConfigService]
        },
    }),
 
    FalainaloadingsymbolModule,
    NotificationModule,
    FalainapopupModule,
    MFASharedModule,
   
  ],
  exports : [
    TranslateModule
  ]  
  ,
  providers: [
    NotificationService,
     OidcConfigService,
     {
       provide: APP_INITIALIZER,
       useFactory: configureAuth,
       deps: [OidcConfigService,AppConfigService],
       multi: true,
     },
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: AppConfigService) => () => configService.loadConfigurations().toPromise(),
      deps: [AppConfigService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS,useClass: RequestInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
     
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
