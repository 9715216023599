import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FalainafilterComponent } from './falainafilter.component';
import { FalainatextboxModule } from '../falainatextbox/falainatextbox.module';
import {  ButtonsModule } from '@progress/kendo-angular-buttons';
import { FalainabuttonModule } from '../falainabutton/falainabutton.module';
import { FalainapopupModule } from '../falainapopup/falainapopup.module';
import { FalainadropdownlistModule } from '../falainadropdownlist/falainadropdownlist.module';
import { FalainalabelModule } from '../falainalabel/falainalabel.module';
import { FalainaSharedModule } from 'app/shared/shared.module';
import { FalainacontextmenuModule } from '../falainacontextmenu/falainacontextmenu.module';
import { FalainadropdownbuttonModule } from '../falainadropdownbutton/falainadropdownbutton.module';
import { CustomFilterComponent } from './custom-filter/custom-filter.component';
import { CustomFilterFieldComponent } from './custom-filter/custom-filter-field/custom-filter-field.component';
import { FalainatogglebuttonModule } from '../falainatogglebutton/falainatogglebutton.module';
import { FalainadatepickerModule } from '../falainadatepicker/falainadatepicker.module';
import { FalainamessageboxModule } from '../falainamessagebox/falainamessagebox.module';





@NgModule({
  declarations: [
    FalainafilterComponent,
    CustomFilterComponent,
    CustomFilterFieldComponent
  ],
  imports: [
    CommonModule,FalainatextboxModule,FalainamessageboxModule,
    ButtonsModule,FalainabuttonModule,
    FalainapopupModule,FalainadropdownlistModule,
    FalainalabelModule,FalainaSharedModule,FalainacontextmenuModule,
    FalainadropdownbuttonModule,FalainatogglebuttonModule,FalainadatepickerModule
  
  
  ],
  exports:[FalainafilterComponent,CustomFilterComponent]
})
export class FalainafilterModule { }
