import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FalainadropdownlistComponent } from './falainadropdownlist.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { AvatarModule } from '@progress/kendo-angular-layout';



@NgModule({
  declarations: [FalainadropdownlistComponent],
  imports: [
    CommonModule,DropDownsModule,FormsModule,ButtonModule,AvatarModule
  ],
  exports : [FalainadropdownlistComponent]
})
export class FalainadropdownlistModule { }
