import {  HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { customHttpclient } from './shared/customhttpclient';

@Injectable({
  providedIn: 'root'
})
export class UploadserviceService {

  constructor( private customHttp:customHttpclient) { }

  public GetByUploadConfigurationId(id: any) {

    let params = new HttpParams().set('id', id)
    return this.customHttp.getdomainapi("PresentationUploadConfig","GetByUploadConfigurationId", params);
  
    // return this.http.get(this.baseUrl + "PresentationUploadConfig/GetByUploadConfigurationId?id=" + id).pipe(
    //   map(
    //     res => res
    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }

  public GetTemplateList(uploadConfigId: any) {
    let params = new HttpParams().set('uploadConfigId', uploadConfigId)
    return this.customHttp.getdomainapi("PresentationUploadFieldMapper","GetTemplateList", params);
    // return this.http.get(this.baseUrl + "PresentationUploadFieldMapper/GetTemplateList?uploadConfigId=" + uploadConfigId).pipe(
    //   map(
    //     res => res
    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }

  public GetUploadProgressByUploadConfigId(configId: any) {
    let params = new HttpParams().set('configId', configId)
    return this.customHttp.getdomainapi("PresentationUploadProgress","GetUploadProgressByUploadConfigId", params);
    // return this.http.get(this.baseUrl + "PresentationUploadProgress/GetUploadProgressByUploadConfigId?configId=" + configId).pipe(
    //   map(
    //     res => res
    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }

  public SaveUploadProgress(uploadProgress): Observable<any> {
    let params = new HttpParams();
    return this.customHttp.postdomainapi("PresentationUploadProgress","SaveUploadProgress",uploadProgress, params);
    // return this.http.post(this.baseUrl + "PresentationUploadProgress/SaveUploadProgress/", uploadProgress).pipe(
    //   map(
    //     res => res
    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }

  public GetRecordCount(uploadedFile: any): any {
    let params = new HttpParams();
    return this.customHttp.postdomainapi("PresentationUploadProgress","GetRecordCount",uploadedFile, params);
    // var eObjects = this.http.post(this.baseUrl + "PresentationUploadProgress/GetRecordCount/", uploadedFile).pipe(
    //   map(
    //     res => res
    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
    // return eObjects

  }
  
  public ValidateUploadFile(uploadedFile: any): any {

    let params = new HttpParams();
    return this.customHttp.postdomainapi("PresentationUploadProgress","ValidateUploadFile",uploadedFile ,params);
  }
}
