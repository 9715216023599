import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { FalainadropdownbuttonComponent } from './falainadropdownbutton.component';
import { TranslateModule } from '@ngx-translate/core';
import { FalainaSharedModule } from 'app/shared/shared.module';


@NgModule({
  declarations: [FalainadropdownbuttonComponent],
  imports: [
    CommonModule,
    ButtonsModule,
    TranslateModule,
    FalainaSharedModule
  ],
  exports: [FalainadropdownbuttonComponent]
})
export class FalainadropdownbuttonModule { }
