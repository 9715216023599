import { Injectable } from "@angular/core";
import {  HttpParams } from "@angular/common/http";
import { throwError  } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { environment } from "@environment/environment";
import { customHttpclient } from "./shared/customhttpclient";

@Injectable({
  providedIn: "root"
})
export class MenuService {

 
  constructor(private customHttp : customHttpclient) {
    
  }

  private showwidget:boolean = false;
 
  setwidgetconfiguration(item: boolean) {
      this.showwidget = true;
  }

  getwidgetconfiguration(): boolean {
      return this.showwidget;
  }

  public getFirstLevelMenus(userId) {

    let params = new HttpParams().set('userId', userId)
    return this.customHttp.getdomainapi("PresentationFirstLevelMenu","GetFirstLevelTabMenus", params);
  

    // return this.http.get(this.myApiUrl+"PresentationFirstLevelMenu/GetFirstLevelTabMenus?userId="+userId).pipe(
    //   map(res => res),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }
  public getSubMenus(tabmenu:string,userId){
    let params = new HttpParams().set('tabmenu', tabmenu).set('userId',userId);
    return this.customHttp.getdomainapi("PresentationSubMenu","GetSubMenus", params);

    // return this.http.get(this.myApiUrl+"PresentationSubMenu/GetSubMenus?tabmenu="+tabmenu+"&userId="+userId).pipe(
    //   map(res => res),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }

  public getDetailsByRoute(url:string,userId){
    let params = new HttpParams().set('pageURL',url);
    return this.customHttp.getdomainapi("PresentationBreadCrumb","GetBreadCrumbByPageURL", params);

    // return this.http.get(this.myApiUrl+"PresentationBreadCrumb/GetBreadCrumbByPageURL?userId="+userId+"&pageURL="+url).pipe(
    //   map(res => res),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }
  public getPageNameByUrl(url){
    let params = new HttpParams().set('pageURL',url);
    return this.customHttp.getdomainapi("PresentationBreadCrumb","GetPageNameByUrl", params);
    // return this.http.get(this.myApiUrl+"PresentationBreadCrumb/GetPageNameByUrl?pageURL="+url).pipe(
    //   map(res => res),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }
  public getPagepermissionsByUrlanduserId(url, userId){

    let params = new HttpParams().set('pageUrl', url).set('userId',userId);
    return this.customHttp.getdomainapi("PresentationPermissionAR","GetAllPermissionsByPageURLandUserId", params);

    // return this.http.get(this.myApiUrl+"PresentationPermissionAR/GetAllPermissionsByPageURLandUserId?pageUrl="+url + "&userId=" + userId).pipe(
    //   map(res => res),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }
  public getFavouriteMenus(userId){

    let params = new HttpParams();
    return this.customHttp.getdomainapi("PresentationSubMenu","GetFavouriteMenus", params);

    // return this.http.get(this.myApiUrl+"PresentationSubMenu/GetFavouriteMenus?userId="+userId).pipe(
    //   map(res => res),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }

  public saveFavourite(data){

    let params = new HttpParams();
    return this.customHttp.postdomainapi("PresentationUserFavourites","",data, params);


    // return this.http.post(this.myApiUrl+"PresentationUserFavourites",data).pipe(
    //   map(res => res),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }
  //https://localhost:44301/api/PresentationFirstLevelMenu/GetMenuDetailsByUserId?userId=1
  public getAllMenu(userId){

    
    let params = new HttpParams().set('userId',userId);
    return this.customHttp.getdomainapi("PresentationFirstLevelMenu","GetMenuDetailsByUserId", params);

    // return this.http.get(this.myApiUrl+"PresentationFirstLevelMenu/GetMenuDetailsByUserId?userId="+userId).pipe(
    //   map(res => res),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }

  public getByURL(url){

    let params = new HttpParams().set('url',url);
    return this.customHttp.getdomainapi("PresentationCRUDPageUrl","GetByURL", params);

    // return this.http.get(this.myApiUrl+"PresentationCRUDPageUrl/GetByURL?url="+url).pipe(
    //   map(res => res),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }

  public GetByURLId(urlId) {

    
    let params = new HttpParams().set('urlId',urlId);
    return this.customHttp.getdomainapi("PresentationCRUDPage","GetByURLId", params);

    // return this.http.get(this.myApiUrl + "PresentationCRUDPage/GetByURLId?urlId=" + urlId).pipe(
    //   map(res => res),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }


  public GetByPageId(pageId) {

    
    let params = new HttpParams().set('pageId',pageId);
    return this.customHttp.getdomainapi("PresentationUploadConfig","GetByPageId", params);
    // return this.http.get(this.myApiUrl + "PresentationUploadConfig/GetByPageId?pageId=" + pageId).pipe(
    //   map(res => res),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }
  public GetPagePermissionByPageId(parentMenuId,pageId,userId) {

    let params = new HttpParams().set('pageId',pageId).set('parentMenuId',parentMenuId).set('userId',userId);
    return this.customHttp.getdomainapi("PresenationPagePermission","GetPagePermissionByPageId", params);


    //   return this.http.get(this.myApiUrl + "PresenationPagePermission/GetPagePermissionByPageId?pageId=" + pageId+"&parentMenuId="+parentMenuId+"&userId="+userId).pipe(
    //   map(res => res),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }
  public GetHomePage() {


    let params = new HttpParams()
    return this.customHttp.getdomainapi("PresentationCRUDPage", "GetHomePage", params);

    // return this.http.get(this.myApiUrl + "PresentationCRUDPage/GetByURLId?urlId=" + urlId).pipe(
    //   map(res => res),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }
}
