import { ColumnSetting } from "@models/miscellaneous/ColumnSetting";
import { ControlTypeEnum, ValidatorEnum } from "app/modules/dynamic-crud/models/formFieldEnum";


export class FilterForm{
    FormFields:Array<FilterFormField>;
    /**
     *
     */
    constructor(formField:Array<FilterFormField>=[]) {
       
        this.FormFields=formField;
    }
}

export class FilterFormField {
    websource:string
    key: string;
    isEditable:boolean
    value: any;    label: string;    
    placeholder:string; required: boolean ;
    readonly:boolean;
    dropdownTextKey:string;
    disabled:boolean;
    validator:Array<{ key: ValidatorEnum; value: any,customErrorMessage:string }>;
    order: number;
    controlType: ControlTypeEnum;
    type: string;
    options:Array<{ key: string; value: string }>;
    operator:string;
    defaultValue:string
  
    constructor(
      options: {
        websource?:any;
      
        value?: any;
        isEditable?:boolean;
        key?: string;
        dropdownTextKey?:string;
      //  checkBoxIsTrueFn?:{ disableProp:[],enableProp:[],requiredProp:[],notRequiredProp:[] }
        label?: string;
        required?: boolean;
        validator?: Array<{key:ValidatorEnum; value: string;customErrorMessage:string }>;
        order?: number;
        placeholder?:string;
        readonly?:boolean;
        disabled?:boolean
        controlType?: ControlTypeEnum;
        type?: string;
        options?: Array<{ key: string; value: string }>;
        defaultValue?:string;
       
      } = {}
    ) {
      this.websource=options.websource || null;
      this.isEditable=options.isEditable||true;    
      this.value = options.value;
      this.placeholder=options.placeholder || "";
      this.readonly=!!options.readonly;
      this.disabled=!!options.disabled;
      this.key = options.key || "";
      this.dropdownTextKey=options.dropdownTextKey || "";    
      this.label = options.label || "";
      this.required = options.required || false;
      this.validator = options.validator || [];
      this.order = options.order === undefined ? 1 : options.order;
      this.controlType = options.controlType ;
      this.type = options.type || "";
      this.options = options.options || [];
      this.defaultValue=options.defaultValue||"";
     
    }
  }