import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ControlTypeEnum, ValidatorEnum } from 'app/modules/dynamic-crud/models/formFieldEnum';
import { DynamicCRUDService } from 'app/modules/dynamic-crud/service/dynamic-crud.service';
import { FilterFormField } from '../../model/formField';

@Component({
  selector: 'falaina-custom-filter-field',
  templateUrl: './custom-filter-field.component.html',
  styleUrls: ['./custom-filter-field.component.css']
})
export class CustomFilterFieldComponent implements OnInit {

//#region global Variable
@Input() input: FilterFormField;
@Input() form: FormGroup;

public controlType: typeof ControlTypeEnum = ControlTypeEnum;
enableField: boolean = false;

operators = [
  { key: "Equal", value: "Equal" },
  { key: "NotEqual", value: "NotEqual" },
 
  { key: "NoFilter", value: "NoFilter" },
  //{ key: "In", value: "In" },
 // { key: "Like", value: "Like" },
  
]
//#endregion
constructor(private crudService: DynamicCRUDService) { }
ngOnInit(): void {
  this.setOperators()
 
  if(!this.input.operator)
  this.input.operator="NoFilter";
  if (this.input.websource != null)
    this.getOptions();
  else
    this.enableField = true;
  
}
  setOperators() {
    let controlType = this.input.controlType
    if(this.input.controlType==ControlTypeEnum.TextBox)
    this.operators.push({ key: "Like", value: "Like" })
    else if(this.input.controlType==ControlTypeEnum.Checkbox)
    {
      this.operators=[];
      this.operators=[ { key: "Equal", value: "Equal" }, { key: "NoFilter", value: "NoFilter" }]
    }
    else if(controlType==ControlTypeEnum.NumericTextBox || controlType == ControlTypeEnum.DatePicker )
    {
     var _operator= [{ key: "GreaterThan", value: "GreaterThanOrEqual" },
      { key: "LessThan", value: "LessThan" },
      { key: "GreaterThanOrEqual", value: "GreaterThanOrEqual" },
      { key: "LessThanOrEqual", value: "LessThanOrEqual" }]
      _operator.forEach(x=>{
          this.operators.push(x);
        })
    
    }
  }
get isValid() { return this.form.controls[this.input.key].valid; }
getOptions() {
  this.input.options = [];
  var isFilterable = this.input.type == 'filter';
  if (!isFilterable) {
    this.crudService.get(this.input.websource + "").subscribe(data => {
      delete data["$type"]
      Object.keys(data).map((key) => {
        this.input.options.push(
          {
            key: data[key],
            value: String(key)
          }
        )
      })
      this.enableField = true;
    })
    return;
  }

 
}

//#region Filter




selectionChange(event, dropdownTextKey) {
  if (dropdownTextKey != "")
    this.form.controls[dropdownTextKey].setValue(event.key)

}
operatorselectChange(e)
{
  this.input.operator=e.value
}
datepickerError: boolean = false
datePickerErrorMessage: string = ""
onDatePickerChange(event, input: FilterFormField) {
 

}

//#endregion 
}
