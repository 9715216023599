import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'falaina-appbar',
  templateUrl: './falainaappbar.component.html'
})
export class FalainaappbarComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }

}
