import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'falaina-appbar-spacer',
  template: `<div class="k-appbar-spacer"></div>`
})
export class FalainaAppbarSpacerComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }

}
