import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FalainamulticolumntemplategridComponent } from './falainamulticolumntemplategrid.component';

import { GridModule, PDFModule as GridPDFModule, ExcelModule, PDFModule } from "@progress/kendo-angular-grid";
import { MenusModule } from '@progress/kendo-angular-menu';
import { SkeletonElementsModule } from 'skeleton-elements/angular';
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { FalainatextboxModule } from '../falainatextbox/falainatextbox.module';
import { FalainabuttonModule } from '../falainabutton/falainabutton.module';
import { PopupModule } from "@progress/kendo-angular-popup";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { LabelModule } from "@progress/kendo-angular-label";
import { FalainafilterModule } from '../falainafilter/falainafilter.module';
import { FalainacontextmenuModule } from '../falainacontextmenu/falainacontextmenu.module';
import { FalainadropdownbuttonModule } from '../falainadropdownbutton/falainadropdownbutton.module';
import { FalainaSharedModule } from 'app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [FalainamulticolumntemplategridComponent],
  imports: [
    CommonModule,
    GridModule,
    PDFModule,
    ExcelModule,
    MenusModule,
    SkeletonElementsModule,
    InputsModule,
    ButtonsModule,
    FalainatextboxModule,
    FalainabuttonModule,
    PopupModule,
    DropDownsModule,
    LabelModule,
    FalainafilterModule,
    FalainacontextmenuModule,
    FalainadropdownbuttonModule,
    TranslateModule,
    FalainaSharedModule
  ],
  exports:[FalainamulticolumntemplategridComponent]
})
export class FalainamulticolumntemplategridModule { }
