import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { PopupModule } from '@progress/kendo-angular-popup';
import { FalainapopupComponent } from './falainapopup.component';
import { FalainabuttonModule } from '../falainabutton/falainabutton.module';
import { FalainaSharedModule } from 'app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FalainadropdownbuttonModule } from '../falainadropdownbutton/falainadropdownbutton.module';



@NgModule({
  declarations: [FalainapopupComponent],
  imports: [
    CommonModule,PopupModule,ButtonModule,FalainabuttonModule,
    FalainaSharedModule,TranslateModule,FalainadropdownbuttonModule
  ],
  exports:[FalainapopupComponent]
})
export class FalainapopupModule { }
