import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BreadCrumbCollapseMode, BreadCrumbItem } from "@progress/kendo-angular-navigation";

@Component({
  selector: "falaina-breadcrumb",
  templateUrl: "./falainabreadcrumb.component.html",
  styleUrls: ["./falainabreadcrumb.component.scss"]
})

export class FalainabreadcrumbComponent implements OnInit {
  @Input()
  breadcrumbItems: any[]; 
  @Input()
  breadcrumbCollapseMode: BreadCrumbCollapseMode = "wrap";
  breadcrumbSeparatorIcon: string;
  breadcrumbClass:any = "";
  breadcrumbStyle:any = "";

  constructor() { }

  ngOnInit(): void {
  }

  @Output()
  itemClick: EventEmitter<any> = new EventEmitter();

  public onItemClick(item: BreadCrumbItem,items:BreadCrumbItem[]): void {
    this.itemClick.emit(item);
  }
}
