import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FalainacontextmenuComponent } from './falainacontextmenu.component';
import { ContextMenuModule } from '@progress/kendo-angular-menu';
import { ButtonModule } from '@progress/kendo-angular-buttons';



@NgModule({
  declarations: [FalainacontextmenuComponent],
  imports: [
    CommonModule,ContextMenuModule,ButtonModule
  ],
  exports : [FalainacontextmenuComponent]
})
export class FalainacontextmenuModule { }
