import { Component, ElementRef, OnInit, SimpleChanges, Input } from "@angular/core";
import { MenuService } from "@services/menu.service"
import { NavigationEnd, Router } from "@angular/router";
import { SharedService } from "@services/shared/shared.service";
import { breadCrumb } from "../models/breadcrumbs";
import { CacheService } from "@services/shared/cacheservice";
import { FalainaSearchSize } from "app/shared/customcontrols/falainafilter/falainasearchsize";
import { fromEvent, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { MenuPageData, SizeEnum } from "../models/menuPageSetting";
import { authServiceDetail } from "../authentication/auth.service";
/*import { pageDetail } from "../models/pageDetail";*/
import { MFASharedService } from "app/shared/mfashared/mfashared.service";
import { AppConfigService } from "@services/shared/app-config-service";
import { FalainaLocalStorage } from "app/shared/utils/falainaLocalStorage";
import { FalainaSessionStorage } from "app/shared/utils/falainaSessionStorage";
import { ThemeService } from "@progress/kendo-angular-gauges";
import { xCircleIcon } from "@progress/kendo-svg-icons";
import { AnonymousSubject } from "rxjs/internal/Subject";
import { JsonpClientBackend } from "@angular/common/http";
import { UserService } from "@services/user.service";
import { UILog } from "@services/UILogInfo";


@Component({
  selector: "menu",
  templateUrl: "./menu.component.html"
})

export class MenuComponent implements OnInit {

  isAuthorized=false;
  resizeSubscription$: Subscription;
  showFiller = false;
  @Input() auth = false;

  resolverTypeValue: any = "";

  resolverTypeText: any = "";
  autocompleteFilterable: boolean = true;
  updateId: any;
  environment: boolean;
  menuExpand: boolean = false;
  private _clientId: string;
  private _clientSecreat: string;
  hasfilter: any=true;


  public resolverTypeChange(e) {
    this.resolverTypeValue = this.resolverTypeAutocomplete[32]?.value;
    this.getThirLevelMenu(null, e.value);

    this.resolverTypeText = e["text"];
    this.resolverTypeValue = e["value"];

  }

  public resolverTypeAutocomplete: any = [];
  //  = [
  //   {
  //     text: "None",
  //     value: 0
  //   },
  //   {
  //     text: "ExternalCodeResolver",
  //     value: 8
  //   },
  //   {
  //     text: "DatabaseResolver",
  //     value: 10
  //   }
  // ];

  setValue(values) {
    let resolverType = this.resolverTypeAutocomplete.filter(x => { return x.text == values.ResolverType });
  }

  viewButtonclick(values) {
    let resolverType = this.resolverTypeAutocomplete.filter(x => { return x.text == values.ResolverType });
  }

  hasBreadCrumbServData: boolean = false;
  FavouriteMenu: any[] = [];
  hasMenu: boolean;
  overFLowItems: any[];
  contextMenuDisplay: boolean;
  hasPermission: any;
  reload: any = false;
  hasHeader: boolean;
  paddingMenu: boolean;
  pageId: any;
  pageUniqueName: any;
 contextMenuItems:any;
  disablePagePermissions : any = [];
  constructor(private configServ : AppConfigService,private userService : UserService ,private el: ElementRef, private menuService: MenuService, private mfaService: MFASharedService,
    private cacheService: CacheService, private router: Router, private sharedService: SharedService) {
      this.sharedService.disablePagePermissionsEmittedEvent.subscribe((x: any) => {
  
        this.disablePagePermissions = x;
   
      })
      this.configServ.loadConfigurations().subscribe(x=>{
        this.environment = x.DevInProgress
        this._clientId = x.APIClientId
        this._clientSecreat = x.APIClientSecret
      })
      router.events.forEach((event) => {

      if (event instanceof NavigationEnd) {
        let url = this.router.url;

        if (url.includes("home")) {
          this.sharedService.isHomeEmittedEventEmitted("true")
        } else {
          this.sharedService.isHomeEmittedEventEmitted("false")
        }

        this.themeConfig()
      }
    });
  }
  ngOnInit(): void {

    this.themeConfig()
    this.checkMenuPageSetting(); // subscribing  shared service
    this.checkMenuFilterSetting();// subscribing  shared service
    //  this.getBreadCrumbsFromService();// subscribing  shared service
    this.subBreadCrumbEvent();   
    this.subContextMenuAction();

  }
  subContextMenuAction()
  {
    this.mfaService.CheckMFAContextAction.subscribe(x=>{
    this.contextMenuItems = x;
    this.onActionClick(null,x.action.text,true);
   });
  }
  ngOnChanges(changes: SimpleChanges) {
    // if (changes['mfaResult']?.currentValue){
    //   alert("mfaResult");
    // }
    if (changes['auth']?.currentValue) {

      this.userId = authServiceDetail.LoggedinUserId

      this.getFavouriteMenus(this.userId)
      this.cacheMenu();

      this.tempUserAccessElements = this.userAccessElements;


      this.dropdownButtonData = this.tempUserAccessElements.splice(6, this.tempUserAccessElements.length - 6);
      this.setForHome();
    }
    if (changes["mfaResult"]) {
      this.mfaResult = changes["mfaResult"].currentValue;
    }
    if(changes["disablePagePermissions"])
    {
      this.disablePagePermissions = changes["disablePagePermissions"].currentValue; 
    }
  }

  isDisabled(value : any)
  { 
         let index=  this.disablePagePermissions.indexOf(value);
         if(index !== -1)
         {
           return true;
         }
         else{
           return false;
         }
  }
  // subMenuPageData() {
  //   this.sharedService.menuPageConfigDataEvent.subscribe((x: MenuPageData) => {
  //     if (x.breadCrumbData) {
  //       this.hasBreadCrumbs = true;
  //       this.breadCrumbData.push(x.breadCrumbData);
  //     }
  //     if (x.pageTitle) {
  //       this.hasPageTitle = true
  //       this.pageTitle = x.pageTitle;
  //     }
  //     if (x.permissions) {
  //       this.hasPermission = true
  //       this.userAccessElements = x.permissions;
  //     }
  //   })
  //  }
  subBreadCrumbEvent() {
    this.sharedService.breadCrumbByURLEvent.subscribe(x => {
      this.setBackDefaultValue();
      var url = <String>x.substring(1);
      this.getBreadCrumbsDetailsByURL(url, authServiceDetail.LoggedinUserId);
    })
  }
  themeConfig() {

    if (this.router.url.includes('/register')) {

      this.hasMenu = false;
      this.hasHeader = false;
      this.hasBreadCrumbs = false;
      this.hasPermission = false;
      this.hasPageTitle = false;
      this.isAuthorized=true;
    }
    if (this.router.url.includes('pam/pamaccess')) {
      this.hasMenu = false;
      this.hasPermission = false;
      this.hasBreadCrumbs = false;
      this.hasPageTitle = false;
    }
    if (this.router.url.includes('pam/pasrdp')) {
      this.hasMenu = false;
      this.hasHeader = false;
      this.hasBreadCrumbs = false;
      this.hasPermission = false;
      this.hasPageTitle = false;

    }
    if (this.router.url.includes('pam/usersession/passession')) {
      this.hasMenu = false;
      this.hasHeader = false;
      this.hasBreadCrumbs = false;
      this.hasPermission = false;
      this.hasPageTitle = false;

    }
    if (this.router.url.includes('users/myprofile')) {
      this.hasPermission = false;
      this.hasBreadCrumbs = false;
      this.hasPageTitle = false;
    }

    if (this.router.url.includes('pam/pamaccess') || this.router.url.includes('pam/pasrdp') || this.router.url.includes('pam/usersession/passession')) {
      this.paddingMenu = true;
    } else {
      this.paddingMenu = false;
    }
  }

  getFavouriteMenus(userId: any) {
    this.menuService.getFavouriteMenus(userId).subscribe((x: any[]) => {
      this.FavouriteMenu = x;
    })
  }
  ngAfterContentInit(): void {

    setTimeout(() => {

      let tabElement = document.getElementById('Buttonoverflow')

      this.overFLowItems = [];

      let tabLabel = tabElement?.getElementsByClassName('Overflowbutton');

      for (let index = 0; index < tabLabel?.length; index++) {
        tabLabel[index]?.setAttribute('data-tabSize', tabLabel[index]?.scrollWidth.toString());
      }
      this.setTabData();
      this.resizeSubscription$ = fromEvent(window, 'resize').pipe(debounceTime(200)).subscribe(evt => {
        this.setTabData();
      })
    }, 3000)

  }
  setTabData() {
    let tabElement = document.getElementById('Buttonoverflow');
    this.overFLowItems = [];

    let tabLabel = tabElement?.getElementsByClassName('Overflowbutton');
    if (tabLabel) {
      let tabLabelArray = Array.from(tabLabel as HTMLCollectionOf<HTMLElement>);
      let overflowWidth = tabElement.offsetWidth;
      let tabLength = 0;
      for (let index = 0; index < tabLabel?.length; index++) {

        let tabwidth = tabLabel[index]?.getAttribute('data-tabSize')
        tabLength = tabLength + Number(tabwidth);
        if (tabLength > overflowWidth) {
          tabLabel[index]?.classList.add("hide");
          this.overFLowItems = [...this.overFLowItems, { text: tabLabel[index]?.getElementsByClassName('f_buttontext')[0]?.textContent, icon: tabLabel[index]?.querySelector('i')?.classList.toString() }];
        } else {
          tabLabel[index]?.classList.remove("hide");
        }
      }
      this.contextMenuDisplay = tabLabelArray.some(data => data.classList.contains('hide'))
    }
  }

  loadMenu() {
    this.overFLowItems = [];
    let tabElement = document.getElementById('Buttonoverflow')
    let tabLabel = tabElement?.getElementsByClassName('Overflowbutton');

    for (let index = 0; index < tabLabel?.length; index++) {

      if (tabLabel[index]?.classList.contains('hide')) {
        this.overFLowItems = [...this.overFLowItems, { text: tabLabel[index]?.getElementsByClassName('f_buttontext')[0]?.textContent, icon: tabLabel[index]?.querySelector('i').classList.toString() }];
      }
    }
  }

  homePage() {
    this.setPageTitle("Home")
    this.addClassById('Home', "f_sidebar_parentcategory_name_selected");
    this.breadCrumbData = [...this.defaultBreadCrumData];
    this.userAccessElements = [];
    this.enableMenus = false
  }
  // getBreadCrumbsFromService() {
  //   this.sharedService.addBreadCrumbEvent.subscribe(x => {
  //     var breadCrumbData = [];
  //     breadCrumbData = this.breadCrumbData.slice(2);
  //     var data = <breadCrumb>{
  //       id: x.breadCrumbValue,
  //       text: x.breadCrumbValue,
  //       order: 4
  //     }
  //     breadCrumbData.push(data);
  //     this.breadCrumbData = breadCrumbData;
  //     this.hasBreadCrumbServData = true;
  //     this.userAccessElements = [];
  //     this.setPageTitle("", x.pageUrl, true)
  //     if (x.loadpagepermission)
  //       this.getPagePermissionByUrl(x.pageUrl);

  //   })
  // }
  getChildBreadCrumbs(url, childURL: string) {
    var breadCrumbData = [];
    breadCrumbData = this.breadCrumbData.slice(2);
    childURL.toUpperCase();
    var data = <breadCrumb>{
      id: childURL.toUpperCase(),
      text: childURL.toUpperCase(),
      order: 4
    }
    breadCrumbData.push(data);
    this.breadCrumbData = breadCrumbData;
    this.userAccessElements = [];

    this.setPageTitle("", url + "/" + childURL, true)

  }
  getPagePermissionByUrl(url) {
    if (url.includes('accessreview/review')) {
      this.menuService.getPagepermissionsByUrlanduserId('accessreview/review', authServiceDetail.LoggedinUserId).subscribe((perresult: Array<any>) => {

        if (perresult.length > 0) {
          perresult = perresult.sort((a, b) => a.Order - b.Order)
          // perresult.forEach((d: any) => {
          //   if (d.ShowInControlBar) {
          //   this.userAccessElements.push({
          //     icon: d.IconUrl,
          //     text: d.DisplayName,
          //   });
          //   }
          // });
        }

        this.sharedService.getAllpageAction(perresult);
      });

    }
  }
  //#region falaina-filter

  standardFilter: any = [];
  hasAdhocFilter: boolean = true;
  hasCompactView: boolean = false;
  searchToolbar: boolean = false;
  hasSwitchView: boolean = true;
  hasRefresh: boolean = true
  hasCustomFilter: boolean = true;
  defaultView: string = "List View"
  userId: any;
  onViewSwitch(e) {
    if (e == "List View") {
      this.hasCustomFilter = false;
      this.hasAdhocFilter = this.menufiltersettings?.hasAdhocFilter ?? true;
      this.hasCompactView = false;
      this.defaultView = e
    }
    else {
      this.hasCustomFilter = true;
      this.hasAdhocFilter = false;
      this.hasCompactView = true;
      this.defaultView = e;
    }
    this.sharedService.switchViewEmitted(e);
  }
  onFilter(e) {
    this.sharedService.filterOnEnterEmittedFn(e);
  }
  onAdhocFilterClick(e) {
    this.sharedService.adhocFilterClickFn(e);
  }
  onCustomFilterAction(e) {

    this.sharedService.customFilterSelectFn(e);
  }
  onCustomFilterPopupSubmit(e) {

    this.sharedService.customFilterPopupSubmitFn(e);
  }
  onOutletLoaded(event)
  {
    
  }
  refreshButtonClick(e) {
    this.sharedService.refreshButtonClickEmitted(e)
  }
  //#endregion


  //#region breadcrumbs
  public defaultBreadCrumData: breadCrumb[] = [{ text: "Home" }];
  public breadCrumbData: breadCrumb[] = [];
  hasBreadCrumbs: boolean = true;

  getBreadCrumbsDetailsByURL(url, userId, childURL = []) {
    this.isAuthorized=false;
    this.menuService.getDetailsByRoute(url, userId).subscribe(
      a => {
        let x : Array<any> =a.BreadCrumbs
        this.isAuthorized=a.IsAuthorized;
      this.enableMenus = false
      var result = x.sort((a, b) => a.order - b.order)
      this.generateBreadCrumbs(result); //set the breadcrumbs 
      if (this.breadCrumbData.length <= 3 && url != 'home') {
        this.pageId = this.breadCrumbData[2]?.pageId;
        this.pageUniqueName = this.breadCrumbData[2]?.uniqueName
        this.checkMFARule(authServiceDetail.LoggedinUserId, this.pageId, this.pageUniqueName, 1)
        this.setPageTitle(this.breadCrumbData[2]?.text)    //set the title of page 
        this.LoadPagePermissions(this.breadCrumbData[1]?.id, this.breadCrumbData[2]?.id);//set the pagepermission
      }
      else if (this.breadCrumbData.length == 4 && (url.toString().includes("workflow/approverlist/") ||
        url.toString().includes("users/subordinates") ||
        url.toString().includes("myschedulehistory/myschedulehistory/viewschedule") ||
        url.toString().includes("provisioning/eventprocess/approvalsettings"))){
        this.setPageTitle(this.breadCrumbData[3]?.text)
        this.menuService.GetPagePermissionByPageId(this.breadCrumbData[2]?.id, this.breadCrumbData[3]?.pageId, FalainaLocalStorage.getItem("userId")).subscribe(x => {
          this.LoadPagePermissions("", "", x);
        })
      }
      else {
        this.setPageTitle(this.breadCrumbData[3]?.text)
      }
      if (url == 'home') {
        this.menuService.GetHomePage().subscribe(x => {
          this.homePage();
          let popUp = FalainaSessionStorage.getItem("SelfPWDPopUpVisible");
          if (popUp == 'false' || popUp == null) {
            this.checkMFARule(authServiceDetail.LoggedinUserId, x.PageUrlId, x.UniqueName, 1)
            return;
          }
        })
      }
    })
  }
  checkMFAHomeRule(userId: string, pageId: number, pageName: string, permId: number, action = "View") {
    if (!userId || !pageId || !permId) {
      this.mfaService.showMFAAuthPopup({ showPopup: false,policyId:'',type:'',ipAddress:'',city:'',countryCode:'', action: action });
      this.actionEmit(action)
      return;
    }
    if (FalainaSessionStorage.getItem("isMFAHomeVerified") == "true") {
      return;
    }
    this.mfaService.getMatchedRuleMFA(userId, pageId, permId).subscribe(x => {
      let rule = x
      if (rule.Id != 0 && rule.IsRecursive) {
        FalainaSessionStorage.setItem("isMFAHomeVerified", "true")
        this.doMFA(action);
      }
      else if (rule.Id != 0) {
        let userName = authServiceDetail.LoggedinUserInfo?.UserName;
        let loginTime = FalainaSessionStorage.getItem("loginTime");
        var formatedDate = this.formatDate(loginTime)
        this.mfaService.isMFAVerified(userName, pageName, formatedDate).subscribe(m => {

          if (m) {
            FalainaSessionStorage.setItem("isMFAHomeVerified", "true")
            this.mfaService.showMFAAuthPopup({ showPopup: false,policyId:'',type:'',ipAddress:'',city:'',countryCode:'', action: action });
            this.actionEmit(action)
            return
          }
          FalainaSessionStorage.setItem("isMFAHomeVerified", "false")
          this.doMFA(action);

        })
      }
      else {
        FalainaSessionStorage.setItem("isMFAHomeVerified", "true")
        this.mfaService.showMFAAuthPopup({ showPopup: false,policyId:'',type:'',ipAddress:'',city:'',countryCode:'', action: action });
        this.actionEmit(action)
      }
    })

  }

  checkMFARule(userId: string, pageId: number, pageName: string, permId: number, action = "View") {

    if (!userId || !pageId || !permId) {
      this.mfaService.showMFAAuthPopup({ showPopup: false,policyId:'',type:'',ipAddress:'',city:'',countryCode:'', action: action });
      this.actionEmit(action)
      return;
    }
    if (FalainaSessionStorage.getItem("isMFAHomeVerified") == "false") {
      this.router.navigate(["/home"])
    }
    this.mfaService.getMatchedRuleMFA(userId, pageId, permId).subscribe(x => {
      let rule = x
      if (rule.Id != 0) {
        FalainaSessionStorage.setItem("MFAPageId", pageId.toString());
        FalainaSessionStorage.setItem("MFAPageIdPerm", permId.toString());
        FalainaSessionStorage.setItem("MFAPageIdName", pageName.toString());
        if(!this.ContextMenuMFA)
        {
           var cItems = {action: {item:null,text:action}}          
        }
        else{
          cItems = this.contextMenuItems
        }       
        FalainaSessionStorage.setItem("MFAAction", JSON.stringify(cItems));
      }
      if (rule.Id != 0 && rule.IsRecursive == false) {
        var lipages = JSON.parse(FalainaSessionStorage.getItem("MFAPageList"));
        if (lipages == null) {
          this.doMFA(action);
        }
        else {
          if (lipages.filter((x) => x.UserId == userId && x.PageId == pageId && x.PermId == permId).length == 0) {
            this.doMFA(action);
          }
          else {
            this.actionEmit(action)
          }
        }
      }
      else if (rule.Id != 0) {
        //let userName = authServiceDetail.LoggedinUserInfo?.UserName;
        //let loginTime = FalainaSessionStorage.getItem("loginTime");
        //var formatedDate = this.formatDate(loginTime)
        //this.mfaService.isMFAVerified(userName, pageName, formatedDate).subscribe(m => {
        //  if (m) {
        //    this.mfaService.showMFAAuthPopup({ showPopup: false,policyId:'',type:'', action: action });
        //    this.actionEmit(action)
        //    return
        //  }
        this.doMFA(action);

        //})
      }
      else {
        this.mfaService.showMFAAuthPopup({ showPopup: false,policyId:'',type:'',ipAddress:'',city:'',countryCode:'', action: action });
        this.actionEmit(action)
      }
    })

  }
  formatDate(loginTime) {
    loginTime = new Date(loginTime);

    let d = loginTime.getDate();
    let m = loginTime.getMonth() + 1;  
    let y = loginTime.getFullYear();
    let h = loginTime.getHours();
    let mn = loginTime.getMinutes();
    let ss = loginTime.getSeconds();
    var date = [m,d, y].join("/");
    var time = [h, mn,ss].join(":");
    let formatedDate = date + " " + time
    return formatedDate
  }
  doMFA(action) {
    let body = new URLSearchParams();
    body.set('UserId', authServiceDetail.LoggedinUserId);
    body.set('ClientId', this._clientId);
    body.set('UserName', this._clientId);
    body.set('EndpointId', "0");
    this.mfaService.doMFAApi(body.toString()).subscribe(x => {
      let msg = x?.Message;
      switch (msg) {
        case "SUCCESS":
          this.mfaService.showMFAAuthPopup({ showPopup: false,policyId:'',type:'',ipAddress:x?.ipAddress,city:x?.city,
          countryCode:x?.countryCode, action: action });
          this.actionEmit(action)
          break;
        case "SHOW-POPUP":
          let data = {
            showPopup: true,
            policyId:x?.PolicyId,
            type:x?.Type,
            ipAddress:x?.ipAddress,
            city:x?.city,
            countryCode:x?.countryCode,
            action: action
          }
          this.mfaService.showMFAAuthPopup(data);
          break;
        case "DENY":
          this.mfaService.showMFAAuthPopup({ showPopup: false,policyId:'',type:'',ipAddress:x?.ipAddress,city:x?.city,
          countryCode:x?.countryCode, action: action });
          this.router.navigate(["/home"]);
          break;
      }
    })
  }
  actionEmit(action)
  
  {
    if(this.ContextMenuMFA)
    {
      if(action != "View")
          this.mfaService.ContextMenuMFAVerified(this.contextMenuItems);
    }
    else{
          if(action != "View")
              this.sharedService.actionEmitted(action);
    }
  }
  // doMFAApi(presentationMFAClientAccessValidation):Observable<any>
  // { 
  //   const headers= new HttpHeaders()
  //   .set('ClientId', 'AngularApp')
  //   .set('ClientSecret', 'AngularApp')
  //   .set('Content-Type','application/x-www-form-urlencoded')
  //   return this.http.post<any>("http://localhost:54423/MFA/DoMFA/",presentationMFAClientAccessValidation,{headers:headers});
  // }
  generateBreadCrumbs(data: Array<any>) {
    var breadCrumbData = [];
    data.forEach(x => {
      if (x.Order == 1) {
        this.addClassById(x.uniqueName, "f_sidebar_parentcategory_name_selected");
        x.Id = x.uniqueName
      }
      let breadCrumb = {
        id: x.Id,
        text: x.Name,
        order: x.Order,
        pageUrl: x.Url,
        pageId: x.PageId,
        uniqueName: x.uniqueName

        //imageUrl:x.ImageUrl
      }
      breadCrumbData.push(breadCrumb)
    })
    this.breadCrumbData = breadCrumbData
  }
  //#endregion

  //#region UserAccessElement and Page title
  hasPageTitle: boolean = true;
  collapseMenu: boolean = true;
  pageTitleSize: SizeEnum = SizeEnum.Large;
  userAccessElements: Array<{ icon?: any, text: string, permissionId?: any ,trackFlow?:boolean}> = [];
  pageTitle: any;
  tempUserAccessElements: any[] = [];
  dropdownButtonData: Array<any> = [];
  LoadPagePermissions(parentPageId, submenuId, Data = null) {
    this.userAccessElements = [];
    var contextMenu=[];
    var result = [];

    if (submenuId == undefined)
      return

    if (Data != null) {
      result = Data;

    }
    else {

      let acl = this.cacheService.load("acl").acl as Array<any>;

      let subMenus = acl.find(x => x.Id == parentPageId)?.submenus as Array<any>;;
      result = subMenus?.find(x => x.Id == submenuId)?.pagePermission
    }

    if (result?.length > 0) {
      //setting the userAccess element
      result.forEach((d: any) => {
        if (d.ShowInControlBar) {
          if (this.userAccessElements.filter((x) => x.text == d.PermissionName).length == 0) {
            this.userAccessElements.push({
              icon: d.PermissionIcon,
              text: d.PermissionName,
              permissionId: d.PermissionId,
              trackFlow:d?.TrackFlow
            });
          }

        }
        if (d.ShowInContextMenu) {
          if (contextMenu.filter((x) => x.text == d.PermissionName).length == 0) {
            contextMenu.push(d);
          }

        }
        

        
      });
    }
    setTimeout(() => {
      this.sharedService.getAllpageAction(contextMenu);
    }, 1000);
   
  }

  setPageTitle(title, url = null, isFromDb = false) {
    setTimeout(() => {
      this.pageTitle = title;
    });
    if (isFromDb) {
      this.menuService.getPageNameByUrl(url).subscribe((x: any) => {
        this.pageTitle = x;
        title = x;
      })
    }
  }
  //#endregion

  imgUrl: string = "../assets/images/AzureSvgIcons/";

  public GetIcon(index: number): string {
    let ind = index + 1;
    return this.imgUrl + ind + ".svg";
  }
  imgReportUrl: string = "../assets/images/AzureSvgIcons/reports/";
  public GetReportIcon(index: number): string {
    let ind = index + 1;
    return this.imgReportUrl + "report_" + ind + ".svg";
  }
  public data: Array<{ MenuName: string, Id: number }> = [];
  filterValue=""

  setBackDefaultValue() {

    this.pageTitleSize = SizeEnum.Large;
    this.hasMenu = true;
    this.hasBreadCrumbs = true;
    this.hasPageTitle = true;
    this.hasPermission = true;
    this.breadCrumbData = [];
    this.userAccessElements = [];
    this.pageTitle = "";
    this.collapseMenu = true;
    //filter
    this.searchToolbar = false;
    this.hasSwitchView = true;
    this.defaultView = "List View";
    this.hasAdhocFilter = true;
    this.filterValue=""
  }



  //#region Menu methods

  reportId: string = "ALLREPORTS"
  serviceId: string = "ALLSERVICES"
  enableMenus: boolean = false
  secondLevelMenuItems: any[] = [];
  thirdLevelMenuBckUpItems: any[] = [];

  public categoryAllservices: any;
  public categoryReports: any;
  public submenus: any;
  public dummycategories: any = [];
  public categorysubmenus: any;
  findItem: any;
  getCount(tabmenu: string) {
    //PageUrl
    if(this.environment)
    return this.submenus.filter((y: any) => { return y.ParentMenuName == tabmenu; }).length;
    else
    return this.submenus.filter((y: any) => { return y.ParentMenuName == tabmenu && !y.PageUrl?.includes('.aspx') }).length;
  }
  clickMethod(evt: any) {
    if (evt.target.classList.contains("k-i-arrow-n")) {
      evt.target.classList.remove("k-i-arrow-n");
      evt.target.classList.add("k-i-arrow-s");
      evt.target.parentElement.parentElement.lastElementChild.style.display = "none";
    }
    else {
      evt.target.classList.remove("k-i-arrow-s");
      evt.target.classList.add("k-i-arrow-n");
      evt.target.parentElement.parentElement.lastElementChild.style.display = "flex";
    }
  }

  openNav() {
    document.querySelector<HTMLElement>("#mySidepanel").style.width = "72%";
    document.querySelector<HTMLElement>("#mySidepanel").style.display = "block";

  }

  closeNav() {
    document.querySelector<HTMLElement>("#mySidepanel").style.width = "0";
    document.querySelector<HTMLElement>("#mySidepanel").style.display = "none";
  }
  toggleVerticalMenu() {
    this.menuExpand = !this.menuExpand
    const ulElm = document.querySelector<HTMLElement>(".f_sidebar_category_lst")!;
    const liTitle = document.querySelectorAll<HTMLElement>(".f_sidebar_categories_title")!;
    const spanElm = document.querySelectorAll<HTMLElement>(".menuBackGray .pinNone .f_sidebar_category_title")!;

    if (!ulElm.classList.contains("firstLevelMenuCollapsed")) {
      ulElm.classList.add("firstLevelMenuCollapsed");
      for (var i = 0; i < spanElm.length; i++) {
        spanElm[i].style.display = "none";
      }
      for (var i = 0; i < liTitle.length; i++) {
        liTitle[i].style.display = "none";
      }
      let elm = document.querySelector(".kendo_popup");
      if (elm && elm?.classList.contains("popupSizeV2")) {
        elm.classList.remove("popupSizeV2");
        elm.classList.add("popupSizeV1");
      }
    }
    else {
      ulElm.classList.remove("firstLevelMenuCollapsed");
      for (var i = 0; i < spanElm.length; i++) {
        spanElm[i].style.display = "flex";
      }
      for (var i = 0; i < liTitle.length; i++) {
        liTitle[i].style.display = "flex";
      }
      let elm = document.querySelector(".kendo_popup");
      if (elm && elm?.classList.contains("popupSizeV1")) {
        elm.classList.remove("popupSizeV1");
        elm.classList.add("popupSizeV2");
      }
    }
    this.sharedService.menuExpandEmitted(this.menuExpand)
  }
  cacheMenu() {
    var isExist = this.cacheService.load("acl") == null ? false : true;
    if (isExist && this.cacheService.load("acl").userId == authServiceDetail.LoggedinUserId) {
      this.getFirstLevelMenu();
      return
    }

    this.menuService.getAllMenu(this.userId).subscribe(x => {
      var userACL = {
        acl: x,
        userId: authServiceDetail.LoggedinUserId
      }

      this.cacheService.save({ key: "acl", data: userACL });

      this.getFirstLevelMenu();
    })
  }
  getFirstLevelMenu() {
    let result = this.cacheService.load("acl")
    let firstLevelMenus = result.acl;
    this.categoryAllservices = firstLevelMenus.filter((x: any) => { return x.ParentMenuId == null && x.UniqueName != "REPORT"; }).sort(this.customOrder(true));
    this.categoryReports = firstLevelMenus.filter((x: any) => { return x.ParentMenuId != null; }).sort(this.customOrder(true));

    let source = firstLevelMenus;
    var overviewitem = {
      MenuName: "Home",
      Id: 50000
    };
    var index = 0;
    source.splice(index, 0, overviewitem);
    this.data = source.slice();
  }

  setForHome() {
    let binding = [];
    let mixedArray = [...this.categoryReports, ...this.categoryAllservices];


    mixedArray.forEach((bind: any) => {
      let bundle = {
        text: bind.MenuName,
        value: bind.Id
      }
      binding.push(bundle)
    });

    this.resolverTypeAutocomplete = binding;

    this.resolverTypeValue = this.resolverTypeAutocomplete[32]?.value;
    //this.resolverTypeValue = this.resolverTypeAutocomplete[32];
  }
  getSecondLevelMenu(id, getThirdLevelMenuById = null) {
    this.addClassById(id, "f_sidebar_parentcategory_name_selected");

    if (id == this.reportId) {
      this.secondLevelMenuItems = this.categoryReports
    } else {
      this.secondLevelMenuItems = this.categoryAllservices
    }
    let binding = [];
    this.secondLevelMenuItems.forEach((bind: any) => {
      let bundle = {
        text: bind.MenuName,
        value: bind.Id
      }
      binding.push(bundle)
    });

    this.resolverTypeAutocomplete = binding;



    this.enableMenus = true;

    if (getThirdLevelMenuById == null) {
      this.getThirLevelMenu(null, this.secondLevelMenuItems[0]?.Id)
      return;
    }
    this.getThirLevelMenu(null, getThirdLevelMenuById);


    // let binding = [];
    //     for(q){
    //       let dsd =  {
    //             text: q.MenuName,
    //             value: 0
    //           },

    //           this.secondLevelMenuItems
    //      binding.push()
    //     }

    // public resolverTypeAutocomplete: Array<{ text: string; value: number }> = [
    //   {
    //     text: "None",
    //     value: 0
    //   },
    //   {
    //     text: "ExternalCodeResolver",
    //     value: 8
    //   },
    //   {
    //     text: "DatabaseResolver",
    //     value: 10
    //   }
    // ];
  }

  getThirLevelMenu(evt: any, id: any) {
    this.updateId = id;
    let result = this.cacheService.load("acl").acl as Array<any>;
   let menu = result.find(x => x.Id == id)
   if(menu.UniqueName =="ALLREPORTS")
   {
   this.submenus= this.getAllReportSubMenu(menu);
   }
   else{
    this.submenus = result.find(x => x.Id == id)?.submenus;
   }
    
    this.thirdLevelMenuBckUpItems = this.submenus;
    this.getThirdLevelMenuCat(this.submenus);
    setTimeout(() => {
      this.addClassById(id, "f_sidebar_category_name_selected")
      const targetElm = document.getElementById(this.breadCrumbData[2]?.id + "tmenu")!;
      targetElm?.closest(".f_sidebar-category-label-wrapper").classList.add("f_sidebar_category_name_selected");
    });
  }
  getAllReportSubMenu(menu: any) {
    let submenus=[];
  menu.submenus.forEach(y=>{
      if(y.ParentMenuName !="All Reports")
      {
    
      
      let result = this.cacheService.load("acl").acl as Array<any>;
      let menu = result.find(x => x.Id == y.Id)
      let items=result.find(x => x.Id == y.Id)?.submenus
      items.forEach(x=>{
        if(x.PageUrl=='#')
        {
          console.log("triggered")
        }
      })
    
      submenus =[...submenus,...items]
      }
    })

    return submenus
  }
  closeMegaMenu() {
    if (this.router.url.toString().includes("/home")) {
      this.homePage();
    }
    this.enableMenus = false
  }
  pinMenu(m) {
    
    if(!m?.PageUrl?.includes("aspx"))
  { 
    var fav = {
      MenuName: m.MenuName,
      UserId: this.userId,
      IsFavourite: !m.IsFavourite,
      CreatedBy: "",
    }
    this.menuService.saveFavourite(fav).subscribe(x => {
      this.getFavouriteMenus(this.userId);
      var index = this.submenus.findIndex(x => x.Id == m.Id)
      this.submenus[index].IsFavourite = !(this.submenus[index].IsFavourite);   
      let result = this.cacheService.load("acl").acl as Array<any>;
      let submenu= result.find(x => x.Id == this.updateId)?.submenus;
    
       submenu = submenu.find(x => x.Id == m.Id);
       submenu.IsFavourite = m.IsFavourite;
       var userACL={
        acl:result,
        userId:authServiceDetail.LoggedinUserId
      }
       this.cacheService.save({ key: "acl", data: userACL });

      })
    }


  }
  closemenu(evt: any, data: any) {
    this.enableMenus = false
    if (evt.target.innerText !== "Home") {

      this.router.navigate([data.PageUrl]);
      return;
    }
    this.addClassById('Home', "f_sidebar_parentcategory_name_selected")
    this.router.navigateByUrl("/home");
  }
  addClassById(id, className) {
    const targetElm = document.getElementById(id)!;
    let myTag = this.el.nativeElement.querySelector("." + className);
    myTag?.classList.remove(className);
    targetElm?.classList.add(className);
  }
  getThirdLevelMenuCat(submenus) {
    this.dummycategories = [];
    submenus.forEach((x: any, i: number) => {
      this.findItem = this.dummycategories.filter((y: any) => { return y == x.ParentMenuName; });
      if (this.findItem.length == 0) {
        this.dummycategories.push(x.ParentMenuName);
      //  this.categorysubmenus = submenus.filter((y: any) => { return y.ParentMenuName == x.ParentMenuName; });
      }

    }

    );
  }
  //#endregion
  checkMenuPageSetting() {
    this.sharedService.menuPageConfigEnableEvent.subscribe(x => {
      this.hasBreadCrumbs = x.hasBreadCrumbs ?? true;
      this.hasPageTitle = x.hasPageTitle ?? true;
      this.hasPermission = x.hasPermission ?? true;
      this.pageTitleSize = x.pageSize ?? SizeEnum.Large;
      this.collapseMenu = x.collapseMenu ?? true;
    })
  }

  menufiltersettings: any = null;
  checkMenuFilterSetting() {
    this.sharedService.menuFilterEnableEvent.subscribe(x => {
      this.menufiltersettings = x;
      this.searchToolbar = x.filterEnable ?? false;
      this.hasSwitchView = x.hasSwitchView ?? true;
      this.hasRefresh = x.hasRefresh ?? true;
      this.defaultView = x.defaultView ?? "List View"
      this.searchSize = x.searchSize ?? "small"
      this.hasfilter=x.hasFilter ?? "true";
      if (this.defaultView == "List View") {
        this.hasCustomFilter = false;
        this.hasAdhocFilter = x.hasAdhocFilter ?? true;
        this.hasCompactView = false;
      }
      else {
        this.hasCustomFilter = x.hasCustomFilter ?? true;
        this.hasAdhocFilter = false;
        this.hasCompactView = true;
        this.defaultView = x.defaultView;
      }
    })
  }

  public onEnter(value: string): void {
    if (value) {
      this.submenus = this.thirdLevelMenuBckUpItems.filter((s: any) => s.MenuName.toLowerCase().indexOf(value.toLowerCase()) !== -1)
      this.getThirdLevelMenuCat(this.submenus)
    }
    else {
      this.submenus = this.thirdLevelMenuBckUpItems
      this.getThirdLevelMenuCat(this.submenus)
    }
  }
  public onItemClick(item: breadCrumb): void {

    if (item.order == 3) {
      this.router.navigateByUrl(item.pageUrl)
      return;
    }
    if (item.order == 1) {
      this.getSecondLevelMenu(item.id)
    }
    else {

      this.getSecondLevelMenu(this.breadCrumbData[0].id, item.id)
    }
  }

  uploadId: any = "";
  showUploadPopup: boolean = false;
  popupWidth: any = "width: calc(100vw - 36px);";
  urlResult: any = "";
  pageResult: any = "";
  upldResult: any = "";
  ContextMenuMFA:boolean =false;
  @Input() mfaResult: string = "";
  
  onActionClick(evt, action,isContextMenu = false,trackFlow=false) {   
    this.ContextMenuMFA = isContextMenu; 
    let url = this.router.url.substring(1);
    this.logUserAction(action,isContextMenu);
    if (action === "Upload" && !this.router.url.includes("genericuploadconfiguration")) {
      this.menuService.getByURL(url).subscribe((perresult: Array<any>) => {
        if (perresult) { 
          this.urlResult = perresult;
          this.menuService.GetByURLId(this.urlResult.Id).subscribe((result: Array<any>) => {
            if (result) {
              this.pageResult = result;
              this.menuService.GetByPageId(this.pageResult.Id).subscribe((uploadResult: Array<any>) => {
                if (uploadResult) {
                  this.upldResult = uploadResult;
                  this.uploadId = this.upldResult.Id;
                  this.showUploadPopup = true;
                }
              });
            }
          });
        }

      });
    }
    this.checkActionLvlMFA(this.pageId,action,this.userAccessElements)
  }
  logUserAction(action,isContextMenu) {
   let i= this.userAccessElements.findIndex(x=>{return x.text == action || x.text.includes("Export")})
  if((i!= -1 ) && this.userAccessElements[i].trackFlow)
  {
    let userId= authServiceDetail.LoggedinUserId
    let pageId=this.pageId
    let pageTitle= this.pageTitle
    let displayName=authServiceDetail.LoggedinUserInfo.DisplayName
    let message=displayName+" accessing "+pageTitle+" for "+action+" operation"
    if(isContextMenu)
    message=displayName+" accessing "+pageTitle+" for "+action+" operation from context menu"
    let log =<UILog>{
      Id:0,
      UserId:userId,
      Name:displayName,
      LogLevel:"Info",
      Message:message
    }
   this.userService.saveUILog(log).subscribe(x=>{
     
   })
  }
  

  }
  checkActionLvlMFA(pageId: any, action: any, userAccessElements: { icon?: any; text: any; }[]) {
   let permissionId= this.userAccessElements.find(x=>{return x.text==action})?.permissionId
   
   this.checkMFARule(authServiceDetail.LoggedinUserId,pageId,this.pageUniqueName,permissionId,action);
  }

  eventChangedHandler(flag: boolean) {
    this.showUploadPopup = flag;
  }
  buttonMenu(ev) {

    let text = ev.item.text;
    this.onActionClick(ev, text)
  }
  eventCancelChangedHandler(flag: boolean) {
    this.showUploadPopup = flag;
  }

  pagePermissionExportData: any[] = [{
    icon: "ms-Icon ms-Icon--ExcelDocument f_primarycolor f_font_size_18 f_margin_right_8",
    text: "Export To Excel"
  },
  {
    icon: "ms-Icon ms-Icon--ExcelLogo f_primarycolor f_font_size_18 f_margin_right_8",
    text: "Export To CSV"
  },
  {
    icon: "ms-Icon ms-Icon--PDF f_primarycolor f_font_size_18 f_margin_right_8",
    text: "Export To PDF"
  }];

  pagePermissionPickSessiontData: any[] = [
    {
      icon: "k-icon k-i-paste-plain-text f_primarycolor",
      text: "Top 6 Latest Completed Session"
    },
    {
      icon: "ms-Icon ms-Icon--Filter f_primarycolor f_font_size_18 f_margin_right_8",
      text: "Filter"
    },
    {
      icon: "k-icon k-i-calendar f_primarycolor",
      text: "Completed Session List"
    }];
  exportItemClick(e: any) {
    this.onActionClick(null, e.text);
  }

  pickSessionItemClick(e: any) {

    this.sharedService.pickSessionItemClick(e);
  }

  searchSize: FalainaSearchSize = "small";
  popupuploadbtnclick(e: any) {
    this.showUploadPopup = false;
  }

  singleAutoFilterChange(e: any) {
    // alert(e);
  }

  //Custom Order -keeps null value has last
   customOrder(isAscending) {

    return function (a, b) {
      a=a.Order
      b=b.Order
      // equal items sort equally
      if (a === b) {
          return 0;
      }
      // nulls sort after anything else
      else if (a === null) {
          return 1;
      }
      else if (b === null) {
          return -1;
      }
      // otherwise, if we're ascending, lowest sorts first
      else if (isAscending) {
          return a < b ? -1 : 1;
      }
      // if descending, highest sorts first
      else { 
          return a < b ? 1 : -1;
      }
  
    };
  
  }
}

