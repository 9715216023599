import { EventEmitter, forwardRef, Output, ViewChildren } from "@angular/core";
import { Component, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { formatNumber } from '@telerik/kendo-intl';
@Component({
  selector: "falaina-textbox",
  templateUrl: "./falainatextbox.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FalainatextboxComponent),
      multi: true
    }
  ]
})
export class FalainatextboxComponent implements ControlValueAccessor,OnInit {
  @ViewChildren("falainatextbox") falainaTextbox:any;
  @Input()
  formControlName:any = "";

  @Input()

  txtAreaClass : any = "";

  @Input()
  txtboxId : any = "falainaTextbox";

  @Input()
  txtboxPlaceholder : any = "Place your text here";

  @Input()
  txtboxClass : any = "";

  @Input()
  txtboxIcon : any = "";

  @Input()
  txtboxClear : any = true;
 

  @Input()
  txtboxDisabled : any = false;

  @Input()
  txtboxReadonly : any = false;
  @Input()
  txtareaReadonly : any = false;

  @Input()
  isNumericTextBox : boolean = false;

  @Input()
  isNumTxtboxDisabled : boolean = false;
  
  @Input()
  maxlength : number;

  @Input()
  numericTextBoxMinValue : number = 0;
  @Input()
  numericTextBoxMaxValue : number = 100;

  @Input()
  isTextAreaEnabled : boolean = false;


  @Input()

  textAreaColumnSize : number = 40;

  @Input()

  textAreaRowSize : number = 5;

  @Input()

  textAreaPlaceHolder : string = "Type a Text";
  
  @Input()
  textPasswordPlaceHolder : string = "Type in Password";

  @Input() showPassWordBox : boolean = false;

  @Input() isPasswordBoxDisabled : boolean =false;

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter();
  isTextFieldType: boolean;

  enteredText : any = "";
  public onTextBoxValueChange(e:any) {
    

    if(this.showPassWordBox)
    {
      this.valueChange.emit(e.target.value);
      this.enteredText = e.target.value;
    }
    else
    {
      this.valueChange.emit(e);
      this.enteredText = e
    }
   
  }

  @Output()
  searchClick: EventEmitter<any> = new EventEmitter();
  onSearchClick(e : any)
  {
    this.searchClick.emit(this.enteredText);
  }
  constructor() { 
  }
  ngOnInit(): void {
    this.txtboxClass = "f_font_size_13 "+this.txtboxClass;
  }
  ngAfterViewInit(){
    if(this.txtboxDisabled){
      document.getElementById(this.txtboxId).setAttribute("disabled",this.txtboxDisabled);
    }
  }


  @Input('textAreaValue') textAreaVal = "";
 
  get textAreaValue() {
    return this.textAreaVal;
  }

  set textAreaValue(val) {
    this.textAreaVal = val;
    this.onChange(val);
    this.onTouched();
  }


  @Input('numericTextBoxValue') numericTextBoxVal = "";
 
  get numericTextBoxValue() {
    return this.numericTextBoxVal;
  }

  set numericTextBoxValue(val) {
    this.numericTextBoxVal = val;
    this.onChange(val);
    this.onTouched();
  }

  @Input('textPasswordValue') textPasswordVal = "";
 
  get textPasswordValue() {
    return this.textPasswordVal;
  }

  set textPasswordValue(val) {
    this.textPasswordVal = val;
    this.onChange(val);
    this.onTouched();
  }
  
  @Input('value') txtboxValue = "";
  onChange: any = () => {};
  onTouched: any = () => {};
  get value() {
    return this.txtboxValue;
  }

  set value(val) {
    this.txtboxValue = val;
    this.onChange(val);
    this.onTouched();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
   
      this.value = value;
    this.numericTextBoxValue = value;
    this.textAreaValue = value;
    this.textPasswordValue = value;
  }
  clearValue()
  {
    this.textAreaValue = "";
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  onClick(){
    this.isTextFieldType = !this.isTextFieldType;
  }
}
