import { EventEmitter, Injectable, Output } from '@angular/core'; 

@Injectable({
  providedIn: 'root'
})
export class FalainaloadingService {
  constructor( ) { }
  @Output() showLoaderEvent= new EventEmitter<any>();
  @Output() disableUIAction= new EventEmitter<any>();

 
  show()
  { 
    this.showLoaderEvent.emit(true) ;
   
  }
  
  hide()
  {
   
    this.showLoaderEvent.emit(false)
   
  }
  enableAction()
  {
    this.disableUIAction.emit(true)
  }
  disableAction()
  {
    this.disableUIAction.emit(true)
  }
}
