import {  HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { CheckoutType } from '@models/accessdetail/checkoutitems';
import { PagedResults } from '@models/customcontrols/pagedresults';
import { ListViewDataResult } from '@progress/kendo-angular-listview';
import { customHttpclient } from '@services/shared/customhttpclient';
import { PresentationActiveUser } from 'app/shared/falainausercontrols/falainaapprovalchain/users';
import { param } from 'jquery';
import { Observable,of } from 'rxjs';
import { AcrAddResponse } from '../models/acraddresponse';
import { AcrFilterCriteria } from '../models/acrfiltercriteria';
import { AddAcrItemResponse, Result } from '../models/addacritemresponse';
import { SearchUserList } from '../models/searchuserlist';

@Injectable({
  providedIn: 'root'
})
export class AcrService {
  private acrservicecontrollername = "PresentationAddACRItem";
  private autocompletecontrollername = "AutoCompleteDataService";
  private actvusercontrollername = "PresentationActiveUser";
  private endpointcontrollername = "NPresentationEndPoint";
  constructor(private http: customHttpclient) {
  }



  //#region  acr service

  //post
  saverequesttocart(addacritem : Result, CheckoutType : any):Observable<AcrAddResponse>{
    let params = new HttpParams().set('CheckoutType',CheckoutType);
    let actionname  = 'SaveRequestToCart';
    return this.http.postdomainapi(this.acrservicecontrollername,actionname,addacritem, params);
  }

  //get Acr paged results
  public getAcrPagedResults(acrfiltercriteria:AcrFilterCriteria,skip : any,take : any,sortExpression: any):Observable<AddAcrItemResponse>{
    let params = new HttpParams().set('pageSize',take).set('skip',skip).set('sortExpression',sortExpression);
    let actionname  = 'GetACRPagedResults';

    return this.http.postdomainapi(this.acrservicecontrollername,actionname,acrfiltercriteria, params);
   }


 //get user list service
  public getuserlist(searchstring : string):Observable<Array<SearchUserList>>{
    let params = new HttpParams().set('searchString',searchstring);
    let actionname = 'GetUserList'
    return this.http.getdomainapi(this.acrservicecontrollername,actionname, params);
  }

  //get cart count items count
  public getcartcountitemscount(userId:any,checkoutType:any):Observable<number>{ 
    let params = new HttpParams().set('checkoutType',checkoutType);
  let actionname = 'GetCartItemsCount'
  return this.http.getdomainapi(this.acrservicecontrollername,actionname, params)
  }
  //#endregion


    //#region autocomplete service
    getallacraccesstypes(searchstring : string):Observable<any>{
      let params = new HttpParams().set('searchString',searchstring);
      return this.http.getdomainapi(this.autocompletecontrollername,'GetAllACRAccessTypes', params);
    }
  
    getallacrrequesttypes(searchstring : string):Observable<any>{
      let params = new HttpParams().set('searchString',searchstring);
      return this.http.getdomainapi(this.autocompletecontrollername,'GetAllACRRequestTypes', params);
    }
    
    getaccounttypes(searchstring : string):Observable<any>{
      let params = new HttpParams().set('searchString',searchstring);
      return this.http.getdomainapi(this.autocompletecontrollername,'GetAccountTypes', params);
    }
  
    getactionendpointobjects(searchIds : Array<any>):Observable<any>{

      let params = new HttpParams();
      searchIds.forEach(ele=>{
        params = params.append("endpointIds",ele);
      })
      return this.http.getdomainapi(this.autocompletecontrollername,'GetActionEndpointObjects', params);
    }
  
    //#endregion

    //#region  previlege access
    isElevatedUser(userId : any):Observable<boolean>{
      let params = new HttpParams();
      return this.http.getdomainapi(this.actvusercontrollername,'IsElevatedUser', params);
    }

    getAllPIMUsers(skip : any,pagesize:any):Observable<PagedResults<PresentationActiveUser>>{
      let params = new HttpParams().set('skip',skip).set('pagesize',pagesize);
      return this.http.getdomainapi(this.actvusercontrollername,'GetAllPIMUsers', params);
    }


    getAllPAMACRAccessTypes(searchstring : string):Observable<any>{
      let params = new HttpParams().set('searchString',searchstring);
      return this.http.getdomainapi(this.autocompletecontrollername,'GetAllPAMACRAccessTypes', params);
    }
  
    getAllPAMACRRequestTypes(searchstring : string):Observable<any>{
      let params = new HttpParams().set('searchString',searchstring);
      return this.http.getdomainapi(this.autocompletecontrollername,'GetAllPAMACRRequestTypes', params);
    }
  
    getAllPAMACRCategories(searchstring : string):Observable<any>{
      let params = new HttpParams().set('searchString',searchstring);
      return this.http.getdomainapi(this.autocompletecontrollername,'GetAllPAMACRCategories', params);
    }
  


    getTechRolesDetails(technicalRolId) {
      let params = new HttpParams().set('technicalRolId',technicalRolId);
      return this.http.getdomainapi("PresentationTechnicalRole", "GetTechnicalRolesDetails",params)

  }

    //#endregion
  



}
