import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ContextMenuSelectEvent } from '@progress/kendo-angular-menu';

@Component({
  selector: 'falaina-context-menu',
  templateUrl: './falainacontextmenu.component.html'
})
export class FalainacontextmenuComponent implements OnInit {
  @Input() contextMenuItems: any[] = [];
  @Input() buttonIconclass:string = "";
  @Input() buttonKendoIconclass:string = "more-vertical";
  constructor(private renderer: Renderer2) { }
  ngOnInit(): void {
    if(this.buttonIconclass)
      this.buttonKendoIconclass = "";
    if(this.buttonKendoIconclass)
      this.buttonIconclass = "";
  }

  @Output()
  contextOnSelect: EventEmitter<any> = new EventEmitter();
  onSelect(e:ContextMenuSelectEvent): void {
    this.contextOnSelect.emit(e);
  }
  popupOpen(e){
    
  }
  popupClose(e){
    //e.preventDefault();
  }
}
