import { EventEmitter, Injectable, Output, Pipe, PipeTransform } from '@angular/core';
import { HomeService } from 'app/modules/home/service/homeservice.service';
import { truncate } from 'fs';
import { Observable } from 'rxjs';

@Pipe({
  name: 'getCoOrdinates',
  pure: true
})
export class GetCoOrdinates implements PipeTransform {
islast=false
 constructor(private homeService:HomeService) {


 }
  transform(position:string, field:string,islast): any {
    {
      if(islast && !this.islast)
      {
        this.islast=islast
        this.homeService.isDashboardReady(islast);
      }
    
  
    if(position){
        let posn = JSON.parse(position); 
        return posn[field];
      }
      return "0";
  }
}
}
