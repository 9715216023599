import { Component, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'falaina-dropdown-list',
  templateUrl: './falainadropdownlist.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FalainadropdownlistComponent),
      multi: true
    }
  ]
})
export class FalainadropdownlistComponent implements ControlValueAccessor, OnInit {
  @ViewChild("dropdownlist", { static: true }) public dropdownlist: any;
  // @Input() dropdownDataItem:any[] = [];
  @Input()
  dropDownValueField: string = "value";
  @Input()
  dropDownTextField: string = "text";
  tempDropdownDataItem: any[] = [];

  @Input()

  isDataServiceEnabled: boolean = false;

  // @Input() dropdownSelectedValue:any;
  @Input() dropdownName: string = "falainaDropdown";

  @Input() isFilterable: boolean = false;
  @Input() isDropDownDisabled: boolean = false;
  @Input() dropdownId = "dropdown"


  @Input() dropDownIconField: string = "icon";

  @Input('dropdownDataItem') dropdownData;
  placeholder: any;
  get dropdownDataItem() {
    return this.dropdownData;
  }

  set dropdownDataItem(val) {
    this.dropdownData = val;

    // this.onChange(val);
    // this.onTouched();

  }
  @Input('dropdownSelectedValue') dropdownValue;
  get dropdownSelectedValue() {

    return this.dropdownValue;

  }

  set dropdownSelectedValue(val) {
    
      this.dropdownValue = val;
    
    this.onChange(val);
    this.onTouched();

  }
  onChange: any = () => { };
  onTouched: any = () => { };


  registerOnChange(fn) {
    this.onChange = fn;

  }

  writeValue(value) {
  
      this.dropdownSelectedValue = value;
    
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  constructor() { }
  ngOnInit(): void {

    // this.dropdownlist.disable();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes["dropdownData"]) {
      this.removeData(this.dropdownDataItem);
      this.tempDropdownDataItem = this.dropdownDataItem;
    }
  }

  @Output() onDropdownSelect: EventEmitter<any> = new EventEmitter();
  selectionChange(value: any): void {
    // this.onChange(value);
    // this.onTouched();
    this.onDropdownSelect.emit(value);
  }
  
  
  @Output() 
  onDropdownFilterChange: EventEmitter<any> = new EventEmitter();
  handleFilter(value) {
    //console.log(value);
    this.dropdownDataItem = [];

    if (!this.isDataServiceEnabled) {
      // if(value.length <= 0)
      // return;

      if (value == "*") {
        this.dropdownDataItem = this.tempDropdownDataItem;
      }
      else {
        this.tempDropdownDataItem.filter((x: any) => { return x[this.dropDownTextField].toLowerCase().indexOf(value.toLowerCase()) !== -1 }).forEach((d: any) => {
          if (!this.dropdownDataItem.map((res: any) => res.value).includes(d.value)) {
            d[this.dropDownTextField] = d[this.dropDownTextField]
            d[this.dropDownValueField] = d[this.dropDownValueField]
            this.dropdownDataItem.push(d)
          }
        });
      }

    }
    else {

      this.onDropdownFilterChange.emit(value);
    }


  }
  removeData(arryData) {
    let a = arryData as Array<any>
    if (!a)
      return;
    let index = a.findIndex(x => { return x[this.dropDownValueField] === '$type' })
    if (index != -1)
      a.splice(index, 1)

    let index1 = a.findIndex(x => { return x[this.dropDownTextField] === '$type' })
    if (index1 != -1)
      a.splice(index1, 1)
  }
}
