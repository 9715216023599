import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { ColumnSetting } from '../../shared/models/miscellaneous/ColumnSetting';
import { UploadserviceService } from '../services/uploadservice.service';
import { PresentationUploadProgress } from './PresentationUploadProgress';


@Component({
  selector: 'userupload',
  templateUrl: './userupload.component.html',
  styleUrls: ['./userupload.component.css']
})
export class UseruploadComponent implements OnInit {

  @Input()
  falainaCurrentStep: number = 0;
  stepperOrientation: any = "vertical";
  stepperSteptype: any = "full";
  form: FormGroup;
  downloadData: any = "";
  uploadConfig: any = "";
  progressList: any = "";
  bytes: any = [];
  historyItem: GridDataResult;
  itemCount: number = 0;
  uploadedText: any = "";
  upldId: any;
  showLink: boolean = false;
  showErrorPopup: boolean = false;
  popupWidth: number = 1500;

  failureDetail: any=[];
  rowSelection: boolean = false;
  @Input() set uploadId(value: any) {
    this.upldId = value;
  }
  get uploadId(): any {
    return this.upldId;
  }

  uploadedFile: any = [];

  @Output() eventChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() eventCancelChanged: EventEmitter<boolean> = new EventEmitter();

  fileName: any = "";
  private uploadProgress = new PresentationUploadProgress();

  beforeUploaded: any[] = [];
  errorItem: GridDataResult;

  @Input()
  fileuploadAllowedExtensions: Array<string> = ["csv"];

  historyColumns: ColumnSetting[] = [
    {
      field: "FileName",
      title: "File Name",
      type: "text"
    },
    {
      field: "CreatedBy",
      title: "Uploaded By",
      type: "text"
    },
    {
      field: "CreatedDate",
      title: "Uploaded Date",
      type: "text"
    },
    {
      field: "SuccessCount",
      title: "Success Count",
      type: "text"
    },
    {
      field: "FailureCount",
      title: "Failure Count",
      type: "text"
    },
    {
      field: "TotalRecords",
      title: "Total Records",
      type: "text"
    },
    {
      field: "ProcessFailureCount",
      title: "Process Failure Count",
      type: "text"
    },
    {
      field: "Status",
      title: "Status",
      type: "text"
    },
    {
      field: "FailedRecordDetails",
      title: "Failed Record Details",
      type: "text"
    },
    {
      field: "ProcessFailedRecordDetails",
      title: "Process Failed Record Details",
      type: "text"
    }
  ];

  errorColumns: ColumnSetting[] = [
    {
      field: "FileName",
      title: "File Name",
      type: "text"
    },
    {
      field: "CreatedBy",
      title: "Uploaded By",
      type: "text"
    },
    {
      field: "CreatedDate",
      title: "Uploaded Date",
      type: "text"
    },
    {
      field: "FailureCount",
      title: "Failure Count",
      type: "text"
    }

  ];
  
  steps = [
    {
      label: "Upload Files",
      isValid: true
    },
    {
      label: "History",
      isValid: true
    },
    {
      label: "Review & Submit",
      isValid: true
    }
  ];

  submitted = false;
  onSubmit() {
    this.submitted = true;
    this.form.markAsUntouched();

    if (this.form.invalid) {
      return;
    }
  }

  pullcurrentstep(event: any) {
    this.falainaCurrentStep = event;
  }

  prev(e: any) {
    this.falainaCurrentStep = e["stepindex"];
  }


  public isFormValid: boolean = true;

  get f() {
    let formControlName = document.querySelector(".k-form-stepper > div").getAttribute("formgroupname");

    return this.form.controls[formControlName];
  }

  next(e: any) {
    this.submitted = true;
    this.falainaCurrentStep = e["stepindex"];

    let formControlName = document.querySelector(".k-form-stepper > div").getAttribute("formgroupname");
    let formControlValid = this.form.controls[formControlName].valid;
    this.isFormValid = (formControlValid && this.falainaCurrentStep !== this.steps.length);
    this.steps[this.falainaCurrentStep].isValid = this.isFormValid;
    this.form.controls[formControlName].markAllAsTouched();
  }

  validatecurrentstep(e: any) {

    this.falainaCurrentStep = e["stepindex"];
    this.submitted = true;
    let formControlName = document.querySelector(".k-form-stepper > div").getAttribute("formgroupname");

    if (this.falainaCurrentStep !== this.steps.length) {

      let formControlValid = this.form.controls[formControlName].valid;

      if (this.falainaCurrentStep == 0)
      {
        if (this.fileError || !this.form.value.UploadFile.attachments)
        {
          this.steps[this.falainaCurrentStep].isValid = false;
          formControlValid = false;
        }
        else 
        {
          this.fileError = "";
          formControlValid = true;
        }
      }

      this.isFormValid = (formControlValid && this.falainaCurrentStep !== this.steps.length);
      this.steps[this.falainaCurrentStep].isValid = this.isFormValid;

      this.form.controls[formControlName].markAllAsTouched();

      if (formControlValid) {
        this.submitted = false;
      }


    }
  }

  finish(e: any) {
    if (this.upldId != 0) {
      this.uploadProgress.Extension = ".csv";
      this.uploadProgress.FileName = this.uploadConfig.EntityName;
      this.uploadProgress.Status = "Pending";
      this.uploadProgress.UploadConfigurationId = this.upldId;
      this.uploadProgress.UploadedFile = this.uploadedFile;
      this.uploadProgress.UploadProcessId = null;
      this.uploadServices.SaveUploadProgress(this.uploadProgress).subscribe((result: any) => {
        let resultArr = result;
      }, error => console.error(error));
    }

    this.eventChanged.emit(false);
  }

  constructor(private formBuilder: FormBuilder, private router: Router, private uploadServices: UploadserviceService) {
    
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      UploadFile: this.formBuilder.group({
        attachments: [[], Validators.required],
      },{ validators: checkFilesValidators })
    })

    this.uploadServices.GetByUploadConfigurationId(this.upldId).subscribe((result: any) => {
      this.uploadConfig = result;
    }, error => console.error(error));

    this.uploadServices.GetUploadProgressByUploadConfigId(this.upldId).subscribe((result: any) => {
      this.progressList = result;
      this.historyItem = { data: this.progressList, total: this.progressList.length };
      this.beforeUploaded.push(this.progressList[this.progressList.length - 1]);
      if (this.beforeUploaded[0].FailureCount > 0) {
        let output = this.beforeUploaded[0].FailedRecordJson;
        this.errorItem = { data: output, total: output.length };
        this.uploadedText = "File you uploaded has some issues. Please download and fix it";
        this.showLink = true;
      }
      else if (this.beforeUploaded[0].Status == "Pending"
        || this.beforeUploaded[0].Status == "InProgress") {
        this.uploadedText = "Uploaded file is still in progress";
        this.showLink = false;
      }
      else {
        this.uploadedText = "File has been uploaded successfully";
        this.showLink = false;
      }
    }, error => console.error(error));
  }
  fileUploaded:any[] = [];
  fileError:any;
  selectEventHandler(e: any) {
    this.fileUploaded.push(e[0].e);
    this.uploadedFile = e[0].base64textString;
    this.uploadProgress.UploadConfigurationId = this.upldId;
    this.uploadProgress.UploadedFile = this.uploadedFile;
    this.uploadServices.ValidateUploadFile(this.uploadProgress).subscribe((validateResult: any) => {
      if(!validateResult.Result)
        this.fileError = validateResult.StatusMessage;
      else
      {
        this.uploadServices.GetRecordCount(this.uploadProgress).subscribe((result: any) => {
          let res = result;
          if(res > 0)
            this.itemCount = res;
          else
            this.fileError = "empty file could not uploaded";
        })
      }
     })
  }
  downloadCSVFile() {
    if (this.upldId != 0) {
        this.uploadServices.GetTemplateList(this.upldId).subscribe((result: any) => {
          this.downloadData = result;
          var a = document.createElement("a");
          var blob = new Blob([this.downloadData], { type: "application/csv" }),
            url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = this.uploadConfig.EntityName + ".csv";
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }, error => console.error(error));
    }
  }
  downloadFailedRecord(e: any) {
    this.failureDetail = this.beforeUploaded[0].FailedRecordDetails;

    let byteChar = atob(this.failureDetail.$value);
    let byteArray = new Array(byteChar.length);
    for (let i = 0; i < byteChar.length; i++) {
      byteArray[i] = byteChar.charCodeAt(i);
    }
    let uIntArray = new Uint8Array(byteArray);

    var a = document.createElement("a");
    var blob = new Blob([uIntArray], { type: "application/csv" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = this.uploadConfig.EntityName + ".csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  downloadFailedRecords(e: any) {
    this.failureDetail = e;
    let byteChar = atob(this.failureDetail.$value);
    let byteArray = new Array(byteChar.length);
    for (let i = 0; i < byteChar.length; i++) {
      byteArray[i] = byteChar.charCodeAt(i);
    }
    let uIntArray = new Uint8Array(byteArray);
    var a = document.createElement("a");
    var blob = new Blob([uIntArray], { type: "application/csv" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = this.uploadConfig.EntityName + ".csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  downloadProcessFailedRecords(e: any) {
    this.failureDetail = e;
    let byteChar = atob(this.failureDetail.$value);
    let byteArray = new Array(byteChar.length);
    for (let i = 0; i < byteChar.length; i++) {
      byteArray[i] = byteChar.charCodeAt(i);
    }
    let uIntArray = new Uint8Array(byteArray);
    var a = document.createElement("a");
    var blob = new Blob([uIntArray], { type: "application/csv" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = this.uploadConfig.EntityName + ".csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  viewErrors() {
    this.showErrorPopup = true;
  }

  popupuploadbtnclick(e: any) {
    this.showErrorPopup = false;
  }

  removeFile(e:any){
    e.files?.forEach(ele=>{
      this.fileUploaded = this.fileUploaded?.filter(file=> file != ele);
    });
   let x = this.form.get('UploadFile').get('attachments').setValue(this.fileUploaded)
  }

  cancel(e: any) {
    this.eventCancelChanged.emit(false);
  }

  clearFiles(e:any){
    
   this.fileUploaded = [];
  
   let x = this.form.get('UploadFile').get('attachments').setValue(this.fileUploaded)
  }

  removeEventHandler(e:any){
    this.fileError = "";
    this.fileUploaded = [];
  }

}

export const checkFilesValidators: ValidatorFn = (control: AbstractControl):  ValidationErrors | null  => {
  let filesvalues : Array<any> = control?.value.attachments;
  let iserrorfound : boolean = false;
  if(filesvalues.length!==0){
  filesvalues?.forEach(ele=>{

    if(ele?.validationErrors != null){
      iserrorfound = true;
    } })
}
  if(iserrorfound) return {"FileTypeError":true} 
  else
  return null;

};


// function checkFileTypeValidators(control : AbstractControl): { [key: string]: boolean}{
//  let filesvalues : Array<any> = control.value;
//  let iserrorfound : boolean = false;
//  console.log(control.value);
//  filesvalues.forEach(ele=>{
//    if(ele.validationErrors.length != 0){
//      iserrorfound = true;
//    }
//  })

//  if(iserrorfound) return {"FileTypeError":true} 
//  else
//  return null;

// }

    

