import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '@models/config/appconfig';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private readonly CONFIG_URL = '/assets/config/configuration.json';
  private configuration$: Observable<AppConfig>;


  constructor(private http : HttpClient) { 
  
  }

  public loadConfigurations():  Observable<AppConfig> {
    if (!this.configuration$) {
      this.configuration$ = this.http.get<AppConfig>(this.CONFIG_URL).pipe(
        shareReplay(1)
      );
    }
    return this.configuration$;
  }


}
