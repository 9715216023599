import { AfterContentInit, Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { StepperComponent } from "@progress/kendo-angular-layout";
import { FalainaStepComponent } from "./falainasteps";

@Component({
  selector: "falaina-stepper",
  templateUrl: "./falainastepper.component.html"
})
export class FalainastepperComponent implements OnInit, AfterContentInit {

  steps: FalainaStepComponent[] = [];

  isValid: boolean = true;
  @Input()
  stepperOrientation: any = "vertical";

  @Input()
  stepperSteptype: any = "indicator";

  @Input()

  stepperLabelfield : any = "label";

  @Input()
  stepperSteps: any = [];

  @Input()
  falainaCurrentStep: number = 0;
  @Input()
  stepperLinear: boolean = false;

  @Input()

  stepperInFullPage : boolean = false;

  @Input()

  stepperStyle : any = "height: calc(100vh - 140px)";

  @Input()
  isShowInPopup: boolean = false;

  @Input()
  isReadyforSubmit: boolean = true;

  @Input()
  isReviewAndSubmit: boolean = false;

  @Output()
  steppersclick: EventEmitter<any> = new EventEmitter();
  @Output()
  validatecurrentstep: EventEmitter<any> = new EventEmitter<{ stepindex: any }>();

  pullcurrentstep(value: any) { 
    this.steppersclick.emit(this.falainaCurrentStep);
  }
  @ViewChild("stepper", { static: true })
  public stepper: StepperComponent;
  constructor() { }

  ngAfterContentInit(): void {
    
  }
  ngOnInit(): void {
    
   
  }

  @Output()
  stepperPrev: EventEmitter<any> = new EventEmitter<{ stepindex: any }>();
  prev(e: any) {
    this.falainaCurrentStep -= 1;
    if (this.stepperSteps[this.falainaCurrentStep].disabled) {
      this.falainaCurrentStep -= 1;
    }
    this.stepperPrev.emit({ stepindex: this.falainaCurrentStep });
  }

  @Output()
  stepperNext: EventEmitter<any> = new EventEmitter<{ stepindex: any }>();


  next(e: any) {

    this.validatecurrentstep.emit({ stepindex: this.falainaCurrentStep });
    if (this.stepperSteps[this.falainaCurrentStep].isValid) {
      if (e.target.innerText !== "Submit") {
        this.falainaCurrentStep += 1;
        if (this.stepperSteps[this.falainaCurrentStep].disabled) {
          this.falainaCurrentStep += 1;
        }
      
        this.stepperNext.emit({ stepindex: this.falainaCurrentStep });         
      }

    }
    else {
      this.stepper.validateSteps();
      this.stepperNext.emit({ stepindex: this.falainaCurrentStep });
    }
  }


  @Output()
  stepperFinish: EventEmitter<any> = new EventEmitter<{ stepindex: any }>();

  Finish(e: any) {
    //console.log(this.stepperSteps)
    this.stepperFinish.emit({ stepindex: this.falainaCurrentStep })
  }

  @Output()
  stepperCancel: EventEmitter<any> = new EventEmitter();
  cancel(e: any) {
    this.stepperCancel.emit(e);
  }

  @ContentChild("customfootersection") customfootersectionRef: TemplateRef<any>;
  @Input() showCustomfooter: boolean = false;
  @Input() showSubmit : boolean= true

   
}
