import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FalainaappbarComponent } from './falainaappbar.component';
import { FalainaAppbarSpacerComponent } from './falainaappbarspacer';
import { FalainaAppbarSectionComponent } from './falainaappbarsection';



@NgModule({
  declarations: [FalainaappbarComponent,FalainaAppbarSpacerComponent,FalainaAppbarSectionComponent],
  imports: [
    CommonModule
  ],
  exports:[FalainaappbarComponent,FalainaAppbarSpacerComponent,FalainaAppbarSectionComponent]
})
export class FalainaappbarModule { }
