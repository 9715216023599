import { AfterContentInit, Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
    selector: '[ellipsisActive]'
})
export class EllipsisActiveDirective implements AfterContentInit {
    constructor(private elementRef: ElementRef) {
    }


    @HostListener('mouseenter') onMouseEnter() {
        const element = this.elementRef.nativeElement;
        // console.log(element.classList.contains('overflowInTwoLines'),'element');
        
        if (element.clientWidth < element.scrollWidth || element.classList.contains('overflowInTwoLines')) {
            element.title = element.innerText
        } else {
            element.removeAttribute("title");
        }
    }

    ngAfterContentInit() {
    }
}
