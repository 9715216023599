import { EventEmitter, Injectable, Output, Pipe, PipeTransform } from '@angular/core';
import { HomeService } from 'app/modules/home/service/homeservice.service';
import { truncate } from 'fs';
import { Observable } from 'rxjs';

@Pipe({
  name: 'getById',
  pure: true
})
export class GetByIdPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} value
   * @param {number} id
   * @param {string} property
   * @returns {any}
   */
  transform(value: any[], id: number, property: string): any {
    const foundItem = value.find(item => {
      if (item.id !== undefined) {
        return item.id === id;
      }

      return false;
    });

    if (foundItem) {
      return foundItem[property];
    }
  }
}

@Pipe({
  name: 'DashboardItemById',
  pure: true
})
export class DashboardItemsPipe implements PipeTransform {
  private data: any[] = [];
 
  constructor(private homeService: HomeService) {

  }
 
    transform(id: number):  Observable<{data: any[], total:number}>   {   

   return this.homeService.GetQueryResult1(id, 1);

  }
}

@Pipe({
  name: 'getFromLocalStorage'
})
export class FullNamePipe implements PipeTransform {
  transform(id: number)
  {
   
    return null;
  }
}