import { EventEmitter, Injectable, Output } from "@angular/core";
import {  HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { environment } from "@environment/environment";
import { PageChangeEvent } from "@progress/kendo-angular-pager";
import { customHttpclient } from "@services/shared/customhttpclient";
@Injectable({
  providedIn: "root"
})
export class HomeService {


  constructor(private customhttp: customHttpclient) {

  }
  public GetUserroleDefaultDashboards(userId: any) {

    let params = new HttpParams();
    return this.customhttp.getdomainapi("PresentationDefaultRoleDashboardPreference","GetDefaultRoleUserDashboard", params);
  

    // return this.http.get(this.myApiUrl + "PresentationDefaultRoleDashboardPreference/GetDefaultRoleUserDashboard?userId=" + userId).pipe(
    //   map(
    //     res => res
    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }
  public FilterDefaultRoleUserDashboard(userId: any, searchString:any) {

    let params = new HttpParams().set('searchString', searchString)
    return this.customhttp.getdomainapi("PresentationDefaultRoleDashboardPreference","FilterDefaultRoleUserDashboard", params);
  

    // return this.http.get(this.myApiUrl + "PresentationDefaultRoleDashboardPreference/GetDefaultRoleUserDashboard?userId=" + userId).pipe(
    //   map(
    //     res => res
    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }
  public GetUserDashboards(userId: any) {

    let params = new HttpParams()
    return this.customhttp.getdomainapi("NPresentationCustomDashboard","GetUserDashboards", params);
  

    // return this.http.get(this.myApiUrl + "NPresentationCustomDashboard/GetUserDashboards?userId=" + userId).pipe(
    //   map(
    //     res => res
    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }


  public GetCategoryBasedWebAccess(userId: any) {
    let params = new HttpParams();
    let actionname = 'GetCategoryBasedWebAccess'
    return this.customhttp.getdomainapi("NPresentationMyWebAccess", actionname, params);
  }
  public resetDashboardWidget(userId: any) {
  
    let params = new HttpParams().set('userId', userId);
    let actionname = "ResetUserDashboardPreference";
    return this.customhttp.postdomainapi("PresentationUserDashboardPreference", actionname,"", params);
  }
 
  public removeDashboardWidget(userId: any,customdashboardId:any) {
  
    let params = new HttpParams().set('userId', userId).set('customDashboardId',customdashboardId);
    let actionname = "RemoveUserDashboardPreference";
    return this.customhttp.postdomainapi("PresentationUserDashboardPreference", actionname,"", params);
  }
 

  public GetQueryResult(statisticsId: any, userId: any,grpAttrb="",grpVal=""): Observable<any[]> {

    let params = new HttpParams().set('statisticsId', statisticsId).set('grpAttr', grpAttrb)
    .set('grpAttrVal', grpVal).set('DataFieldX','').set('ColorField','Color')
    return this.customhttp.getdomainapi("NPresentationCustomDashboard","GetQueryResult", params);


    // return this.http.get<any[]>(this.myApiUrl + "NPresentationCustomDashboard/GetQueryResult?statisticsId=" + statisticsId + 
    // "&userId=" + userId+"&grpAttr="+grpAttrb+"&grpAttrVal="+grpVal + "&DataFieldX=&ColorField=Color").pipe(
    //   map(
    //     res => (
    //       res
    //     )
    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }
  public GetPagedQueryResult(statisticsId: any, userId: any,pagination:any,grpAttrb:any="",grpVal:any=""): Observable<any[]> {
   
    let params = new HttpParams().set('statisticsId', statisticsId)
    .set('skip',pagination.skip).set('pagesize',pagination.take)
    .set('grpAttr', grpAttrb)
    .set('grpAttrVal', grpVal).set('DataFieldX','').set('ColorField','Color')

    return this.customhttp.getdomainapi("NPresentationCustomDashboard","GetPagedQueryResult", params);

    // return this.http.get<any[]>(this.myApiUrl + "NPresentationCustomDashboard/GetPagedQueryResult?statisticsId=" + statisticsId + "&userId=" + userId+
    // "&skip="+pagination.skip+"&pagesize="+pagination.take+"&grpAttr="+grpAttrb+"&grpAttrVal="+grpVal+ "&DataFieldX=&ColorField=Color").pipe(
    //   map(
    //     res => (
    //       res
    //     )
    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }
  public GetDashBoardDrpDwn(statisticsId: any, userId: any,groupAttribute): Observable<any[]> {


    let params = new HttpParams().set('statisticsId', statisticsId)
    .set('grpAttr', groupAttribute).set('DataFieldX','').set('ColorField','Color')

    return this.customhttp.getdomainapi("NPresentationCustomDashboard","GetValuesByGroupAttribute", params);

    // return this.http.get<any[]>(this.myApiUrl + "NPresentationCustomDashboard/GetValuesByGroupAttribute?statisticsId=" 
    // +statisticsId + "&userId=" + userId+"&grpAttr="+groupAttribute+ "&DataFieldX=&ColorField=Color").pipe(
    //   map(
    //     res => (
    //       res
    //     )
    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }

  public GetQueryResult1(statisticsId: any, userId: any): Observable<{ data: any[], total: number }> {

     let params = new HttpParams().set('userId', userId);
    return this.customhttp.getdomainapi("NPresentationCustomDashboard","GetUserDashboards", params);

    // return this.http.get<any[]>(this.myApiUrl + "NPresentationCustomDashboard/GetUserDashboards?userId=" + userId).pipe(
    //   map(
    //     res => ({ data: res, total: res.length })

    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }

  public GetQuickDashboardData(userId: any, customDashboardIds: any, startIndex: any) {

    let params = new HttpParams().set('userId', userId).set('customDashboardIds', customDashboardIds).set('startIndex', startIndex)
    return this.customhttp.getdomainapi("PresentationAttention","GetQuickDashboardData", params);

    // return this.http.get(this.myApiUrl + "PresentationAttention/GetQuickDashboardData?userId=" + userId + "&customDashboardIds=" + customDashboardIds + "&startIndex=" + startIndex).pipe(
    //   map(
    //     res => res
    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }
  public GetUserPIMAssetsWorkSpace(userId: any) {

    let params = new HttpParams();
    return this.customhttp.getdomainapi("NPresentationMyPAMAccess","GetUserPIMAssetsWorkSpace", params);

    // return this.http.get(this.myApiUrl + "NPresentationMyPAMAccess/GetUserPIMAssetsWorkSpace?userId=" + userId).pipe(
    //   map(
    //     res => res
    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }
  public GetUserAccessPAMAccounts(userId: any, endPointSystemId:any) {

    let params = new HttpParams().set('userId', userId).set('epId', endPointSystemId);
    return this.customhttp.getdomainapi("NPresentationMyPAMAccess", "GetUserNewPIMAssetsByEndpoint", params);

    // return this.http.get(this.myApiUrl + "NPresentationMyPAMAccessAccount/GetUserAccessPAMAccounts?userId=" + userId + "&endPointSystemId=" + endPointSystemId).pipe(
    //   map(
    //     res => res
    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }

  @Output() enableDashabord = new EventEmitter<boolean>();
  isDashboardReady(e) {

    this.enableDashabord.emit(e);

  }
}
