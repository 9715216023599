import { Injectable } from "@angular/core";
import {  HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { environment } from "@environment/environment";
import { customHttpclient } from "@services/shared/customhttpclient";

@Injectable({
  providedIn: "root"
})

export class FalainaLogoService {
 
  constructor(private customHttp : customHttpclient) {

  }

  public resultObj : any; 

  public getLogoValue()
  {
    let params = new HttpParams().set('configKey', 'ApplicationLogoText').set('product','IACM')
    return this.customHttp.getdomainapi("PresentationUserRegistration","GetByConfigKey", params);
  
  }
  
  public gpdateLogoConfigValue(configuration) : Observable<any> {
    let params = new HttpParams();
    return this.customHttp.postdomainapi("PresentationUserRegistration","GetByConfigKey",configuration, params);
  }

  public getImageData()
  {
    let params = new HttpParams().set('configKey', 'ApplicationLogo').set('product','IACM')
    return this.customHttp.getdomainapi("PresentationUserRegistration","GetByConfigKey", params);

  }

}
