import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FalainaaccordianComponent } from './falainaaccordian.component';
import { FalainaaccordianItemComponent } from './falainaaccordian-item/falainaaccordian-item.component';



@NgModule({
  declarations: [FalainaaccordianComponent,FalainaaccordianItemComponent],
  imports: [
    CommonModule
  ],
  exports:[FalainaaccordianComponent,FalainaaccordianItemComponent]
})
export class FalainaaccordianModule { }
