import { Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { customHttpclient } from "./shared/customhttpclient";



@Injectable({
  providedIn: 'root'
})
export class MasterConfigurationService {


  constructor(private customhttp: customHttpclient) {
  }
  GetConfigByKey(data) {
    let params = new HttpParams()
      .set("ConfigKey",data)
    return this.customhttp.getdomainapi("PresentationConfigurationMaster", "GetByConfigKey", params)
  }
  GetIMConfigByKey(data) {
    let params = new HttpParams()
      .set("ConfigKey",data)
    return this.customhttp.getdomainapi("PresentationConfigurationMasterIM", "GetByConfigKey", params)
  }
  SaveConfigKeyValue(ConfigKey, ConfigValue) {
    let params = new HttpParams()
      .set("ConfigKey", ConfigKey)
      .set("ConfigValue", ConfigValue)
    return this.customhttp.getdomainapi("PresentationConfigurationMaster", "SaveConfigKeyValue", params)
  }
  GetProductInfo() {
    let params = new HttpParams()
    return this.customhttp.getdomainapi("PresentationProductInfo", "GetProductInfo", params)
  }
  getApplicationTheme(){
    let params = new HttpParams().set('configKey', 'ApplicationTheme').set('product','IACM')
    return this.customhttp.getdomainapi("PresentationUserRegistration","GetByConfigKey", params);
  }
}
