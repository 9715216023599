import { animate, group, state, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "falaina-accordian-item",
  templateUrl: "./falainaaccordian-item.component.html",
  animations: [
    trigger("accordionItemContentAnimation",[
      state("isOpen", style({height: "*"})),
      state("isClose", style({height: 0})),
      transition("isOpen <=> isClose", group([animate("500ms")])),      
    ])
  ]
})
export class FalainaaccordianItemComponent implements OnInit {
  public state: string = "isClose";
  private isExpanded: boolean = false;
  
  @Input("isOpen")
  set isOpen(value: boolean) {
    this.isExpanded = value;
    this.state = this.isExpanded ? "isOpen" : "isClose";
  }
  get isOpen(): boolean {
    return this.isExpanded;
  }
  @Input() title: string;
  @Output() onToggle: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }


  ngOnInit() {
  }


  public onClickHeader() {
    this.isOpen = this.isOpen? false : true;
    this.onToggle.emit(this.isOpen);
  }

}
