import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { SharedService } from '@services/shared/shared.service';
import { ENOTEMPTY } from 'constants';
import { FalainaSearchSize, ViewType } from './falainasearchsize';

@Component({
  selector: 'falaina-filter',
  templateUrl: './falainafilter.component.html',
  styleUrls: ['./falainafilter.component.css']
})
export class FalainafilterComponent implements OnInit {
  @Input() isToolbarAlignedLeft:boolean = false;
  @Input() filterValue:string="";
  @Input() gridCompactview:boolean = false;
  @Input() hasRefresh:boolean = false;
  @Output() switchEvent:EventEmitter<any>=new EventEmitter();

  @Output() keypressEnter: EventEmitter<any> = new EventEmitter();
  @Output()
  adhocFilterClick: EventEmitter<any> = new EventEmitter();
  @Output()
  customFilterAction: EventEmitter<any> = new EventEmitter();
  @Output()
  customFilterPopupsubmit: EventEmitter<any> = new EventEmitter();
  @Output()
  refreshButtonClick:EventEmitter<any>=new EventEmitter();
  @Input() showFilterAlone:boolean = false;

  @Input()
  standardFilter:Array<string>=[]; 
  @Input()
  hasCustomFilter:boolean=false;
  @Input()
  hasAdhocFilter:boolean=false;
  @Input()
  hasFilter:boolean=true;
  customFilterText:string=""
  dropdownIconClass:string="icon-ic_fluent_grid_20_regular f_font_size_16 f_primarycolor k-icon ";
  customFilterData: Array<any> = [{
    text: "New filter",
    icon:"ms-Icon ms-Icon--Add f_font_size_16 f_margin_right_6 f_primarycolor"
  }, {
    text: "Clear filter", icon:"ms-Icon ms-Icon--ClearFilter f_font_size_16 f_margin_right_6 f_primarycolor"},
  ];
  dropdownViewData:any = [{icon:"icon-ic_fluent_grid_20_regular f_font_size_16 f_primarycolor k-icon f_margin_right_4",text:"List View"},
  {icon:"icon-ic_fluent_apps_list_20_regular f_font_size_16 f_primarycolor k-icon f_margin_right_4",text:"Grid View"}];
  normalview:boolean = true;
  iconClass = "icon-ic_fluent_list_28_regular f_font_size_16 f_primarycolor";

  @Input()

  // txtboxClass : any = "f_fullwidth";
  txtboxClass : any = "";
  
  views: Array<any> = [{
    text: "Normal List",
    icon:"icon-ic_fluent_list_24_regular f_font_size_16"
  }, {
      text: "Compact List",
      icon:"icon-ic_fluent_text_align_justify_24_regular f_font_size_16"
  }];


  @Input()
  hasSwitchView:boolean=false;
  @Input()
  defaultView: ViewType;



  @Input()

searchBoxSize : FalainaSearchSize = "small";

searchClass : any = "f_width_85percent";

txtboxIconClass : any = "f_primarycolor"

@Input() customContent:boolean = false;
@Input() customDetails:any;
  constructor(private sharedService: SharedService,private router: Router) { }

  ngOnInit(): void {
  
   this.setSearchSize(this.searchBoxSize);
   if(this.standardFilter?.length>0)
   {
     this.customFilterData.push({category:"Standard filter"});
   }
    this.standardFilter?.forEach(x=>{
      this.customFilterData.push({text:x})
    })
   if( this.defaultView =="List View")
   {
    
     this.dropdownIconClass="icon-ic_fluent_grid_20_regular f_primarycolor k-icon";
   }
   else{
    
    this.dropdownIconClass = "icon-ic_fluent_apps_list_20_regular f_primarycolor k-icon";
   }

   this.router.events.forEach((event) => {
    if (event instanceof NavigationEnd) { 
      this.filterValue = ""
      if(this.hasFilter){
        this.hasFilter = false;
        setTimeout(() => {
          this.hasFilter = true;
        }, 10);
      }
    }
  });
   
  }

  
  ngOnChanges(changes: SimpleChanges) {
    this.filterValue=""
    if (changes["searchBoxSize"]) {
     
      this.setSearchSize(changes["searchBoxSize"].currentValue);
  
    }
  }
  setSearchSize(value)
  {
//     if(this.searchBoxSize == "small")
//     {
//  this.searchClass =  "f_width_35percent";
//     }
//     else if(this.searchBoxSize == "medium"){
//       this.searchClass =  "f_width_60percent";
//     }
//     else if(this.searchBoxSize == "large")
//     {
//       this.searchClass =  "f_width_80percent";
//     }
//     else if(this.searchBoxSize == "Xlarge")
//     {
//       this.searchClass =  "f_fullwidth";
//     }

    switch (value) {
      case value = "small": 
 this.searchClass =  "f_width_85percent";
     this.txtboxIconClass = "f_primarycolor";
        break;
        case value = "Xsmall": 
        this.searchClass =  "f_width_25percent";
        this.txtboxIconClass = "f_primarycolor";
               break;
      case value = "medium": 
      this.searchClass =  "f_width_60percent";
      this.txtboxIconClass = "f_primarycolor";
        break;
      case value = "large": 
      this.searchClass =  "f_width_80percent";
      this.txtboxIconClass = "f_primarycolor";
        break;
      case value = "Xlarge": 
      this.searchClass =  "f_fullwidth"
      this.txtboxIconClass = "f_primarycolor";
    
        break;
     
      default:  
      this.searchClass =  "f_width_85percent";
      this.txtboxIconClass = "f_primarycolor";
        break;
    }

  }
  openDropdown(event:any){
    let normalView = this.normalview;
    if(normalView)
        this.iconClass = "icon-ic_fluent_list_28_regular f_font_size_16 f_primarycolor";
    else
        this.iconClass = "icon-ic_fluent_text_align_justify_24_regular f_font_size_16 f_primarycolor";
    setTimeout(function () {
        var element = document.querySelectorAll(".k-list-container .k-item");
        for(var i=0; i<element.length; i++){
          let currentelm = element[i];
          if(currentelm.innerHTML.includes("Normal List")){
            if(normalView){
              currentelm.classList.add("icon-ic_fluent_checkmark_24_regular");
            }
            else{
              currentelm.classList.remove("icon-ic_fluent_checkmark_24_regular");
            }
          }
          if(currentelm.innerHTML.includes("Compact List")){
            if(!normalView){
              currentelm.classList.add("icon-ic_fluent_checkmark_24_regular");
            }
            else{
              currentelm.classList.remove("icon-ic_fluent_checkmark_24_regular");
            }
          }
        }
      });
}
  ChangeGridView(e:any){
    this.normalview = (e.text == "Normal List");
    if(this.normalview)
        this.iconClass = "icon-ic_fluent_list_28_regular f_font_size_16 f_primarycolor";
    else
        this.iconClass = "icon-ic_fluent_text_align_justify_24_regular f_font_size_16 f_primarycolor";
    
    this.sharedService.gridViewChangeEmitted(e)
        
  }
  onSelectCustomFilter(event)
  {
    
   
    this.customFilterAction.emit(event)
   }
   onCustomFilterPopupSubmit(event)
   {
     this.customFilterPopupsubmit.emit(event)
   }
  adhocFilter(event)
  {
    this.adhocFilterClick.emit()
  }
  onFilterValueChange(event)
  {
    this.filterValue=event;
    if(this.filterValue=='' || this.filterValue==null)
    this.keypressEnter.emit(this.filterValue)
  }
  keyPress(event:KeyboardEvent)
  {
     if(event.key=="Enter")
        this.keypressEnter.emit(this.filterValue)
  }
  onSearchClick(e : any)
  {
    this.keypressEnter.emit(e)
  }
  dropdownItemClick(e){
    if(e.text == "List View"){
      this.dropdownIconClass = "icon-ic_fluent_grid_20_regular f_primarycolor k-icon";
      this.switchEvent.emit("List View")
    }
    else{
     
      this.normalview=true;
      this.openDropdown(true)
      this.dropdownIconClass = "icon-ic_fluent_apps_list_20_regular f_primarycolor k-icon";
      this.switchEvent.emit("Grid View")
    }   
  }
  refreshClick(e){
    this.filterValue=''
    this.keypressEnter.emit(this.filterValue)
    this.sharedService.refreshbuttonEmitted(true)
      this.refreshButtonClick.emit(e);
  }

@Input() gridExport : boolean = false;

  exportTypes : any[] = [{
    icon: "ms-Icon ms-Icon--ExcelDocument f_primarycolor f_font_size_18 f_margin_right_8",
    text: "Export To Excel"
  },
  {
    icon: "ms-Icon ms-Icon--ExcelLogo f_primarycolor f_font_size_18 f_margin_right_8",
    text: "Export To CSV"
  },
  {
    icon: "ms-Icon ms-Icon--PDF f_primarycolor f_font_size_18 f_margin_right_8",
    text: "Export To PDF"
  }];
  @Output() exportClick: EventEmitter<any> = new EventEmitter();
  exportItemClick(e :any)
  {
this.exportClick.emit(e);
  }
}
