import { DatePipe } from "@angular/common";
import { FormControl } from "@angular/forms";
import { SchedulerData } from "@models/scheduler/scheduler";
import { data } from "jquery";
import { FalainaLocalStorage } from "./falainaLocalStorage";

export class FalainaUtils
{
 
    /**
     * Filter array by string
     *
     * @param mainArr
     * @param searchText
     * @returns {any}
     */
    public static filterArrayByString(mainArr, searchText): any
    {
        if ( searchText === '' )
        {
            return mainArr;
        }

        searchText = searchText.toLowerCase();

        return mainArr.filter(itemObj => {
            return this.searchInObj(itemObj, searchText);
        });
    }

    /**
     * Search in object
     *
     * @param itemObj
     * @param searchText
     * @returns {boolean}
     */
    public static searchInObj(itemObj, searchText): boolean
    {
        for ( const prop in itemObj )
        {
            if ( !itemObj.hasOwnProperty(prop) )
            {
                continue;
            }

            const value = itemObj[prop];

            if ( typeof value === 'string' )
            {
                if ( this.searchInString(value, searchText) )
                {
                    return true;
                }
            }

            else if ( Array.isArray(value) )
            {
                if ( this.searchInArray(value, searchText) )
                {
                    return true;
                }
            }

            if ( typeof value === 'object' )
            {
                if ( this.searchInObj(value, searchText) )
                {
                    return true;
                }
            }
        }
    }

    /**
     * Search in array
     *
     * @param arr
     * @param searchText
     * @returns {boolean}
     */
    public static searchInArray(arr, searchText): boolean
    {
        for ( const value of arr )
        {
            if ( typeof value === 'string' )
            {
                if ( this.searchInString(value, searchText) )
                {
                    return true;
                }
            }

            if ( typeof value === 'object' )
            {
                if ( this.searchInObj(value, searchText) )
                {
                    return true;
                }
            }
        }
    }

    /**
     * Search in string
     *
     * @param value
     * @param searchText
     * @returns {any}
     */
    public static searchInString(value, searchText): any
    {
        return value.toLowerCase().includes(searchText);
    }

    /**
     * Generate a unique GUID
     *
     * @returns {string}
     */
    public static generateGUID(): string
    {
        function S4(): string
        {
            return Math.floor((1 + Math.random()) * 0x10000)
                       .toString(16)
                       .substring(1);
        }

        return S4() + S4();
    }

    /**
     * Toggle in array
     *
     * @param item
     * @param array
     */
    public static toggleInArray(item, array): void
    {
        if ( array.indexOf(item) === -1 )
        {
            array.push(item);
        }
        else
        {
            array.splice(array.indexOf(item), 1);
        }
    }

    /**
     * Handleize
     *
     * @param text
     * @returns {string}
     */
    public static handleize(text): string
    {
        return text.toString().toLowerCase()
                   .replace(/\s+/g, '-')           // Replace spaces with -
                   .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                   .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                   .replace(/^-+/, '')             // Trim - from start of text
                   .replace(/-+$/, '');            // Trim - from end of text
    }
    public static applyMaskingByRole(text,pattern,currentUserRole=null,exceptionRoles=null)
    {
        if (pattern == '' || !(pattern))
        return text
        if(!this.hasMasking(currentUserRole,exceptionRoles))
        return text
        text = this.maskingInput(text, pattern)
        return text
    }
   private static hasMasking(currentUserRole,exceptionRoles)
    {
    if(!currentUserRole || !exceptionRoles)
    return true;
      var userinfoRoles = currentUserRole
      var a=[]
     
     var index =userinfoRoles?.findIndex(m => m. RoleName == exceptionRoles);
      if(index === -1)
      return true
  
      return false
    
    }
    public static maskingInput(input:string,maskPattern:string)
        {
        var patternArr =  maskPattern.split('$');
        var intialVal = patternArr[1]
        var endVal = patternArr[2]
        var replaceChar = patternArr[3]
        var stringBuilder = ""
        var inputArr=[];
        inputArr.push(input);
        if(patternArr.length>5)
        {
            inputArr = input.split(patternArr[4])
        }
         
          for(let i=0;i<inputArr.length;i++)
          { 
             var output = this.applyMaskingPattern(inputArr[i],intialVal,endVal,replaceChar)
             stringBuilder = stringBuilder+output;
             if(inputArr.length>1 && (i+1 != inputArr.length))
             stringBuilder=stringBuilder+patternArr[4]
          }
          return stringBuilder; 

        }

        private static applyMaskingPattern(text:string,intialVal,endVal,replaceChar)
        {
               

                var stringBuilder=""
            //expected output Di******ar

            for (var i = 0; i < text.length; i++)
            {
                
                if(i < intialVal )
                {
                    stringBuilder=stringBuilder+text[i]
                }
                else if(i < (text.length-endVal))
                {
                    stringBuilder=stringBuilder+replaceChar
                }
                else
                {
                    stringBuilder=stringBuilder+text[i]
                }

               
                //return stringBuilder;
            }
            return stringBuilder;
        }

    // public static maskingInputNew(input,maskPattern){
    //     var output="*".repeat(input.length)
    //     var arr = maskPattern.split("$");
    //     //console.log(arr)
    //     for(let i=0;i < arr.length;i++){
    //       //console.log(arr[i])
    //       if(arr[i].length > 0){
           
    //         if(arr[i] !="*"){
    //           //console.log(arr[i])
    //           let initposition = arr[i].split("-")[0];
    //           let endposition = arr[i].split("-")[1];
    //          //console.log(initposition)
    //        //console.log(initposition =="length")
    //           if(initposition =="length"){
    //            //console.log("inside")
    //              for(let j=0;j< parseInt(endposition);j++){
    //               let showPos = input.length-(j+1)
                
    //               output = this.setCharAt(output,showPos,input[showPos]);
    //             }
    //           }else if(initposition =="mid"){
    //             for(let j=0;j< parseInt(endposition);j++){
    //               let midLen = input.length
    //               let showPos = parseInt(midLen)/2+j-1
                
    //               output = this.setCharAt(output,showPos,input[showPos.toFixed()]);
    //             }
    //           }else{
    //             for(let j=0;j< parseInt(endposition);j++){
    //               let showPos = parseInt(initposition)+j-1
                 
    //               output = this.setCharAt(output,showPos,input[showPos]);
    //             }
    //           }
             
    //         }
    //       }
    //     }
    //     return output; 
    //   }
    //   private static setCharAt(str,index,chr) {
    //     if(index > str.length-1) return str;
    //     return str.substring(0,index) + chr + str.substring(index+1);
    // }

    //#region DateTime Conversion

  private static formatToValidDate(date) {
        var a = typeof date;
        if ((typeof date == 'object' || typeof date == 'number' || date.length > 3) && date != null) {
          if (date.toString().includes('GMT') || typeof date == 'number') {
            return new Date(date)   
          }                
          return new Date(date)    
        }    
      }
     
     public static ConvertToLocalTimeZone(datetimestring,  datePipe: DatePipe){
        let formatDate = this.formatToValidDate(datetimestring)
        let dateFormat = this.getUserPreferenceDetail().dateZone;
        var date= datePipe.transform(formatDate,dateFormat); 
        
        return date ? date : formatDate.toString();
      }

      public static DateCustomFormat(dateValue:Date | string | number,isDateTimePickr:boolean=false ){
            if(!dateValue)
            return dateValue
            let localDate= new Date(dateValue)
            let d = localDate.getDate();
            let m = localDate.getMonth() + 1;
            let y = localDate.getFullYear();
            let date = [y, m, d].join("-");
            var time = ['00', '00','00'].join(":");
            if(isDateTimePickr)
            {
                let h = localDate.getHours().toString();
                var mn = localDate.getMinutes().toString() ;
                let ss = localDate.getSeconds().toString();
                if(h.length == 1)
                {
                    h ='0'+h;
                }
                if(mn.length == 1)
                {
                    mn ='0'+mn;
                }
                if(ss.length == 1)
                {
                    ss ='0'+ss;
                }
                
                var time = [h, mn,ss].join(":");

            }             
           
            let formatedDate = date +"T"+ time
            return formatedDate        
      }
      public static schedulerDateFormat(schedulerData :SchedulerData) : SchedulerData{
          schedulerData.eventEndDate =this.DateCustomFormat(schedulerData.eventEndDate,true)
          schedulerData.eventStartDate=this.DateCustomFormat(schedulerData.eventStartDate,true)
          schedulerData.eventRepeatDailyStartTime=this.DateCustomFormat(schedulerData.eventRepeatDailyStartTime,true)
          schedulerData.eventRepeatDailyEndTime=this.DateCustomFormat(schedulerData.eventRepeatDailyEndTime,true)
       
        return schedulerData        
  }

 

  public static ListCompare(a, b) {
    var ax = [], bx = [];
 
    a?.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
    b?.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
 
    while (ax?.length && bx?.length) {
      var an = ax?.shift();
      var bn = bx?.shift();
      var nn = (an[0] - bn[0]) || an[1]?.localeCompare(bn[1]);
      if (nn) return nn;
    }
 
    return ax?.length - bx?.length;
 }


    //   convertToTimeZone(){
    //     var date= this.datePipe.transform(this.formatDate(this.labelText),FalainaLocalStorage.getItem('datezone'), FalainaLocalStorage.getItem('timezone')); 
    //      return date
    //    }

    //#endregion
    public static getUserPreferenceDetail()
    {
        var userPreference ={
            dateZone: FalainaLocalStorage.getItem("datezone"),
         //   timeZone:FalainaLocalStorage.getItem("timezone"),
            language:FalainaLocalStorage.getItem("language")
        }
          return userPreference
    }

    /**
     *  validations for not allow only spaces in the textbox field
     * @param control 
     * @returns Validation error
     */

    public static  noWhitespaceValidator(control: FormControl): { whitespace: boolean; } {
        if(control.value == null || control.value == '') return null;
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }
}

