import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelModule } from '@progress/kendo-angular-label';
import { FalainalabelComponent } from './falainalabel.component';
import { TranslateModule } from '@ngx-translate/core';
import { FalainaSharedModule } from 'app/shared/shared.module';


@NgModule({
  declarations: [FalainalabelComponent],
  imports: [
    CommonModule,LabelModule,TranslateModule,FalainaSharedModule

  ],
  exports : [FalainalabelComponent]
})

export class FalainalabelModule { }
