import { NgModule } from '@angular/core';
import { MenuComponent } from './menu/menu.component';
import { AppbarComponent } from './appbar/appbar.component';
import { FalainaappbarModule } from 'app/shared/customcontrols/falainaappbar/falainaappbar.module';
import { FalainabuttonModule } from 'app/shared/customcontrols/falainabutton/falainabutton.module';
import { FalainatextboxModule } from 'app/shared/customcontrols/falainatextbox/falainatextbox.module';
import { FalainanotificationModule } from 'app/shared/customcontrols/falainanotification/falainanotification.module';
import { FalainaavatarModule } from 'app/shared/customcontrols/falainaavatar/falainaavatar.module';
import { FalainapopupModule } from 'app/shared/customcontrols/falainapopup/falainapopup.module';
import { FalainaaccordianModule } from 'app/shared/customcontrols/falainaaccordian/falainaaccordian.module';
import { FalainaautocompleteModule } from 'app/shared/customcontrols/falainaautocomplete/falainaautocomplete.module';
import { FalainabreadcrumbModule } from 'app/shared/customcontrols/falainabreadcrumb/falainabreadcrumb.module';
import { FalainadropdownbuttonModule } from 'app/shared/customcontrols/falainadropdownbutton/falainadropdownbutton.module';
import { FalainacontextmenuModule } from './../shared/customcontrols/falainacontextmenu/falainacontextmenu.module';
import { RouterModule } from '@angular/router';
import { FalainaSharedModule } from 'app/shared/shared.module';
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { FalainafilterModule } from 'app/shared/customcontrols/falainafilter/falainafilter.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { FalainaloadingsymbolModule } from 'app/shared/customcontrols/falainaloadingsymbol/falainaloadingsymbol.module';
import { AnonyAppbarComponent } from './appbar/anony-appbar/anony-appbar.component';
import { FalainastepperModule } from 'app/shared/customcontrols/falainastepper/falainastepper.module';
import { FalainamulticolumntemplategridModule } from 'app/shared/customcontrols/falainamulticolumntemplategrid/falainamulticolumntemplategrid.module';
import { FalainafileuploadModule } from 'app/shared/customcontrols/falainafileupload/falainafileupload.module';
import { UseruploadComponent } from './userupload/userupload.component';
import { FalainalabelModule } from 'app/shared/customcontrols/falainalabel/falainalabel.module';
import { FalainalinkModule } from 'app/shared/customcontrols/falainalink/falainalink.module';
import { FalainapipesModule } from 'app/shared/pipes/pipe.module';
import { MFASharedModule } from "app/shared/mfashared/mfashared.module";
@NgModule({
  declarations: [MenuComponent,AppbarComponent, AnonyAppbarComponent,UseruploadComponent],
  imports: [
    FalainaappbarModule,FalainabuttonModule,FalainatextboxModule,FalainacontextmenuModule,
    FalainanotificationModule,FalainaavatarModule,FalainapopupModule,
    FalainaaccordianModule,FalainaautocompleteModule,FalainabreadcrumbModule,
    FalainadropdownbuttonModule,RouterModule,DropDownsModule,
    FalainafilterModule,FalainaSharedModule,AngularSvgIconModule,TranslateModule,
    FalainaloadingsymbolModule,FalainastepperModule,
    FalainamulticolumntemplategridModule,FalainafileuploadModule,
    FalainalabelModule, FalainalinkModule, FalainapipesModule, MFASharedModule
  ],
  exports:[MenuComponent,AppbarComponent,AnonyAppbarComponent]
})
export class FalainaCoreModule { }
