import * as CryptoJS from 'crypto-js';
export class FalainaSessionStorage  
{  
   
    static password: any="oTJFUYS4uHP6T3preLkbog2cMhH64ZnJ";
    static sessionStorageData: any={};
   
    public static clear(): void {
        sessionStorage.clear();
    }
    public static getItem(key: string): string {
        
    
        var value = sessionStorage.getItem(key)
        if(!value)
        return null

        if(this.sessionStorageData[key] != null)
        {
            value=this.sessionStorageData[key];
            return value
        }
        value =CryptoJS.AES.decrypt( value,  this.password.trim() ).toString(CryptoJS.enc.Utf8);
        this.sessionStorageData[key]=value
        return value
    }
    public static  key(index: number): string {
        return sessionStorage.key(index)
    }
    public static removeItem(key: string): void {
       return sessionStorage.removeItem(key)
    }
    public static setItem(key: string, value: string): void {
        this.sessionStorageData[key]=null;
       value=  CryptoJS.AES.encrypt( value.toString(), this.password.trim()).toString();       
       return sessionStorage.setItem(key,value);
    }
    
}