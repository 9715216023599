import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "falaina-loading-symbol",
  templateUrl: "./falainaloadingsymbol.component.html",
  styleUrls: ["./falainaloadingsymbol.component.scss"],
  encapsulation : ViewEncapsulation.None
})
export class FalainaloadingsymbolComponent implements OnInit {
  loadingsymbolStyle:any = "display:none";
  @Input()
  loadingsymbolText : any = "'fálaina'";

  @Input()
  loadingsymbolBackground : any = true;

  loadingsymbolArrary : any[] = [];

  constructor() { }

  ngOnInit(): void {

    if(this.loadingsymbolText && this.loadingsymbolText.length > 0 )
    {
      for(var num = 0; num < this.loadingsymbolText.length; num++)
      {
        this.loadingsymbolArrary.push(this.loadingsymbolText[num]);
      }
    }
    else{
      this.loadingsymbolArrary = ['f', 'á', 'l', 'a', 'i', 'n', 'a', '.', '.', '.'];
    }
  }
}
