import { EventEmitter, Output, SimpleChanges } from "@angular/core";
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "falaina-button",
  templateUrl: "./falainabutton.component.html"
})
export class FalainabuttonComponent implements OnInit {

  @Input()
  buttonDisabled : boolean = false;
  
  @Input()
  enableIconButton:boolean = false;

  @Input()
  isTranslateEnabled : boolean = true;

  @Input() alternateButtonIcon:any = "";
  @Input() alternateButtonIconClass:any = "";
  @Input() alternateButtonImageUrl:any = "";

  @Input()
  buttonId: any = "falainaButton";
  btnText: any;

  @Input()
  get buttonText(){
    return this.btnText;
  }
  set buttonText(val:any){
    this.btnText = val;
  }

  @Input() buttonClass: any = "";

  @Input()
  buttonIconclass: string = "";

  @Input()
  buttonIiconclass: string = "";
 
  @Input() buttonDisableFormSubmit:boolean = false;
  @Input() alternateButtonDisableFormSubmit:boolean = false;
  btnShadow:boolean = false;
  @Input() buttonPrimary:boolean = true;
  @Input() buttonThemeColor:string = "primary";
  @Input() buttonWrapperClass:string = "";

  @Input() buttonOverflow : boolean = false;
  
  @Input() set buttonShadow(value: any) { 
    this.btnShadow = value;
    if(!value){ 
      this.buttonClass = this.buttonClass + " f_boxshadow_none";
    }else{
      this.buttonClass = this.buttonClass;
    }
  }
  get buttonShadow(): any {
    return this.btnShadow;
  }
  
  buttonLook: any = "'flat'";

  btnTransparentBackground:boolean = false;
  @Input() set buttonTransparentBackground(value: any) {
    this.btnTransparentBackground = value;
    if(value){
      this.buttonClass = this.buttonClass + " bgTransparent";
    }
  }
  get buttonTransparentBackground(): any {
    return this.btnTransparentBackground;
  }

  btnIcon:boolean = false;
  @Input() set buttonIcon(value: any) {
    this.btnIcon = value;
    if(value){
      this.buttonClass = this.buttonClass + " k-button-icon";
    }
  }
  get buttonIcon(): any {
    return this.btnIcon;
  }

  @Output()
  btnClick: EventEmitter<any> = new EventEmitter();

  public buttonClick(e: any) {
    if(this.buttonDisableFormSubmit)
      e.preventDefault()
    this.btnClick.emit(e);
  }
  @Output()
  alternateBtnClick: EventEmitter<any> = new EventEmitter();
  alternateButtonClick(e){
    if(this.alternateButtonDisableFormSubmit)
      e.preventDefault()
    this.alternateBtnClick.emit(e);
  }
  constructor() { this.buttonShadow = false;}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["buttonDisabled"]) {
      this.buttonDisabled = changes["buttonDisabled"].currentValue;
    }
  }
  ngOnInit(): void { 
    if(this.buttonThemeColor == "gray")
      this.buttonWrapperClass = "backGray";
    else if(this.buttonThemeColor == "success")
      this.buttonWrapperClass = "backSuccess";
    else if(this.buttonThemeColor == "warning")
      this.buttonWrapperClass = "backWarning";
    else if(this.buttonThemeColor == "transparent")
      this.buttonWrapperClass = "backTransparent";
      else if(this.buttonThemeColor == "backError")
      this.buttonWrapperClass = "backError";
      else if(this.buttonThemeColor == "InQueue")
      this.buttonWrapperClass = "backInQueue";
      else if(this.buttonThemeColor == "backwithdraw")
      this.buttonWrapperClass = "backwithdraw";
    else
      this.buttonWrapperClass = "k-primary";
  }
  ngAfterViewInit() {
    
  }
  
}
