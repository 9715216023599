import { Pipe, PipeTransform } from '@angular/core';
import { HomeService } from 'app/modules/home/service/homeservice.service';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FalainaLocalStorage } from '../utils/falainaLocalStorage';
import { DashboardDataPipe } from './dashboarddata.pipe';

@Pipe({
  name: 'dropDownData',
  pure: true
})
export class DashboardDropDownPipe implements PipeTransform {
    constructor(private homeService: HomeService) {

    }
    transform(item:any): Observable<any> {
        return this.LoadDropDown(item);
        
    // if (!isGridData)
    //     return this.LoadChartDropDown(item,item.GroupAttribute);
    // //else
    //    // return this.LoadListDropDown(item,arg1);
  }


  LoadDropDown(item): Observable<any> {
    let userId = Number(FalainaLocalStorage.getItem("userId"));
    return this.homeService
        .GetDashBoardDrpDwn(item.statisticsBuilderId, userId,item.GroupAttribute).pipe(
            map(
              
                (res:any) => {                    
                    if (res != undefined) {
                        var dropdownVal:any=[];
                        Object.keys(res).map((key) => {
                            if(key!="$type")
                            {
                                dropdownVal.push(
                                    {
                                      text: res[key],
                                      value: String(key)
                                    }
                                  )
                            }
                          
                          })
                    }   
                    item.dropDownVal=dropdownVal      
                    return dropdownVal;
                }
            ),
            catchError(err => {
                return throwError(err);
            })
        );          
}
}
