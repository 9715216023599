import {  HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '@environment/environment';
import { customHttpclient } from '@services/shared/customhttpclient';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfig } from "@models/config/appconfig";
import { AppConfigService } from "@services/shared/app-config-service";
import { Event } from 'jquery';
@Injectable({
  providedIn: 'root'
})
export class MFASharedService {
  private _clientId: string;
  private _clientSecreat: string;

  constructor(private http: customHttpclient, private config: AppConfigService) {
    var a: Observable<AppConfig> = this.config.loadConfigurations();
    a.subscribe(x => {
      this._clientId = x.APIClientId
      this._clientSecreat = x.APIClientSecret
    })
  }


  public validateSelfPwd() {
    let params = new HttpParams();
      return this.http.getdomainapi("PresentationSecurityInfo","ValidateSelfPasswordResetPrerequisite", params)
      .pipe(
        map(res =>
          res
        ),
        catchError(err => {
          return throwError(err);
        })
      );
  }
  public mandateSelfPwd() {
    let params = new HttpParams()
      return this.http.getdomainapi("PresentationSecurityInfo","MandateSelfPasswordResetPrerequisite", params)
      .pipe(
        map(res =>
          res
        ),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public isUserRegisteredMFA(userId) {
    let params = new HttpParams();
      return this.http.getdomainapi("PresentationSecurityInfo","IsUserRegisteredWithMFA", params)
      .pipe(
        map(res =>
          res
        ),
        catchError(err => {
          return throwError(err);
        })
      );
  }
  
  public getMatchedRuleMFA(userId,pageId,permissionId) {
    let params = new HttpParams() 
    .set("pageId",pageId)
    .set("permissionId",permissionId)
      return this.http.getdomainapi("PresentationMFARuleset","GetMatchedRuleMFA", params)
      .pipe(
        map(res =>
          res
        ),
        catchError(err => {
          return throwError(err);
        })
      );

  }
  public doMFA(MFAClientAccessValidation ) {
    let params = new HttpParams()
    
      return this.http.postdomainapi("MFA","DoMFA",MFAClientAccessValidation, params)
      .pipe(
        map(res =>
          res
        ),
        catchError(err => {
          return throwError(err);
        })
      );

  }
  doMFAApi(presentationMFAClientAccessValidation)
  { 
    // const headers= new HttpHeaders()
    // .set('ClientId', this._clientId)
    // .set('ClientSecret', this._clientSecreat)
    // .set('Content-Type','application/x-www-form-urlencoded')

    let params = new HttpParams()
    return this.http.postmfaapi("MFA","DoMFA",presentationMFAClientAccessValidation, params)
  
    //return this.httpclient.post<any>(environment.MFAAPI+"MFA/DoMFA/",presentationMFAClientAccessValidation,{headers:headers});
  }
  isMFAVerified( userName,pageName,loginTime)
  { 
    // const headers= new HttpHeaders()
    // .set('ClientId', this._clientId)
    // .set('ClientSecret', this._clientSecreat)
    let params = new HttpParams()
    .set("userName",userName)
    .set("applicationId",this._clientId)
    .set("pageName",pageName)
    .set("loginTime",loginTime);
    
    return this.http.getmfaapi("MFA","IsMFAVerified", params)
  
    //return this.httpclient.get<any>(environment.MFAAPI+"MFA/IsMFAVerified",{params,headers:headers});
  }

  @Output() mfaConfigEvent= new EventEmitter<any>();
   emitMfaConfigEvent(data)
  {
    this.mfaConfigEvent.emit(data);
  }

  @Output() showMFAAuthPopupEvent= new EventEmitter<any>();
  showMFAAuthPopup(data:{showPopup:boolean,policyId:any,type:any,ipAddress:any,city:any,countryCode:any,action:any})
 {
   
   this.showMFAAuthPopupEvent.emit(data);
 }
 @Output() CheckMFAContextAction = new EventEmitter<any>();
 CheckMFAContextMenu(action,dataitem,rowindex)
 {
   this.CheckMFAContextAction.emit({action,dataitem,rowindex});
 }
 @Output() ContextMenuMFAVerifiedEvent = new EventEmitter<any>();
 ContextMenuMFAVerified(contextMenuItems:any)
 {
   this.ContextMenuMFAVerifiedEvent.emit(contextMenuItems);
 }

}
