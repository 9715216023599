import { Component, HostListener, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CacheService } from "@services/shared/cacheservice";
import { SharedService } from "@services/shared/shared.service";
import { UserService } from "@services/user.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { SvgIconRegistryService } from "angular-svg-icon";
import { svgIconJson } from "assets/images/AzureSvgIcons/svgIconList";
import { authServiceDetail } from "./core/authentication/auth.service";
import { Title } from '@angular/platform-browser';
import { MFASharedService } from "./shared/mfashared/mfashared.service";
import { MasterConfigurationService } from "@services/master-configuration.service";
import { AppConfigService } from "@services/shared/app-config-service";
import { Observable } from "rxjs";
import { AppConfig } from "@models/config/appconfig";
import { FalainaSessionStorage } from "./shared/utils/falainaSessionStorage";
import { FalainaLocalStorage } from "./shared/utils/falainaLocalStorage";
import { ApplicationsettingsService } from "./modules/iacm/configuration/service/applicationsettings.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html"
})
export class AppComponent {
  title = "Home";

  browserTitle : any = "Falaina IAM";
  canActivate: boolean = false;
  tempMfaList: any;
  pageConfig: any = {};
  currentRoute: string;

  iframeSource: string
  clientId: string
  constructor(private configServ : AppConfigService,public translate: TranslateService, public oidcSecurityService: OidcSecurityService,
    private mfaSharedSer: MFASharedService, private userService: UserService, private sharedService: SharedService
    , private _registry: SvgIconRegistryService, private titleService: Title, private configmaster: MasterConfigurationService
    , private _appSettingsService: ApplicationsettingsService) {
    var sub: Observable<AppConfig> = this.configServ.loadConfigurations();
    sub.subscribe(x => {
      this.iframeSource = x.MFAURL
      this.clientId = x.APIClientId
    })
   
    this.addSvgInRegistery();
    this.oidcSecurityService.isAuthenticated$.subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        this.setTheme()
      }
      else {            
        this.getConfigByFalainaProductBranding();
        this.getApptheme()
        this.getUserData();        
        }
    });
    translate.setDefaultLang("en");
    translate.use("en");

setTimeout(() => {
  this.isLoaded =true;
}, 2000);

    
  } 
  setTheme() {
   this.configmaster.getApplicationTheme().subscribe(x=>{
    console.log(x);
    if(x["ConfigValue"])
      {
        document.documentElement.style.setProperty('--color',x["ConfigValue"]);
      }
      else{
        document.documentElement.style.setProperty('--color', "#0078d4");
      } 
   })
  }
  getUserData() {
    this.oidcSecurityService.userData$.subscribe(x => {
      if(x!=null && this.canActivate == false)
      { 
        authServiceDetail.LoggedinUserId = x?.sub;
        FalainaLocalStorage.setItem("userId",x?.sub); 
        this.setLoggedInTime();            
        this.cacheUserInfo(x?.sub); 
        this.canActivate = true;
      }
      else if(this.canActivate)
      {              
        authServiceDetail.LoggedinUserId = FalainaLocalStorage.getItem("userId");
        authServiceDetail.LoggedinUserInfo=JSON.parse(FalainaLocalStorage.getItem("userInfo"));
       
      }
    }) 
  }
  getConfigByFalainaProductBranding() {
    this.configmaster.GetConfigByKey("FalainaProductBranding").subscribe(x => {
      if (x) {
        this.titleService.setTitle(x.ConfigValue);
      }
    });
  }

  getApptheme()
  {
    this._appSettingsService.getApplicationSettings().subscribe(result => {
      if(result["ApplicaTionTheme"])
      {
        document.documentElement.style.setProperty('--color', result["ApplicaTionTheme"]);
      }
      else{
        document.documentElement.style.setProperty('--color', "#0078d4");
      } 
    });
  }
  
  setLoggedInTime()
  {
    if( FalainaSessionStorage.getItem("loginTime" ) == null)
    FalainaSessionStorage.setItem("loginTime" , new Date().toString())

  }
  addSvgInRegistery() {
    svgIconJson.forEach(x => {
      this._registry.addSvg(x.iconName, x.svgData);
    })
  }
  applyLanguageSettings(languageStr: string) {
    this.translate.use(languageStr);
  }
  cacheUserInfo(userId) {
    this.getUserInfo(userId)
  }
  getUserInfo(userId) {
    this.userService.getUserInfo(userId).subscribe(x => {
      authServiceDetail.LoggedinUserInfo = x
      // this.cacheService.save({ key: "userInfo", data: x });
      FalainaLocalStorage.setItem('userInfo',JSON.stringify(x))     
    })
  }
  url:string = "";

  isLoaded : boolean =false;

  inputText : any = "";
  showMFAPopup:boolean = false;
  ngOnInit() {
    this.sharedService.falainaUIConfigEvent.subscribe(x =>
      this.pageConfig = x
    )
    this.mfaSharedSer.showMFAAuthPopupEvent.subscribe(x=>{
      if(x.showPopup)
      this.showMFA(x);
      else
      this.showMFAPopup=false;
    })

    // this.sharedService.mfaActionEmittedEvent
    //   .subscribe((data) => {
    //     this.showMFA(data.action);
    //   });


    // let url = this.router.url;

    // this.router.events.forEach((event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.url = event.url;
    //     if(this.url.includes("home"))
    //       this.showMFA();
    //     }
    // });
  }
  ngOnChanges(changes: SimpleChanges) {
    // let url = this.router.url;
    // if(url.includes("home"))
    //   this.showMFA();
  }

  flag:boolean = false;
  showMFA(data){  
    let pName = sessionStorage.getItem("MFAPageIdName");
    this.showMFAPopup = data.showPopup;
    let userName = authServiceDetail.LoggedinUserInfo;
    let selectedLanguage=FalainaLocalStorage.getItem('language')!="null"?FalainaLocalStorage.getItem('language'):"en";
    setTimeout(() => {
      if (!this.flag) {
        if (data.action != "view") {
          this.iframeSource = this.iframeSource + "?ButtonID=" + data.action + "&userName=" + userName.UserName + "&clientId=" + this.clientId + "&pageName=" + pName+"&PolicyId="+data.policyId+"&Type="+data.type+"&ipAddress="+data.ipAddress+"&city="+data.city+"&countryCode="+data.countryCode+'&lng='+selectedLanguage;
        }
        else {
          this.iframeSource = this.iframeSource + "?userName=" + userName.UserName+"&PolicyId="+data.policyId+"&Type="+data.type+"&ipAddress="+data.ipAddress+"&city="+data.city+"&countryCode="+data.countryCode+'&lng='+selectedLanguage;

        }
        this.flag = true;
      }
    });
  }
  closebtnclick($event){
    this.showMFAPopup = false;
  }
  @HostListener('window:message', ['$event'])
  onMessage(event) {
    if(event !== null && typeof event?.data === 'string')
    {
      var res = event?.data?.split(",");
      if (res[0] == "MFASUCCESS") {
        this.inputText = "MFASUCCESS";         
        //  if (res[1]) {            
        //    var elem = document.querySelector<HTMLElement>("."+res[1]);
        //    if(elem != null)
        //    {
        //      elem.click();
        //    }
        //}
        let userName = authServiceDetail.LoggedinUserInfo.Id;
        let pageid = FalainaSessionStorage.getItem("MFAPageId");
        let permid = FalainaSessionStorage.getItem("MFAPageIdPerm");
        var lipages = JSON.parse(FalainaSessionStorage.getItem("MFAPageList"));
        if (pageid != null && pageid != undefined) {
          let lipages = JSON.parse(FalainaSessionStorage.getItem("MFAPageList")) || [];
          lipages.push({
            UserId: userName,
            PageId: pageid,
            PermId: permid
          });
          FalainaSessionStorage.setItem("MFAPageList", JSON.stringify(lipages));
        }
        this.showMFAPopup = false;
        let dataContext = JSON.parse(FalainaSessionStorage.getItem("MFAAction"));
        if (!dataContext?.dataitem) {
          let action = dataContext.action.text;
          if (action != "View")
          {
             this.sharedService.actionEmitted(action);
          }
        }
        else{
          this.mfaSharedSer.ContextMenuMFAVerified(dataContext)
        }
      }
      else {
        this.inputText =  "MFAFAILED";
      }
    }
      
  }
  ngAfterViewInit(){
    // setTimeout(() => {
    //   document.querySelector<HTMLElement>("."+"Add").click();
    // }, 5000);
    // let element:HTMLElement = document.getElementById("Add") as HTMLElement;
    // element.click();
  }
}
