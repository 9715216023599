import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MFASharedRoutingModule } from './mfashared-routing.module';
import { RegisterPrerequisiteComponent } from './register-prerequisite/register-prerequisite.component';
import { FalainapopupModule } from '../customcontrols/falainapopup/falainapopup.module';
import { FalainabuttonModule } from '../customcontrols/falainabutton/falainabutton.module';
import { FalainaSharedModule } from '../shared.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    RegisterPrerequisiteComponent
  ],
  imports: [
    CommonModule,
    FalainaSharedModule,
    MFASharedRoutingModule,
    FalainapopupModule,
    FalainabuttonModule,
    TranslateModule
  ],
  exports:[RegisterPrerequisiteComponent]
})
export class MFASharedModule { }
