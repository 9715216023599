import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { FalainadatepickerComponent } from './falainadatepicker.component';



@NgModule({
  declarations: [FalainadatepickerComponent],
  imports: [
    CommonModule,
    DateInputsModule,
    ButtonsModule
  ],
  exports: [FalainadatepickerComponent, DateInputsModule]
})
export class FalainadatepickerModule { }
