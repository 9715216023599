import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ChunkSettings, FileInfo, FileRestrictions, RemoveEvent, SelectEvent, UploadComponent, UploadEvent } from "@progress/kendo-angular-upload";

@Component({
  selector: "falaina-file-upload",
  templateUrl: "./falainafileupload.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FalainafileuploadComponent),
      multi: true
    }
  ]
})
export class FalainafileuploadComponent implements ControlValueAccessor, OnInit {
  @ViewChild("fileUpload")
  fileUploadCtrl!: UploadComponent;
  fileuploadStyle: any;
  fileuploadClass: any;
  @Input()
  fileuploadSaveUrl: any;
  @Input()
  fileuploadRemoveUrl: any;
  @Input()
  fileuploadAutoUpload: boolean = false;
  @Input()
  fileuploadConcurrent: boolean = false;
  @Input()
  fileuploadMultiple: boolean = false;
  @Input()
  fileuploadBatch: boolean = false;
  @Input()
  fileuploadChunkable: ChunkSettings;
  @Input()
  fileuploadMaxFileSize: number = 40000;
  @Input()
  fileuploadMinFileSize: number = 0;
  @Input()
  fileuploadAllowedExtensions: Array<string> = ["jpg", "jpeg", "png", "pdf"];
  fileuploadRestrictions: FileRestrictions = {
    allowedExtensions: this.fileuploadAllowedExtensions,
    maxFileSize: this.fileuploadMaxFileSize,
    minFileSize: this.fileuploadMinFileSize
  };
  @Input()
  fileuploadShowFileList: boolean = true;
  @Input()
  fileuploadDisabled: boolean = false;

  @Input()
  fileuploadDropzone: boolean = false;
  @Input()
 fileuploadMyFiles: Array<FileInfo> = [];

  myFiles: Array<FileInfo> = [];
  base64textString: string;

  constructor() { }

  ngOnInit(): void {
    this.fileuploadRestrictions = {
      maxFileSize: this.fileuploadMaxFileSize,
      minFileSize: this.fileuploadMinFileSize,
      allowedExtensions: this.fileuploadAllowedExtensions
    }
  }

  ngAfterViewInit(): void {
    if (this.fileuploadBatch) {
      this.fileUploadCtrl.chunkable = {
        size: 102400,
        resumable: false,
      };
    }
  }
  // @Input('fileuploadMyFiles') fileupldMyFiles;
  onChange: any = (value) => { };
  onTouched: any = () => { };
  // get fileuploadMyFiles() {
  //   return this.fileupldMyFiles;
  // }

  // set fileuploadMyFiles(val) { 
  //   this.fileupldMyFiles = val;
  //   this.onChange(val);
  //   this.onTouched();
  // }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.fileuploadMyFiles = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }




  @Output()
  select: EventEmitter<any> = new EventEmitter();

  selectEventHandler(e: SelectEvent) {

    this.onChange(e.files);
    this.onTouched();
    let selectedfiles = [];

    e.files.forEach((file, index) => {
      var _this = this;
      if (file) {
        var reader = new FileReader();
        reader.onloadend = (readerEvt) => {
          var binaryString = readerEvt.target.result.toString();
          this.base64textString = btoa(binaryString);
          let selectedfile = {
            e: file,
            base64textString: this.base64textString //passing basestring 
          } // Converting binary string data.
          selectedfiles.push(selectedfile);
          if (index == e.files.length - 1) {
            this.select.emit(selectedfiles)
          }
        }
        reader.readAsBinaryString(file.rawFile);

      }
    })

  }


  uploadEventHandler(e: UploadEvent) {
    e.data = {
      description: "File upload",
    };
  }
  errorEventHandler(e: ErrorEvent) {
    //console.log(e);
  }


  @Output()
  remove: EventEmitter<any> = new EventEmitter(); //emmitting the event while remove the file
  removeEventHandler(e: RemoveEvent) {
    e.data = {
      description: "File remove",
    };
    this.remove.emit(e)
  }

  @Output()
  clear: EventEmitter<any> = new EventEmitter(); //emmitting the event while clear the file
  clearEventHandlers(e: any, event: any) {
    if (e.target.outerText ===" Clear ") {

      e.data = {
        description: "Files clear",
      };
      this.clear.emit(e)
    }
  }
  clearHandler(e: any) {
   
    const clearValue = document?.getElementsByClassName('k-clear-selected')
    this.clearEventHandlers(e, clearValue);
  }



  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);  // Converting binary string data.
  }
}

