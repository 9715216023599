import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { customHttpclient } from '@services/shared/customhttpclient';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsettingsService {

  private autocompletecontrollername = "AutoCompleteDataService"
  constructor(private http: customHttpclient) { }

   //#region CRUD
   getApplicationSettings(){
    let params = new HttpParams()
    return this.http.getdomainapi("PresentationApplicationSettings","GetApplicationSettings ",params)
  }

  getAuthenticationModes(){
    let params = new HttpParams()
    return this.http.getdomainapi("PresentationApplicationSettings","GetAuthenticationModes ",params)
  }

  getAuthenticationDomains(searchString){
    let params = new HttpParams()
    .set('searchString',searchString)
    return this.http.getdomainapi("AutoCompleteDataService","GetAuthenticationDomains",params)
  }

  getOTPOptions(){
    let params = new HttpParams()
    return this.http.getdomainapi("PresentationApplicationSettings","GetOTPOptions ",params)
  }

  getPasswordResets(){
    let params = new HttpParams()
    return this.http.getdomainapi("PresentationApplicationSettings","GetPasswordResets ",params)
  }

  postApplicationSettings(data){
    let params = new HttpParams()
    return this.http.postdomainapi("PresentationApplicationSettings","Save",data,params)
  }

  postClientLogo(data){
    let params = new HttpParams()
    return this.http.postdomainapi("PresentationApplicationSettings","SaveClientLogo",data,params)
  }

  postApplicationLogo(data){
    let params = new HttpParams()
    return this.http.postdomainapi("PresentationApplicationSettings","SaveApplicationLogo",data,params)
  }

  postPamSetup(data){
    let params = new HttpParams()
    return this.http.postdomainapi("PresentationApplicationSettings","SavePAMApplicationSettings",data,params)
  }
  public GetAllLogo() {
    let params = new HttpParams()
    return this.http.getdomainapi("PresentationLogo","GetAll",params)
  }
  public GetFilterData(skip: any, pagesize: any, sortExpression: any, searchString: any) {
    let params = new HttpParams().set('skip',skip).set('pagesize',pagesize).set('sortExpression',sortExpression).set('searchString',searchString)
    return this.http.getdomainapi("PresentationLogo","GetFilterData",params)
  }

  public GetById(id:any) {
    let params = new HttpParams().set('id',id);
    return this.http.getdomainapi("PresentationLogo","GetLogoById",params)
  }

  public saveLogo(logo : any): Observable<boolean> {
    let params = new HttpParams()
    
    let actionname = 'SaveLogo'
    return this.http.postdomainapi('PresentationLogo', actionname,logo, params);
  }

  public deleteLogo(  id : any): Observable<boolean> {
    let params = new HttpParams().set('id',id);
    
    let actionname = 'DeleteLogo'
    return this.http.postdomainapi('PresentationLogo', actionname,null, params);
  }

  //
  public getGlobalLanguages(){
    var tenentName='GLOBALLANGUAGES';
    let params = new HttpParams().set('tenentName',tenentName);
    return this.http.getdomainapi("PresentationCodesTable","GetAllByTenentName",params)
  }
}
