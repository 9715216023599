import { AccountType } from "@enum/EndpointSystemType";

export class CheckedOutItems {
    RequesterId: number;
    Items: CheckedOutItemDetail[];
    FYIUser: number | null;
    Attachments : Array<CartAttachment>;
    
    CheckoutType : CheckoutType; 
    RequestId ?: number;
    CampaignId ?: number;
    ActionUserId ?: number;
    TaskId ?: number;
}

export class CartAttachment{
    ContentType :string;
    FileName : string;
    AttachmentFile : any;
    FileId ?: any;
}
export class CheckedOutItemDetail {
    RequestUserId: number;
    ValidFrom: any;
    ValidTo: any;
    Comments: string;
    RequestFor : string;

    RequestDetailId : number;

    AccountType ?: AccountType;

    AccountName : string;

    Items : string;

    EndpointName : string;
}

export class CheckoutCart {
    RequesterId: number;
    FYIUser: number | null;
    Attachments: Array<CartAttachment>;
    ValidFrom: any;
    ValidTo: any;
    Comments: string;
    CheckoutType : CheckoutType;
    CampaignId ?: number;
    TaskId?:number;

    RequestId?:number;
    ActionUserId ?: number;
}

export enum CheckoutType {
    Cart = 0,
    Express,
    LightWeight,
}