import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { RouteConfigLoadEnd, Router } from '@angular/router';
import { ColumnSetting } from '@models/miscellaneous/ColumnSetting';
import { breadCrumb } from 'app/core/models/breadcrumbs';
import { MenuPageData, MenuPageSetting } from 'app/core/models/menuPageSetting';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  constructor(public router:Router)
  {

  }
  @Output() menuPageConfigEnableEvent= new EventEmitter<any>();
 
  menuPageConfigEmitted(setting:MenuPageSetting)
  {
    this.menuPageConfigEnableEvent.emit(setting)
  }
//  @Output() menuPageConfigDataEvent= new EventEmitter<any>();
 
//   menuPageDataEmitted(data:MenuPageData)
//   {
//     this.menuPageConfigDataEvent.emit(data)
//   }
  @Output() falainaUIConfigEvent= new EventEmitter<any>();
  falainaPageConfig(config:any)
  {
    this.falainaUIConfigEvent.emit(config)
  }
  
  @Output() addBreadCrumbEvent= new EventEmitter<any>();
  addBreadCrumb(data:{loadpagepermission:boolean,pageUrl:any,breadCrumbValue:any,customData?:any})
  {
    this.addBreadCrumbEvent.emit(data)
  }
  @Output() breadCrumbByURLEvent= new EventEmitter<any>();
   breadCrumbByURL(url)
  {
    this.breadCrumbByURLEvent.emit(url);
  }
  
//   @Output() hierarchyLinkableColumnClickEvent= new EventEmitter<any>();
//   hierarchyLinkableColumnClick(dataItem : any,field : any)
//  {
//    this.hierarchyLinkableColumnClickEvent.emit({ "dataItem" : dataItem , "field" : field});
//  }

//  @Output() hierarchySODSABtnClickEvent= new EventEmitter<any>();
//  hierarchySODSABtnClick(dataItem : any,field : any)
// {
//   this.hierarchySODSABtnClickEvent.emit({ "dataItem" : dataItem , "text" : field});
// }

@Output() enTitlementDataEmittedEvent= new EventEmitter<any>();
enTitlementDataEmit(dataItem : any , heirarchyColumns : ColumnSetting[], heirarchyChildColumns : ColumnSetting[],pageEvent:{skip,take} = null,isPaginationServSide=false)
{
  this.enTitlementDataEmittedEvent.emit({"dataItem" : dataItem,"heirarchyColumns" : heirarchyColumns,"heirarchyChildColumns" : heirarchyChildColumns,pageEvent,isPaginationServSide});
}
  @Output() profileImageEmit= new EventEmitter<any>();
   setProfileImage(url)
  {
    this.profileImageEmit.emit(url);
  }

  @Output() mfaAuthenticationUrl= new EventEmitter<any>();
  getCurrentURl(url)
 {
   this.mfaAuthenticationUrl.emit(url);
 }

  //#region filter event's 
  @Output() menuFilterEnableEvent= new EventEmitter<boolean>();
  menuFilterEmitted(filterSetting:any)
  {
    this.menuFilterEnableEvent.emit(filterSetting)
  }
  @Output() filterOnEnterEmittedEvent = new EventEmitter<any>();
  filterOnEnterEmittedFn(e){
    this.filterOnEnterEmittedEvent.emit(e);
  }
  
  @Output() adhocFilterClickEvent = new EventEmitter<any>();
  adhocFilterClickFn(e){
    this.adhocFilterClickEvent.emit(e);
  }

  @Output() customFilterSelectEvent = new EventEmitter<any>();
  customFilterSelectFn(e){
    this.customFilterSelectEvent.emit(e);
  }
  @Output() customFilterPopupSubmit = new EventEmitter<any>();
  customFilterPopupSubmitFn(e){
    this.customFilterPopupSubmit.emit(e);
  }
  @Output() switchViewEmittedEvent = new EventEmitter<any>();
  switchViewEmitted(e){
    this.switchViewEmittedEvent.emit(e);
  }

  @Output() exportDataEmittedEvent = new EventEmitter<any>();
  exportViewEmitted(e){
    this.exportDataEmittedEvent.emit(e);
  }

  @Output() refreshClickEmitted = new EventEmitter<any>();
  refreshButtonClickEmitted(e){
    this.refreshClickEmitted.emit(e);
  }

  @Output() pickSessionClickEmitted = new EventEmitter<any>();

  pickSessionItemClick(e){
    this.pickSessionClickEmitted.emit(e);
  }

  //#endregion

  @Output() actionEmittedEvent = new EventEmitter<string>();
  @Output() allpageActionEmitted = new EventEmitter<any>();
  //Menu.component.html
  
  getAllpageAction(actions:any[])
  {
    this.allpageActionEmitted.emit(actions);
  }
@Output() contextshortCutActionEvent= new EventEmitter<any>();
  getContextandIconAction(data:{contextMenuAction:any[],shortCutMenu:any[]})
  {
    this.contextshortCutActionEvent.emit(data);
  }
  actionEmitted(msg: string) {
    this.actionEmittedEvent.emit(msg);
  }
  @Output() mfaActionEmittedEvent = new EventEmitter();
  mfaActionEmitted(obj) {
    this.mfaActionEmittedEvent.emit(obj);
  }
  @Output() gridViewChangeEmittedEvent = new EventEmitter<any>();
  gridViewChangeEmitted(e) {
    this.gridViewChangeEmittedEvent.emit(e);
  }
  @Output() dropdownOpenEmittedEvent = new EventEmitter<any>();
  dropdownOpenEmitted(e) {
    this.dropdownOpenEmittedEvent.emit(e);  
  }
  //#region cart count
  @Output() cartcountEmittedEvent = new EventEmitter<any>();
  cartcountEmitted(e) {
    this.cartcountEmittedEvent.emit(e);
  }

  @Output() LightWeightcartcountEmittedEvent = new EventEmitter<any>();
  lightWeightCartcountEmitted(e) {
    this.LightWeightcartcountEmittedEvent.emit(e);
  }

  //#endregion

  @Output() showDashboardPopupEmittedEvent = new EventEmitter<any>();
  showDashboardPopupEmitted(e) {
    this.showDashboardPopupEmittedEvent.emit(e);
 
  }
  @Output() showglobalerrorEmittedEvent = new EventEmitter<any>();
  showglobalerrorEmitted(e) {

    this.showglobalerrorEmittedEvent.emit(e);
// console.log(this.showglobalerrorEmittedEvent)
  }
  @Output() dateformatEmittedEvent = new EventEmitter<any>();
  dateformatEmitted(e) {

    this.dateformatEmittedEvent.emit(e);

  }
  @Output() timezoneEmittedEvent = new EventEmitter<any>();
  timezoneEmitted(e) {

    this.timezoneEmittedEvent.emit(e);

  }

  @Output() UserDashboardPreferenceEmittedEvent = new EventEmitter<any>();
  UserDashboardPreferenceEmitted(e) {

    this.UserDashboardPreferenceEmittedEvent.emit(e);

  }

  @Output() refreshbuttonEmittedEvent = new EventEmitter<any>();
  refreshbuttonEmitted(e) {

    this.refreshbuttonEmittedEvent.emit(e);

  }
  
  @Output() isHomeEmittedEvent = new EventEmitter<any>();
  isHomeEmittedEventEmitted(e) {

    this.isHomeEmittedEvent.emit(e);

  }

  @Output() userprofileEmittedEvent = new EventEmitter<any>();
  userprofileEmitted(e) {

    this.userprofileEmittedEvent.emit(e);

  }

  @Output() activeTabEmittedEvent = new EventEmitter<any>();
  activeTabEmitted(e) {
    this.activeTabEmittedEvent.emit(e);
  }

  @Output() detailpopupEmittedEvent = new EventEmitter<any>();
  detailpopupEmitted(e) {
    this.detailpopupEmittedEvent.emit(e);
  }
  
  @Output() coloumnvisibilityEmittedEvent = new EventEmitter<any>();
  coloumnvisibilityEmitted(e) {
    this.coloumnvisibilityEmittedEvent.emit(e);
  }
  @Output() disablePagePermissionsEmittedEvent = new EventEmitter<any>();
  disablePagePermissionsEmitted(e) {
    this.disablePagePermissionsEmittedEvent.emit(e);
  }

  @Output() quickLaunchresizeEmittedEvent = new EventEmitter<any>();
  quickLaunchresizeEmitted(e) {
    this.quickLaunchresizeEmittedEvent.emit(e);
  }

  @Output()
  changeDateTimeZone: EventEmitter<{timeZone:string,dateFormat:string,isTimeZoneEmitted:boolean,
    isDateFormatEmitted:boolean}> = new EventEmitter();

  dateTimeZoneEmitFn(e) {
    this.changeDateTimeZone.emit(e);
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);

  }
  @Output() menuExpandEmittedEvent = new EventEmitter<any>();

  menuExpandEmitted(e) {

    this.menuExpandEmittedEvent.emit(e);

  }
}
