import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BadgeAlign, BadgeFill, BadgePosition } from "@progress/kendo-angular-indicators";
""
@Component({
  selector: "falaina-notification",
  templateUrl: "./falainanotification.component.html"
})
export class FalainanotificationComponent implements OnInit {

  @Input()
  notificationDisabled : boolean = false;

  @Input()
  notificationButtonClass="";

  @Input()
  notificationIconclass : any = "'k-icon k-i-bell notifications'";

  @Input()
  notificationCount : any = "0";

  @Input()
  notificationButtontxt : any = "";
  
  @Input()
  notificationShape : any = "circle";

  @Input()
  notificationThemecolor : any = "info";

  @Input()
  notificationAlign : BadgeAlign = { vertical: "bottom", horizontal: "end" };
  
  @Input()
  notificationPosition : BadgePosition = "edge";

  @Input()
  notificationSize : any = "small"

  @Input()
  notificationFill : BadgeFill = "outline";

  @Input()
  notificationAvatarSrc : any = "";

  @Input()
  notificationCutbroder : boolean = false;

  @Input()
  notificationButtonclass  : any  = "k-button";

  constructor() { }
  ngOnInit(): void {
    if(this.notificationAvatarSrc)
      this.notificationIconclass = "";
  }
  @Output() notifyBtnClick: EventEmitter<any> = new EventEmitter();
  buttonClick(e)
  {
    this.notifyBtnClick.emit(e);
  }
}
