import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChildren } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "falaina-toggle-button",
  templateUrl: "./falainatogglebutton.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FalainatogglebuttoncontrolComponent),
      multi: true
    }
  ]
})
export class FalainatogglebuttoncontrolComponent implements ControlValueAccessor, OnInit {
  @ViewChildren("falainatogglebuttoncontrol") falainaSwitch: any;

  @Input()
  switchId: any = "falainaSwitch";

  switchClass: any = "";

  @Input()
  switchReadonly: boolean = false;

  switchStyle: any = ""

  @Input()
  switchDisabled: boolean = false;

  // @Input()
  // switchChecked : any = false;

  @Input()
  switchOnlabel: any = "";

  @Input()
  switchOfflabel: any = "";

  labelClass: any = "";

  @Output()
  onswitchChange: EventEmitter<any> = new EventEmitter();

  public onSwitchValueChange(e: any) {
    this.onswitchChange.emit(e);
  }

  @Output()
  onswitchfocus: EventEmitter<any> = new EventEmitter();

  public Onfocus() {
    this.onswitchfocus.emit();
  }

  @Output()
  onswitchblur: EventEmitter<any> = new EventEmitter();

  public onblur() {
    this.onswitchblur.emit();
  }

  constructor() { }

  ngOnInit(): void {
    var liston, index,listoff;
    liston = document.getElementsByClassName("k-switch-label-on");
    listoff = document.getElementsByClassName("k-switch-label-off");
    
    for (index = 0; index < liston.length; ++index) {
      liston[index].setAttribute('translate','');
    }
    for (index = 0; index < listoff.length; ++index) {
      listoff[index].setAttribute('translate','');
    }
  }

  ngAfterViewInit() {
    // if (this.switchDisabled) {
    //   document.getElementById(this.switchId).setAttribute("disabled", String(this.switchDisabled));
    // }
  }

  @Input('switchChecked') switchCheckedValue = false;
  get switchChecked() {
    return this.switchCheckedValue;
  }

  set switchChecked(val) {
    this.switchCheckedValue = val;

    this.onChange(val);
    this.onTouched();

  }
  onChange: any = () => { };
  onTouched: any = () => { };


  registerOnChange(fn) {
    this.onChange = fn;

  }

  writeValue(value) {

    this.switchChecked = value;

  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }



}
