import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { FalainaloadingService } from 'app/shared/customcontrols/falainaloadingsymbol/falainaloading.service';
import { from, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { AuthService, authServiceDetail } from "./auth.service";
import { ErrorService} from "@services/error.service";
import { SharedService } from "@services/shared/shared.service";
import { AppNotificationService } from "@services/notificationservice.services";
import { AppConfig } from '@models/config/appconfig';
import { AppConfigService } from '@services/shared/app-config-service';
@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private _authService: AuthService,
    private _router: Router) { }
  count = 0;
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // if(!authServiceDetail.isAuthenticated)
    // {
    // var a=  this._router.url

    //   this._authService.doLogin();
    // }
    var t0 = performance.now()
    this.count++;

    if (this._authService.token) { 
      const clonedRequest = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + this._authService.token)
      });
      return next.handle(clonedRequest);
    }
    else {

      return next.handle(req);
    }
  }
}


@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  public router: Router
  messageArray =[]
  showErrorMessageBox: boolean=true;
  constructor(public loading: FalainaloadingService,private errorservice:ErrorService,
    private notificationservice:AppNotificationService,private config : AppConfigService) {
      var a:Observable<AppConfig>= this.config.loadConfigurations();
      a.subscribe(x=>{
       this.showErrorMessageBox=x.ShowErrorMessageInUI
      })

  }
  count = 0;
 
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    
    this.loading.show();
   
    var t0 = performance.now()
    this.count++;
    
  
    return next.handle(request).pipe(tap(
      (event: HttpEvent<any>) => {
     
        let a= event?.type
       },
      err => {
        this.messageArray.push(this.errorservice.getClientErrorMessage(err))
        if (err instanceof HttpErrorResponse) {
         console.error(err,"error")
        }
      }
    ), finalize(() => {
      this.count--;
      var t1 = performance.now()
    
      if (this.count == 0)
    
              setTimeout(() => {
                
          /** spinner ends after 0.50 seconds */
          this.loading.hide();
          if(this.messageArray.length > 0 && this.showErrorMessageBox){
            this.notificationservice.getGlobalError(this.messageArray);
            this.messageArray =[]
          }
          
        }, 500);
    })
    );
  }

}
