import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FalainatextboxComponent } from './falainatextbox.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@progress/kendo-angular-buttons';


@NgModule({
  declarations: [FalainatextboxComponent],
  imports: [
    CommonModule,InputsModule,FormsModule,ButtonModule
  ],
  exports:[FalainatextboxComponent,FormsModule]
})
export class FalainatextboxModule { }
