import { breadCrumb } from "./breadcrumbs"


export class MenuPageSetting{
hasBreadCrumbs?:boolean
hasPageTitle ?:boolean
pageTitleSize ?:SizeEnum
collapseMenu ?:boolean
}
export class MenuPageData{
  breadCrumbData?:breadCrumb
  pageTitle ?:string

  permissions?:Array<{ icon?:any,text:any}>
  collapseMenu ?:boolean
  }

export enum SizeEnum {
    Small = "small",
    Medium = "medium",
    Large = "large"
  }
  