import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FalainamessageboxComponent } from './falainamessagebox.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [FalainamessageboxComponent],
  imports: [
    CommonModule,TranslateModule
  ],
  exports : [FalainamessageboxComponent]
})
export class FalainamessageboxModule { }
