export interface timeZone {
  value:string,
  text:string
}
export const timeZone: timeZone[] = [
  {value:"UTC−10:00",text:"Society Islands, Tuamotus, Austral Islands"},
  {value:"UTC−09:30",text:"Marquesas Islands"},
  {value:"UTC−09:00",text:"Gambier Islands"},
  {value:"UTC−08:00",text:"Clipperton Island"},
  {value:"UTC−04:00",text:"Guadeloupe, Martinique, Saint Barthélemy, Saint Martin"},
  {value:"UTC−03:00",text:"French Guiana, Saint Pierre and Miquelon"},
  {value:"UTC+01:00",text:"metropolitan France"},
  {value:"UTC+03:00",text:" Mayotte, Scattered Islands in the Indian Ocean"},
  {value:"UTC+04:00",text:" Réunion, Crozet Islands"},
  {value:"UTC+05:00",text:"Kerguelen Islands, Saint Paul and Amsterdam Islands"},
  {value:"UTC+10:00",text:"New Caledonia"},
  {value:"UTC+11:00",text:"Adélie Land"},
  {value:"UTC+12:00",text:"Wallis and Futuna"},
  {value:"UTC+02:00",text:"Kaliningrad Oblast"},
  {value:"UTC+03:00",text:"Most of European Russia"},
  {value:"UTC+04:00",text:"Astrakhan Oblast"},
  {value:"UTC+05:00",text:"Bashkortostan "},
  {value:"UTC+06:00",text:"Omsk Oblast "},
  {value:"UTC+07:00 ",text:"Altai Krai "},
  {value:"UTC+08:00",text:"Buryatia and Irkutsk Oblast "},
  {value:"UTC+09:00 ",text:"Amur Oblast "},
  {value:"UTC+10:00 ",text:"Jewish Autonomous Oblast "},
  {value:"UTC+11:00",text:"Magadan Oblast"},
  {value:"UTC+12:00",text:"Chukotka and Kamchatka Krai "},
  {value:"UTC−12:00",text:"Baker Island and Howland Island "},
  {value:"UTC−11:00",text:"American Samoa "},
  {value:"UTC−10:00",text:"Hawaii,Johnston Atoll "},
  {value:"UTC−09:00",text:"most of the state of Alaska "},
  {value:"UTC−08:00",text:"the Pacific coast states"},
  {value:"UTC−07:00",text:"most of Idaho, part of Oregon"},
  {value:"UTC−06:00",text:"a large area spanning from the Gulf Coast to the Great Lakes"},
  {value:"UTC−05:00",text:"roughly a triangle covering all the states from the Great Lakes down to Florida and east to the Atlantic coast"},
   {value:"UTC−04:00",text:"Puerto Rico, the U.S. Virgin Islands "},
   {value:"UTC+10:00",text:"Guam and the Northern Mariana Islands "},
   {value:"UTC+12:00",text:"Wake Island"},
   
   {value:"UTC−03:00",text:" Palmer Station, Rothera Station "},
   {value:"UTC±00:00",text:" Troll Station "},
   {value:"UTC+03:00",text:" Syowa Station "},
   {value:"UTC+05:00",text:" Mawson Station "},
   {value:"UTC+06:00",text:" Vostok Station "},
   {value:"UTC+07:00",text:" Davis Station "},
   {value:"UTC+10:00",text:" Dumont-d'Urville Station "},
   {value:"UTC+11:00",text:" Casey Station "},
   {value:"UTC+12:00 ",text:" McMurdo Station"},
   
   {value:"UTC+05:00 ",text:"Heard and McDonald Islands "},
   {value:"UTC+06:30 ",text:"Cocos (Keeling) Islands "},
   {value:"UTC+07:00 ",text:"Christmas Island "},
   {value:"UTC+08:00  ",text:"Western Australia) "},
   
    {value:"UTC+08:45  ",text:" South Australia "},
   
   {value:"UTC+09:30 ",text:"Northern Territory "},
    {value:"UTC+10:00",text:" Queensland "},
    {value:"UTC+10:30 ",text:" Lord Howe Island "},
	{value:"UTC+11:00 ",text:" Norfolk Island "},
	
	
	{value:"UTC−08:00 ",text:" Pitcairn Islands "},
	{value:"UTC−05:00 ",text:"Cayman Islands"},
	{value:"UTC−04:00 ",text:"Anguilla "},
	{value:"UTC−03:00 ",text:"Falkland Islands "},
	{value:"UTC−02:00",text:"South Georgia "},
	{value:"UTC±00:00",text:"Saint Helena"},
	{value:"UTC+01:00",text:"Gibraltar "},
	{value:"UTC+02:00",text:"Akrotiri and Dhekelia "},
	{value:"UTC+06:00",text:"British Indian Ocean Territory "},
	
	{value:"UTC−08:00",text:"Yukon "},
	{value:"UTC−07:00",text:"Alberta"},
   	{value:"UTC−06:00",text:" Manitoba"},
  
{value:"UTC−05:00",text:"Southampton Island"},
  
{value:"UTC−04:00",text:"New Brunswick "},

	{value:"UTC−03:30",text:"Newfoundland "},
	
	
	
	{value:"UTC−04:00",text:"Thule Air Base in Greenland "},
		{value:"UTC−03:00",text:"most of Greenland"},
		{value:"UTC−01:00",text:"Greenland's Tunu county "},
		{value:"UTC±00:00",text:"Faroe Islands "},
		{value:"UTC+01:00",text:"main territory of Denmark "},


{value:"UTC−11:00 ",text:"Niue "},
{value:"UTC−11:00 ",text:"Cook Islands "},
{value:"UTC+12:45",text:"Chatham Islands"},
{value:"UTC+13:00",text:"Tokelau"},

{value:"UTC−05:00",text:" Acre and Southwestern Amazonas"},
{value:"UTC−04:00",text:" Mato Grosso"},
{value:"UTC−03:00",text:" Goiás"},

{value:"UTC−02:00",text:"Trindade"},


{value:"UTC−08:00",text:" the state of Baja California"},
{value:"UTC−07:00",text:"Chihuahua, Nayarit"},
{value:"UTC−06:00",text:" most of Mexico"},
{value:"UTC−05:00",text:" the state of Quintana Roo"},

{value:"UTC−06:00",text:" Easter Island"},

{value:"UTC−04:00",text:" main territory of Chile"},
{value:"UTC−03:00",text:" Magallanes and Chilean Antarctica"},


{value:"UTC+07:00",text:"slands of Sumatra"},

{value:"UTC+08:00",text:" islands of Sulawesi, Bali"},

{value:"UTC+09:00",text:"Papua"},



{value:"UTC+12:00  ",text:"  Gilbert Islands "},
{value:"UTC+13:00   ",text:"  Phoenix Islands "},
{value:"UTC+14:00    ",text:"   Line Islands "},

{value:"UTC++01:00    ",text:"Kinshasa, Kongo Central"},
{value:"UTC+02:00    ",text:" Kasaï"},


{value:"UTC−06:00     ",text:"   Galápagos Province"},
{value:"UTC−06:00     ",text:"   main territory of Ecuador"},

{value:"UTC+10:00     ",text:"   the states of Chuuk and Yap"},
{value:"UTC+11:00     ",text:"    the states of Kosrae and Pohnpei"},


{value:"UTC+05:00 ",text:"western Kazakhstan"},
{value:"UTC+06:00 ",text:"eastern Kazakhstan"},

{value:"UTC−04:00 ",text:"Caribbean municipalities and constituent countries"},
{value:"UTC+01:00 ",text:"main territory of the Netherlands"},


{value:"UTC+07:00  ",text:"the provinces of Khovd, Uvs and Bayan-Ölgii"},

{value:"UTC+08:00  ",text:" most of the country"},


{value:"UTC+10:00 ",text:" most of the country"},

{value:"UTC+11:00 ",text:" Autonomous Region of Bougainville"},

{value:"UTC−01:00 ",text:"  Azores"},

{value:"UTC±00:00",text:" Madeira and the main territory of Portugal"},


{value:"UTC+02:00 ",text:" main territory of South Africa"},

{value:"UTC+03:00 ",text:"  Prince Edward Islands"},

{value:"UTC±00:00 ",text:"  Canary Islands"},
{value:"UTC+01:00  ",text:"  main territory of Spain"},

{value:"UTC+02:00[4]  ",text:"   Ukraine"},

{value:"UTC+04:30  ",text:"   Afghanistan"},

{value:"UTC+01:00  ",text:"  Albania"},

{value:"UTC+01:00  ",text:"    Algeria"},
{value:"UTC+01:00  ",text:"  Andorra"},
{value:"UTC+01:00  ",text:"  Angola"},

{value:"UTC−04:00  ",text:"  Antigua and Barbuda"},
{value:"UTC−03:00   ",text:"  Argentina"},

{value:"UTC+04:00   ",text:"  Armenia"},
 {value:"UTC+01:00   ",text:"  Austria"},

 {value:"UTC+04:00   ",text:"  Azerbaijan"},
 {value:"	UTC−05:00   ",text:" Bahamas"},
 
  {value:"	UTC+03:00   ",text:"  Bahrain"},


  {value:"	UTC+06:00    ",text:"  Bangladesh"},
  
   {value:"	UTC−04:00    ",text:" Barbados"},
  {value:"	UTC+03:00     ",text:" Belarus"},
  
  {value:"	UTC+01:00     ",text:" Belgium"},
    {value:"UTC−06:00    ",text:" Belize"},
	 {value:"UTC+01:00    ",text:"  Benin"},
	 
	{value:"UTC+06:00    ",text:"  Bhutan"},
	{value:"UTC−04:00    ",text:"  Bolivia"},
  {value:"	UTC+01:00    ",text:"  Bosnia and Herzegovina"},
  
    {value:"UTC+02:00     ",text:"  Botswana"},
  {value:"UTC+08:00     ",text:"  Brunei"},
   {value:"	UTC+02:00      ",text:"  Bulgaria"},
   
    {value:"UTC±00:00     ",text:" Burkina Faso"},
   
    {value:"UTC+02:00      ",text:" Burundi"},
   
   {value:"	UTC+07:00    ",text:" Cambodia"},

{value:"UTC+01:00      ",text:" Cameroon"},

{value:"UTC−01:00     ",text:" Cape Verde"},

{value:"UTC+01:00       ",text:" Central African Republic"},
{value:"UTC+01:00        ",text:" Chad"},

{value:"UTC+08:00       ",text:" China"},

{value:"UTC−05:00      ",text:" Colombia"},

{value:"UTC+03:00       ",text:" Comoros"},


{value:"UTC+01:00       ",text:" Republic of the Congo"},
{value:"UTC−06:00       ",text:" Costa Rica"},
{value:"UTC+01:00      ",text:" Croatia"},
{value:"UTC−05:00     ",text:" Cuba"},
{value:"UTC+02:00      ",text:"  Cyprus"},

{value:"UTC+01:00      ",text:"  Czech Republic"},
{value:"UTC+03:00     ",text:"  Djibouti"},

{value:"UTC−04:00     ",text:" Dominica"},
{value:"UTC−04:00     ",text:" Dominican Republic"},

{value:"UTC+09:00    ",text:" East Timor"},
{value:"UTC+02:00     ",text:" Egypt"},
{value:"UTC−06:00     ",text:"  El Salvador"},
{value:"UTC+01:00    ",text:"  Equatorial Guinea"},
{value:"UTC+03:00     ",text:"  Eritrea"},
{value:"UTC+02:00    ",text:"  Estonia"},
{value:"UTC+02:00    ",text:"  Eswatini (Swaziland)"},

{value:"UTC+03:00     ",text:"   Ethiopia"},
{value:"UTC+12:00     ",text:"   Fiji"},
{value:"UTC+02:00    ",text:"    Finland"},
{value:"UTC+01:00     ",text:"   Gabon"},
{value:"UTC±00:00     ",text:"   Gambia"},
{value:"UTC+04:00    ",text:"   Georgia"},
{value:"UTC+01:00     ",text:"   Germany"},
{value:"UTC±00:00     ",text:"   Ghana"},

{value:"UTC+02:00      ",text:"    Greece"},

{value:"UTC−04:00      ",text:"    Grenada"},

{value:"UTC−06:00      ",text:"    Guatemala"},

{value:"UTC±00:00     ",text:"   Guinea"},
{value:"	UTC±00:00     ",text:"   Guinea-Bissau"},
{value:"	UTC−04:00     ",text:"   Guyana"},
{value:"	UTC−05:00     ",text:"   Haiti"},

{value:"UTC−06:00     ",text:"  Honduras"},

{value:"UTC+08:00      ",text:"  Hong Kong"},

{value:"UTC+01:00      ",text:" Hungary"},
{value:"UTC±00:00     ",text:" Iceland"},
{value:"UTC+05:30      ",text:" India"},
{value:"UTC+03:30       ",text:"  Iran"},
{value:"UTC+03:00     ",text:"  Iraq"},
{value:"UTC±00:00      ",text:"  Ireland"},
{value:"UTC+02:00       ",text:"  Israel"},
{value:"UTC+01:00       ",text:"  Italy"},
{value:"UTC±00:00       ",text:"  Ivory Coast"},
{value:"UTC−05:00      ",text:"   Jamaica"},
{value:"UTC+09:00       ",text:"   Japan"},
{value:"UTC+02:00       ",text:"   Jordan"},
{value:"UTC+03:00       ",text:"    Kenya"},
{value:"UTC+09:00       ",text:"    Korea, North"},
{value:"UTC+09:00      ",text:"    Korea, South"},
{value:"UTC+03:00      ",text:"    Kuwait"},

{value:"UTC+06:00     ",text:"   Kyrgyzstan"},

{value:"UTC+07:00   ",text:"   Laos"},

{value:"UTC+02:00   ",text:"    Latvia"},
{value:"UTC+02:00   ",text:"    Lebanon"},
{value:"UTC+02:00   ",text:"    Lesotho"},
{value:"UTC±00:00   ",text:"     Liberia"},
{value:"UTC+02:00  ",text:"    Libya"},
{value:"UTC+01:00  ",text:"    Liechtenstein"},

{value:"UTC+02:00   ",text:" Lithuania"},

{value:"UTC+01:00    ",text:"   Luxembourg"},

{value:"UTC+08:00     ",text:"   Macau"},

{value:"UTC+03:00     ",text:"   Madagascar"},

{value:"UTC+02:00      ",text:"    Malawi"},

{value:"UTC+08:00       ",text:"    Malaysia"},

{value:"UTC+05:00      ",text:"  Maldives"},
{value:"UTC±00:00     ",text:"  Mali"},
{value:"UTC+01:00    ",text:"  Malta"},
{value:"UTC+12:00    ",text:"  Marshall Islands"},

{value:"UTC±00:00    ",text:"  Mauritania"},
{value:"UTC+04:00     ",text:"  Mauritius"},
{value:"UTC+02:00     ",text:"  Moldova"},
{value:"UTC+01:00      ",text:"   Monaco"},
{value:"UTC+01:00     ",text:"  Montenegro"},
{value:"UTC+01:00     ",text:"   Morocco"},
{value:"UTC+02:00      ",text:"    Mozambique"},

{value:"UTC+06:30",text:"   Myanmar"},
{value:"UTC+01:00 ",text:"   Namibia"},

{value:"UTC+12:00 ",text:"   Nauru"},

{value:"UTC+05:45 ",text:"  Nepal"},

{value:"UTC−06:00 ",text:" Nicaragua"},

{value:"UTC+01:00 ",text:" Niger"},
{value:"UTC+01:00 ",text:" Nigeria"},

{value:"UTC+01:00  ",text:" North Macedonia"},

{value:"UTC+01:00 ",text:" Norway"},

{value:"UTC+04:00 ",text:" Oman"},
{value:"UTC+05:00  ",text:" Pakistan"},
{value:"UTC+09:00  ",text:" Palau"},
{value:"UTC+02:00  ",text:" Palestine"},
{value:"UTC−05:00  ",text:" Panama"},

{value:"UTC−04:00  ",text:"  Paraguay"},

{value:"UTC−05:00  ",text:"  Peru"},

{value:"UTC+08:00  ",text:"  Philippines"},
{value:"UTC+01:00   ",text:"  Poland"},
{value:"UTC+03:00    ",text:" Qatar"},
{value:"UTC+02:00    ",text:" Romania"},
{value:"UTC+02:00    ",text:" Rwanda"},
{value:"UTC−04:00    ",text:"  Saint Kitts and Nevis"},
{value:"UTC−04:00    ",text:"  Saint Lucia"},
{value:"UTC−04:00    ",text:"   Saint Vincent and the Grenadines"},

{value:"UTC+13:00    ",text:"   Samoa"},
{value:"UTC+01:00    ",text:"   San Marino"},

{value:"UTC+00:00    ",text:"    São Tomé and Príncipe"},
{value:"UTC+03:00    ",text:"     Saudi Arabia"},
{value:"UTC±00:00    ",text:"     Senegal"},
{value:"UTC+01:00     ",text:"     Serbia"},

{value:"UTC+04:00      ",text:"    Seychelles"},

{value:"UTC±00:00      ",text:"    Sierra Leone"},
{value:"UTC+08:00",text:"    Singapore"},

{value:"UTC+01:00 ",text:"    Slovakia"},


{value:"UTC+01:00 ",text:"   Slovenia"},


{value:"UTC+11:00 ",text:"   Solomon Islands"},
{value:"UTC+03:00 ",text:"   Somalia"},
{value:"UTC+02:00  ",text:"   South Sudan"},
{value:"UTC+05:30   ",text:"   Sri Lanka"},

{value:"UTC+02:00   ",text:"   Sudan"},

{value:"UTC−03:00   ",text:"  Suriname"},
{value:"UTC+01:00   ",text:"  Sweden"},
{value:"UTC+01:00   ",text:"  Switzerland"},
{value:"UTC+02:00   ",text:"  Syria"},
{value:"UTC+08:00   ",text:" Taiwan"},

{value:"UTC+05:00   ",text:" Tajikistan"},

{value:"UTC+03:00   ",text:" Tanzania"},
{value:"UTC+07:00   ",text:" Thailand"},
{value:"UTC±00:00   ",text:"  Togo"},
{value:"UTC+13:00   ",text:"  Tonga"},
{value:"UTC−04:00    ",text:"  Trinidad and Tobago"},
{value:"UTC+01:00    ",text:"  Tunisia"},
{value:"UTC+03:00    ",text:"   Turkey"},

{value:"UTC+05:00    ",text:"   Turkmenistan"},
{value:"UTC+12:00    ",text:"    Tuvalu"},
{value:"UTC+03:00    ",text:"   Uganda"},
{value:"UTC+04:00    ",text:"United Arab Emirates"},
{value:"UTC−03:00",text:"Uruguay"},

{value:"UTC+05:00",text:"Uzbekistan"},
{value:"UTC+11:00",text:"Vanuatu"},

{value:"UTC+01:00",text:"Vatican City"},
{value:"UTC−04:00",text:"Venezuela"},
{value:"UTC+07:00 ",text:"Vietnam"},
{value:"UTC+03:00",text:" Yemen"},
{value:"UTC+02:00",text:"Zambia"},
{value:"UTC+02:00",text:"Zimbabwe"},
]






