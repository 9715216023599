import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FalainastepperComponent } from './falainastepper.component';
import { FalainaStepperContentComponent } from './falainasteppercontent';
import { FalainaStepComponent } from './falainasteps';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { IconsModule } from '@progress/kendo-angular-icons';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { TranslateModule } from '@ngx-translate/core';
import { FalainaSharedModule } from 'app/shared/shared.module';
@NgModule({
  declarations: [FalainastepperComponent, FalainaStepperContentComponent, FalainaStepComponent],
  imports : [
    CommonModule,
    LayoutModule,
    IconsModule,
    ButtonsModule,
    TranslateModule,
    FalainaSharedModule
  ],
  exports : [
    FalainastepperComponent, FalainaStepperContentComponent, FalainaStepComponent
    
  ]
})
export class FalainastepperModule { }
