import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from "@progress/kendo-angular-layout";
import { FalainaavatarComponent } from './falainaavatar.component';
import { SkeletonElementsModule } from'skeleton-elements/angular';
import { FalainapipesModule } from 'app/shared/pipes/pipe.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PopupModule } from '@progress/kendo-angular-popup';
import { FalainapopupComponent } from '../falainapopup/falainapopup.component';
import { FalainapopupModule } from '../falainapopup/falainapopup.module';

@NgModule({
  declarations: [FalainaavatarComponent],
  imports: [
    CommonModule,LayoutModule,SkeletonElementsModule 
  ],
  exports:[
    FalainaavatarComponent,
    FalainapipesModule,
    AngularSvgIconModule
  ]
})
export class FalainaavatarModule { }
