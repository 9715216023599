import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FalainatogglebuttoncontrolComponent } from './falainatogglebutton.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [FalainatogglebuttoncontrolComponent],
  imports: [
    CommonModule,InputsModule, FormsModule,TranslateModule
  ],
  exports:[FalainatogglebuttoncontrolComponent]
})
export class FalainatogglebuttonModule { }
