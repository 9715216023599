import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FalainaautocompleteComponent } from './falainaautocomplete.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
// import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [FalainaautocompleteComponent],
  imports: [
    CommonModule,DropDownsModule,FormsModule,AutocompleteLibModule,TranslateModule
  ],
  exports:[FalainaautocompleteComponent,FormsModule]

})
export class FalainaautocompleteModule { }
