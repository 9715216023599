import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "falaina-link",
  templateUrl: "./falainalink.component.html"
})
export class FalainalinkComponent implements OnInit {

  @Input()
  linkHref : any ="#";
  
  @Input() linkClass : any = "";
  
  linkStyle : any = "";
  
  @Input()
  linkTarget : any = "_self";
  
  @Input()
  linkText: any = "";

  @Input() isTranslateEnabled : boolean = true;
 
  constructor() { }

  @Output() onClick: EventEmitter<any> = new EventEmitter();
  onSelect(e:any)
  {
    e.preventDefault();
    this.onClick.emit(e);
  }

  ngOnInit(): void {
  }

}
