import { JsonpClientBackend } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FalainaLogoService } from '@services/FalainaLogoConfig/falainalogoconfig.service';
import { FalainaLocalStorage } from 'app/shared/utils/falainaLocalStorage';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'anony-appbar',
  templateUrl: './anony-appbar.component.html',
 
})
export class AnonyAppbarComponent implements OnInit {
  appLogoTxt: string="";

  constructor(private falainaLogoService :FalainaLogoService, private sanitizer: DomSanitizer) { 

    this.getLogoData();
  }

  ngOnInit(): void {
    
  }
  getLogoData()
  {
    let data = JSON.parse(FalainaLocalStorage.getItem("logoData"))
    if(data)
    {
      this.setLogoData(data)
      return;
    }
    const arr = [this.getLogoImageData(),this.getLogoname()];
    let multiCall = forkJoin(arr);
  
    multiCall.subscribe(data=>{      
        
        var x={
          logoName:data[1],
          imageData:data[0],
         
        }
        let jsonData=JSON.stringify(x);
        FalainaLocalStorage.setItem("logoData",jsonData)
        this.setLogoData(x)
    })
  }
  setLogoData(data: {logoName:any,imageData:any}) {
    if (data?.logoName) {
     let appLogoTxt = data?.logoName["ConfigValue"];
      if (appLogoTxt) {
        if (typeof(appLogoTxt)==  'string') {
          this.hasLogoTxt = true;
          this.appLogoTxt=appLogoTxt;
        }
      }
      else {
        this.hasLogoTxt = false;
      }
    }
    if (data?.imageData) {            
      let objectURL = "data:image/png;base64," + data?.imageData["ConfigValue"];
      this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    
    }
  }

  image: any;
  getLogoImageData(): any {
  return  this.falainaLogoService.getImageData()     
       
  }

  
  hasLogoTxt: boolean = false;

  getLogoname(): any {
   
   return this.falainaLogoService.getLogoValue()

  }
 
}
