import { Component, EventEmitter, Input, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';

@Component({
  selector: 'falaina-dropdown-button',
  templateUrl: './falainadropdownbutton.component.html'
})
export class FalainadropdownbuttonComponent implements OnInit {
  align:string = "right";
  @Input()
  Id="dpDefault"
  @Input()
  listclass=""
  
  constructor(private renderer: Renderer2) { }
  ngOnInit(): void {

    if(!this.dropDownBtnTxt)
    {
      let dropdown = document.getElementsByClassName("dropDownBtn");
       for(let i = 0 ; i < dropdown.length; i++)
       {
        this.renderer.addClass(dropdown[i].childNodes[0],"iconButton");
        this.renderer.addClass(dropdown[i].childNodes[0],"f_gap_0"); 
       }
 
    }
    var rect = document.getElementById("dropdownButton").getBoundingClientRect();
    if(rect.x > 900){
      this.align = "right";
    }
    else if(rect.x < 150){
      this.align = "left";
    }
  
    var liston, index;
    liston = document.getElementsByClassName("k-step-text");
    
    
     
      setTimeout(() => {
        for (index = 0; index < liston.length; ++index) {
          liston[index].setAttribute('translate','');
        }   
      }, 1000);
  }

  ngOnChanges(change:SimpleChanges) : void
  {
    var rect = document.getElementById("dropdownButton")?.getBoundingClientRect();
    if(rect && rect.x > 900){
      this.align = "right";
    }
    else if(rect && rect.x < 150){
      this.align = "left";
    }
  }
  @Input()

  dropBtnDisabled : boolean = false;	

  @Input()
  dropDownBtnTxt:string=null;
  @Input() dropdownButtonData:any = [];
  @Input() dropdownButtonIconClass:string = "ms-Icon ms-Icon--More";
  
  @Output() dropdownOpen: EventEmitter<any> = new EventEmitter();
  onOpen(event){
    
    this.dropdownOpen.emit(event);
  }
  @Output() dropdownItemClick: EventEmitter<any> = new EventEmitter();
  onItemClick(event){
    this.dropdownItemClick.emit(event);

  }
  
}
