import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { FalainabuttonComponent } from './falainabutton.component';
import { TranslateModule } from '@ngx-translate/core';
import { FalainaSharedModule } from 'app/shared/shared.module';
import { FalainalabelModule } from 'app/shared/customcontrols/falainalabel/falainalabel.module';
@NgModule({
  declarations: [FalainabuttonComponent],
  imports: [
    CommonModule,
    ButtonsModule,TranslateModule,FalainaSharedModule,FalainalabelModule
  ],
  exports:[FalainabuttonComponent]
})
export class FalainabuttonModule { }
