import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FalainabreadcrumbComponent } from './falainabreadcrumb.component';
import { NavigationModule } from '@progress/kendo-angular-navigation'; 
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [FalainabreadcrumbComponent],
  imports: [
    CommonModule,NavigationModule,TranslateModule
  ],exports:[FalainabreadcrumbComponent]
})
export class FalainabreadcrumbModule { }
