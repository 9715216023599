import { EventEmitter, Injectable, Output } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LocalStorageSaveOptions } from '@services/shared/cacheservice';
import { SharedService } from '@services/shared/shared.service';
import { OidcConfigService, OidcSecurityService } from 'angular-auth-oidc-client';
import { MFASharedService } from 'app/shared/mfashared/mfashared.service';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService, authServiceDetail } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate , CanActivateChild {

  constructor(private authService:AuthService,private sharedService : SharedService,private mfa:MFASharedService, private OidcConfigService :OidcSecurityService) {  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.authService.checkAuth().pipe(map((x:boolean)=>{
      authServiceDetail.isAuthenticated=x;
      if(authServiceDetail.isAuthenticated)
      {
        
        let url = state.url;               
        this.sharedService.breadCrumbByURL(url);
        this.sharedService.getCurrentURl(url);
        this.checkMFAPwdResetConfig(url);
        
        return true;
      }
      else
      {
        
        this.authService.doLogin();
        return false;
      }
    })
  ) 
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
     return this.authService.checkAuth().pipe(map((x:boolean)=>{
      authServiceDetail.isAuthenticated=x;
      var  a = this.OidcConfigService.getState();
      if(authServiceDetail.isAuthenticated)
      {       
        return true;
      }
      else
      {        
        this.authService.doLogin();
        return false;
      }
    })
  ) 
 }


checkMFAPwdResetConfig(url)
{


    const arr = [this.mfa.validateSelfPwd(),this.mfa.mandateSelfPwd()];
    let multiCall = forkJoin(arr);

    multiCall.subscribe(data=>{      
        
        var x={
          validateSelfPWD:data[0],
          mandateSelfPWD:data[1],
          url:url
        }
        this.mfa.emitMfaConfigEvent(x);
    })
}


}
