import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { DashboardDataPipe } from './dashboarddata.pipe';
import { DashboardDropDownPipe } from './dashboardDropDown.pipe';

import { FilterPipe,SafePipe } from './filter.pipe';
import { DashboardItemsPipe, FullNamePipe, GetByIdPipe } from './getById.pipe';
import { GetCoOrdinates } from './getcoordinates.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { KeysPipe } from './keys.pipe';
import { LoadSVGIconsPipe } from './loadsvIconspipe.pipe';
import { Randomtheme } from './randomtheme.pipe';
 
@NgModule({
  declarations: [KeysPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    FilterPipe,SafePipe,
    CamelCaseToDashPipe,DashboardItemsPipe,FullNamePipe,Randomtheme,GetCoOrdinates,DashboardDropDownPipe,
    DashboardDataPipe,LoadSVGIconsPipe],
  imports: [
    
  ],
  exports:[
    KeysPipe,SafePipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    FilterPipe,
    CamelCaseToDashPipe,
    DashboardItemsPipe,
    FullNamePipe, 
    Randomtheme,
    GetCoOrdinates,
    DashboardDataPipe,
    DashboardDropDownPipe,
    LoadSVGIconsPipe
  ]
})
export class FalainapipesModule { }
