import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FalainalinkComponent } from './falainalink.component';
import { TranslateModule } from '@ngx-translate/core';
import { FalainaSharedModule } from 'app/shared/shared.module';



@NgModule({
  declarations: [FalainalinkComponent],
  imports: [
    CommonModule,TranslateModule,FalainaSharedModule
  ],
  exports:[FalainalinkComponent]
})
export class FalainalinkModule { }
