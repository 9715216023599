import { EventEmitter, Injectable, Output, Pipe, PipeTransform } from '@angular/core';
import { HomeService } from 'app/modules/home/service/homeservice.service';
import { truncate } from 'fs';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FalainaLocalStorage } from '../utils/falainaLocalStorage';

@Pipe({
    name: 'dashboarddata',
    pure: true
})
export class DashboardDataPipe implements PipeTransform {
    dashboardArray: any[] = [];
    dashboardListArray: any[] = [];
    statisticsIds: any[] = [];
    statisticsListIds: any[] = [];
    result: any[] = [];
    selectedfieldValue: any;
    selectedfieldText: any;
    selectedAttestationText: any;
    selectedRiskScoreText: any;
    dashboardData: any = "";
    indexRiskScore: number = 1;
    campaignFilterItems: any;
    activeCampaignByLevelArr: any;
    activeCampaignByLevelArrLsts: any;
    activeCampaignDropdownItems: any;

    attestationFilterItems: any;
    attestationArr: any;
    attestationArrLsts: any;
    attestationDropdownItems: any;
    selectedAttestationValue: any;

    riskScoreFilterItems: any;
    riskScoreArr: any;
    riskScoreArrLsts: any;
    riskScoreDropdownItems: any;
    selectedRiskScoreValue: any;

    flag: number = 0;
    constructor(private homeService: HomeService) {

    }
    //{data: any[], total:number}
    counter = 0;
    transform(item:any, isGridData: boolean,arg1:any,filterVal,): Observable<any> {
        var grpAtt="";
        var grpValue=""
        if( item.GroupAttribute != null && item.GroupAttribute!='' && item.GroupAttribute!=undefined )
        {
            grpValue=filterVal;
            grpAtt=item.GroupAttribute;
        }        
        if (!isGridData){
            return this.LoadDashboard(item,grpAtt,grpValue);
        }
        else{
            return this.LoadListDashboard(item,arg1,grpAtt,grpValue);
        }
    }


    LoadListDashboard(item,arg1,grpAtt,grpValue): Observable<any> {
        let userId = Number(FalainaLocalStorage.getItem("userId"));
        var res =  this.homeService
        .GetPagedQueryResult(item.statisticsBuilderId, userId,arg1,grpAtt,grpValue).pipe(
                map(
                    (res:any) => {
                        
                        if (res != undefined) {
                            var obj = JSON.parse(res);
                            var _data = [];
                            for (var i in obj.Result) {
                                _data.push(obj.Result[i]);
                            }

                            var _columns = [];
                            for (var j in obj.Columns) {
                                _columns.push(obj.Columns[j]);
                            }
                        }  
                                     
                        var reslt =  { data: _data, columns:_columns,  total: obj.TotalNoOfRecords, title: item.CommandName };
                        
                        return reslt;
                    }
                ),
                catchError(err => {
                    return throwError(err);
                })
            );  
            return res;     
    }

    LoadDashboard(item,grpAtt,grpValue): Observable<any> {
        let userId = Number(FalainaLocalStorage.getItem("userId"));
        return this.homeService
        .GetQueryResult(item.statisticsBuilderId, userId,grpAtt,grpValue)
            .pipe(map((result:any) => {  
                if (result != undefined) {
                    var obj = JSON.parse(result)    
                }
                return obj;
            }
            ),
                catchError(err => {
                    return throwError(err);
                })
            );
    }
    // GetQueryString(query: any, name: string) {

    //     if (name === "Active Campaign By Level") {
    //         this.activeCampaignByLevelArrLsts = query;
    //         this.campaignFilterItems = this.activeCampaignByLevelArrLsts.map((t: any) => t.CampaignName).filter((value, index, self) => self.indexOf(value) === index);
    //         if (this.campaignFilterItems.length > 0) {
    //             var activecampaign = [];
    //             for (let i = 0; i < this.campaignFilterItems.length; i++) {
    //                 activecampaign.push({
    //                     text: this.campaignFilterItems[i],
    //                     value: this.indexRiskScore
    //                 })
    //                 this.indexRiskScore++;
    //             }
    //             this.activeCampaignDropdownItems = activecampaign;
    //             this.selectedfieldValue = this.activeCampaignDropdownItems[0].value;
    //             this.selectedfieldText = this.activeCampaignDropdownItems[0].text;
    //         }
    //     }
    //     else if (name === "My Attestation Approval Status") {
    //         this.attestationArrLsts = query;
    //         this.attestationFilterItems = this.attestationArrLsts.map((t: any) => t.AttestationName).filter((value, index, self) => self.indexOf(value) === index);
    //         if (this.attestationFilterItems.length > 0) {
    //             var attestation = [];
    //             for (let i = 0; i < this.attestationFilterItems.length; i++) {
    //                 attestation.push({
    //                     text: this.attestationFilterItems[i],
    //                     value: this.indexRiskScore
    //                 })
    //                 this.indexRiskScore++;
    //             }
    //             this.attestationDropdownItems = attestation;
    //             this.selectedAttestationValue = this.attestationDropdownItems[0].value;
    //             this.selectedAttestationText = this.attestationDropdownItems[0].text;
    //         }
    //     }
    //     else if (name === "Risk Score By Department") {
    //         this.riskScoreArrLsts = query;
    //         this.riskScoreFilterItems = this.riskScoreArrLsts.map((t: any) => t.Risk).filter((value, index, self) => self.indexOf(value) === index);
    //         if (this.riskScoreFilterItems.length > 0) {
    //             var riskScore = [];
    //             for (let i = 0; i < this.riskScoreFilterItems.length; i++) {
    //                 riskScore.push({
    //                     text: this.riskScoreFilterItems[i],
    //                     value: this.indexRiskScore
    //                 })
    //                 this.indexRiskScore++;
    //             }
    //             this.riskScoreDropdownItems = riskScore;
    //             this.selectedRiskScoreValue = this.riskScoreDropdownItems[0].value;
    //             this.selectedRiskScoreText = this.riskScoreDropdownItems[0].text;
    //         }
    //     }
    // }
}


