import { Component, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChange } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SharedService } from "@services/shared/shared.service";
import { FalainaUtils } from "app/shared/utils";

@Component({
  selector: "falaina-date-picker",
  templateUrl: "./falainadatepicker.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FalainadatepickerComponent),
      multi: true
    }
  ]
  
})
export class FalainadatepickerComponent implements ControlValueAccessor, OnInit {

  @Input() datepickerId:any="datepicker"
@Input()
datepickerType : any = "classic";

datepickerAnimateCalendarNavigation : boolean =false;

@Input()
datepickerDisabled : boolean =false;

@Input()
datepickerReadOnly : boolean =false;

@Input()
datepickerReadOnlyInput : boolean =false;

@Input()
datepickerFocusedDate : any ;

@Input()
datepickerDisabledDates: any ;

@Input()
datepickerMin: any ;

@Input()
datepickerMax: any ;

@Input()
datepickerIncompleteDateValidation: boolean = false ;

@Input()
datepickerNavigation: boolean = true ;

@Input()
datepickerFormat: any = "dd/MM/yyyy";

@Input()
datepickerPlaceholder: string = "Type in Your Date Here...";

@Input()
datepickerFormatPlaceholder: any = "'formatPattern'";

datepickerActiveView: any = "month";

datepickerBottomView: any = "year";

datepickerTopView: any = "decade";

@Input()
datepickerWeekNumber: boolean = false;

@Input()
IsdisplayclearPopup :  boolean = false;

@Input()
datePickerClass: any;

    

@Output()
onDatechange: EventEmitter<any> = new EventEmitter();

public onDateValueChange(e : any)
{
 
  this.onDatechange.emit(e);
}

@Input()
IsdisplayClearPopup : boolean = true;

public clearDate(){
  this.value = null;

}

  constructor(private sharedService : SharedService) { }

  ngOnInit(): void {
    this.subSharedService()
    this.changeDateFormat()
  }
  changeDateFormat() {
    var formatValue=  FalainaUtils.getUserPreferenceDetail().dateZone
    if(formatValue &&  formatValue)
      this.datepickerFormat = this.getFormat(formatValue)
      else
      this.datepickerFormat ="dd/MM/yyyy";
    
  }
  subSharedService() {
    this.sharedService.changeDateTimeZone.subscribe(x=>{
      if(x.isDateFormatEmitted &&  x.dateFormat?.value)
      this.datepickerFormat = this.getFormat(x.dateFormat?.value)
      else
      this.datepickerFormat ="dd/MM/yyyy";
    })
  }
  getFormat(value: any): any {
     if(this.dateformat.filter(x=>x.value.includes(value)).length ==0)
     {
      return "dd/MM/yyyy"
     }

     return this.dateformat.filter(x=>x.value.includes(value))[0].text
  }
  ngOnChanges(change:SimpleChange)
  {
    if (change["datepickerMin"]?.currentValue) {
      this.datepickerMin = this.formatDate(change["datepickerMin"]?.currentValue) 
    }
    if (change["datepickerMax"]?.currentValue) {
      this.datepickerMax = this.formatDate(change["datepickerMax"]?.currentValue) 
    }
  }
  @Input('value') datepickerValue :string | Date= "" ;
  onChange: any = () => {};
  onTouched: any = () => {};
  get value() {
    return this.datepickerValue;
  }

  set value(val) {

  this.datepickerValue = val
    this.onChange(val);
    this.onTouched();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
  //this.value=  this.formatDate(value)

     this.value = this.formatDate(value);
    
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  formatDate(date) {   
    var a = typeof date;
  if((typeof date == 'object' || date.length >3) && date != null)
    {
 
          if(date.toString().includes('GMT') )
          {
         
          return new Date(date)
          }         
      var formatedDate = date.toString().replace(' ','T')     
      return new Date(formatedDate)
    }
    else
    {
      return null;
    }
  }

  dateformat = [
    //since it's date picker avoiding time here , using time only on date picker time
 
  { "value": "shortDate", "text": 'M/d/yy'},
  { "value": "d/M/yy, h:mm a", "text": 'd/M/yy'},
  { "value": "mediumDate", "text": 'MMM d, y'},
  { "value": "dd, MMM, y, h:mm:ss a", "text": 'd MMM, y'},
  { "value": "longDate", "text": 'MMMM d, y'},
  { "value": "d MMMM, y, h:mm:ss a z", "text": 'd MMMM, y'},

  // dateformat = [
  //   { "value": "short", "text": "short-Type1 ('M/d/yy, h:mm a')" },
  //   { "value": "d/M/yy, h:mm a", "text": "short-Type2 ('d/M/yy, h:mm a')" },
  //   { "value": "medium", "text": "medium-Type1 ('MMM d, y, h:mm:ss a')" },
  //   { "value": "dd, MMM, y, h:mm:ss a", "text": "medium-Type2 ('dd, MMM, y, h:mm:ss a')" },
  //   { "value": "long", "text": "long-Type1 ('MMMM d, y, h:mm:ss a z')" },
  //   { "value": "d MMMM, y, h:mm:ss a z", "text": "long-Type2 ('d MMMM, y, h:mm:ss a z')" },
  //   { "value": "shortDate", "text": "shortDate-Type1 ('M/d/yy')" },
  //   { "value": "dd/MM/yyyy", "text": "shortDate-Type2 ('dd/MM/yyyy')" },
  //   { "value": "mediumDate", "text": "mediumDate-Type1 ('MMM d, y')" },
  //   { "value": "dd, MMM, y", "text": "mediumDate-Type2 ('dd, MMM, y')" },
  //   { "value": "longDate", "text": "longDate-Type1 ('MMMM d, y')" },
  //   { "value": "d MMMM, y", "text": "longDate-Type2 ('d MMMM, y')" },
  // ];
];


  }
 

