export class ExportColumnHeaders {
    exportField: string;
    exportLocked?: boolean;
    exportCssClass?: string;
    exportTitle: string;
    exportWidth?: number;
    exportHasTemplate?: boolean;
    constructor(
        title: string,
        field: string,
        locked?: boolean,
        cssClass?: string,
        width?: number,
        hasTemplate?: boolean,
    ){
        this.exportTitle = title;
        this.exportField = field;
        this.exportLocked = locked;
        this.exportCssClass = cssClass;
        this.exportWidth = width;
        this.exportHasTemplate = hasTemplate;
    }
}

export class Export {
    exportData: any[];
    exportColumnHeaders: ExportColumnHeaders[];
    exportColumnHeaderNotToBeIncluded?: any[];
    constructor(
        data: any[],
        columnHeaders: ExportColumnHeaders[],
        columnHeaderNotToBeIncluded?: any[],
    ){
        this.exportColumnHeaderNotToBeIncluded = columnHeaderNotToBeIncluded;
        this.exportColumnHeaders = columnHeaders;
        this.exportData = data;
    }
}
