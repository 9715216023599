import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, Input, OnInit, SimpleChange, ElementRef, Output } from "@angular/core";
import { SharedService } from "@services/shared/shared.service";
import { UserService } from "@services/user.service";
import { authServiceDetail } from "app/core/authentication/auth.service";
import { FalainaUtils } from "app/shared/utils";

@Component({
  selector: "falaina-label",
  templateUrl: "./falainalabel.component.html"
})
export class FalainalabelComponent implements OnInit {

  @Input()
  labelText: string = "";
  @Input()
  labelId: any;
  @Input()
  labelClass: any = "k-step-label f_lBold_5";
  @Input()
  labelRequired: boolean = false;
  @Input()
  maskingPattern: string;
  @Input()
  maskingEnabled: boolean = false;

 
  @Input()
  labelFor: any;
  @Input()
  labelTranslate: any = true;
  @Input()
  labelReqclss: any;
  @Input() bold: boolean = true;
  @Input()
  maskingExceptionRoles: any;

  constructor(private activeUsrService: UserService, private sharedService: SharedService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    if (this.labelRequired == true)
      this.labelReqclss = "required";
  }
  ngOnChanges(change: SimpleChange) {
    if (change["labelText"]?.currentValue) {
      this.labelText = change["labelText"].currentValue;
      if (this.checkHypensInLabel(this.labelText.toString()) || this.labelText.toString().includes("GMT")) {
        if (!isNaN(Date.parse(this.labelText))) {
           var dateReg = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?(Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])?$/
        if(this.labelText.toString().match(dateReg) ||  this.formatDate(this.labelText).toString().includes('GMT')){
           this.labelText = FalainaUtils.ConvertToLocalTimeZone(this.labelText,this.datePipe)
          } 
            else {            
          this.labelText = this.labelText
        }
      }

      } else {
        this.labelText = this.labelText
      }
    }

    this.maskValue()
    this.setlabelRequired(change)

  }
  setlabelRequired(change) {
    if (change['labelRequired']?.currentValue) {
      this.labelReqclss = "required";
    }
    else {
      this.labelReqclss = ""
    }
  }

  maskValue() {
    var userinfoRoles = authServiceDetail.LoggedinUserInfo?.Roles
     this.labelText= FalainaUtils.applyMaskingByRole(this.labelText, this.maskingPattern,userinfoRoles,this.maskingExceptionRoles)
  }
  ngafterviewint() {

  }
  //#region dateTime
  formatDate(date) {
    var a = typeof date;
    if ((typeof date == 'object' || typeof date == 'number' || date.length > 3) && date != null) {
      if (date.toString().includes('GMT') || typeof date == 'number') {
        return new Date(date)

      }
      return new Date(date)
    }
  }

  checkHypensInLabel(labelText : string):boolean{
    if(labelText.includes("-") && !labelText.includes("http")){
      let noOfHypens : number = 0;
      for(let i=0;i<labelText.length;i++){
        if(labelText[i]=="-") noOfHypens = noOfHypens + 1; //Date.parse('password - 1') restrict case
      }
      if(noOfHypens==1) return false;
      else return true;
    }else{
      return false;
    }
  }

  //#endregion



}

