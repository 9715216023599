import { OnInit, Component, ViewChild, ElementRef, HostListener, EventEmitter, Output, Input } from "@angular/core";
import { Align } from "@progress/kendo-angular-popup";
import { FalainaLogoService } from "@services/FalainaLogoConfig/falainalogoconfig.service";
import { DomSanitizer } from "@angular/platform-browser";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { BadgeAlign } from "@progress/kendo-angular-indicators";
import { NavigationEnd, Router } from "@angular/router";
import { TreeItem } from "@progress/kendo-angular-treeview";
import { SharedService } from "@services/shared/shared.service";
import { AuthService, authServiceDetail } from "../authentication/auth.service";
import { FalainaAvatarSize } from "app/shared/customcontrols/falainaavatar/falainaavatarsize";
import { UserService } from "@services/user.service";
import { AcrService } from "app/modules/Request/acr/service/acr.service";
import { userDatePreference } from "@services/userdatepreferenece";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { timeZone } from "@services/timeZone";
import { DatePipe } from '@angular/common';
import { AppConfigService } from "@services/shared/app-config-service";
import { MasterConfigurationService } from "@services/master-configuration.service";
import { CheckoutType } from "@models/accessdetail/checkoutitems";
import { FalainaUtils } from "app/shared/utils";
import { FalainaLocalStorage } from "app/shared/utils/falainaLocalStorage";
import { forkJoin } from "rxjs";

@Component({
  selector: "appbar",
  templateUrl: "./appbar.component.html"
})

export class AppbarComponent implements OnInit {
  environment
  @Input() auth: boolean = false;

  cartdisabled: boolean = false;

  avatarLargeSize: FalainaAvatarSize = "large";

  avatarSmallSize: FalainaAvatarSize = "small";
  popupItems: any[] = [
    { text: 'Document', icon: 'icon-ic_fluent_document_one_page_24_regular f_font_size_18 ' },
    { text: 'Cart', icon: 'icon-ic_fluent_cart_24_regular f_font_size_20 ' },
    { text: 'Image', icon: 'icon-ic_fluent_image_add_24_regular f_font_size_18 ' },
    { text: 'Alert', icon: 'icon-ic_fluent_alert_28_regular f_font_size_18 ' },
    { text: 'Settings', icon: 'icon-ic_fluent_settings_28_regular f_font_size_18 ' },
    { text: 'Question', icon: 'icon-ic_fluent_question_28_regular f_font_size_18 ' },
  ];
  public userData: any; //for showing cart count for the user

  public cartcnt;
  hasHeader: boolean;
  // datezone: any;
  // timezonevalue: any;
  showwidget: boolean =true;
  userPrefId: any;
  appLogoTxt: string='';
  public get cartCount() { return this.cartcnt }
  public set cartCount(val: any) {
    this.cartcnt = val;
    (this.cartcnt == 0) ? this.cartdisabled = true : this.cartdisabled = false;
  }
  displayDashboardIcon: boolean = false;
  cultureName = "en-US"
  private currentDate: any = new Date();
  languages = [{ "value": "", "text": "" }];
  dateformat = [
  { "value": "short", "text": "short-Type1 ('M/d/yy, h:mm a')" },
  { "value": "d/M/yy, h:mm a", "text": "short-Type2 ('d/M/yy, h:mm a')" },
  { "value": "medium", "text": "medium-Type1 ('MMM d, y, h:mm a')" },
  { "value": "dd, MMM, y, h:mm:ss a", "text": "medium-Type2 ('dd MMM, y, h:mm a')" },
  { "value": "long", "text": "long-Type1 ('MMMM d, y, h:mm:ss a z')" },
  { "value": "d MMMM, y, h:mm:ss a z", "text": "long-Type2 ('d MMMM, y, h:mm:ss a z')" },
  { "value": "shortDate", "text": "shortDate-Type1 ('M/d/yy')" },
  { "value": "dd/MM/yyyy", "text": "shortDate-Type2 ('dd/MM/yyyy')" },
  { "value": "mediumDate", "text": "mediumDate-Type1 ('MMM d, y')" },
  { "value": "dd, MMM, y", "text": "mediumDate-Type2 ('dd, MMM, y')" },
  { "value": "longDate", "text": "longDate-Type1 ('MMMM d, y')" },
  { "value": "d MMMM, y", "text": "longDate-Type2 ('d MMMM, y')" },
];
timezone:timeZone[] = timeZone;
  selectedLanguageItem: any = { "value": "en", "text": "English" };
  selecteddateItem: any = { "value": "short", "text": "short" };
  
  //selectedtimeZone: any = { "value":"UTC+08:00", "text":"Singapore" };
 
  panelTitle: any = "Display Language (" + this.selectedLanguageItem.text + ")";
  selectedLangCode: string = "en";
  public anchorAlign: Align = { horizontal: "right", vertical: "bottom" };
  public popupAlign: Align = { horizontal: "right", vertical: "top" };
  public toggleText = "Show";
  public show = false;
  @ViewChild("anchor", { read: ElementRef, static: true }) public anchor: any;
  @ViewChild("popup", { read: ElementRef, static: true }) public popup: any;
  public badgeAlign: BadgeAlign = { vertical: "top", horizontal: "end" };
  public cartItemBadgeAlign: BadgeAlign = { vertical: "bottom", horizontal: "end" };
  public showMenuPopup = false;
  showProfilePopup = false;
  hasGlobalSearch: boolean;
  hasShortCutIcon: boolean;
  IACMConfigKey: string = 'DefaultTimezone';
  clientName: string;
  ProductVersion: string;
  ProductActive: string;
  public loadReportMenus(): void {
    const servicemenu = document.querySelector<HTMLElement>(".servicesmenu")!;
    servicemenu.style.display = "none";
    const reportmenu = document.querySelector<HTMLElement>(".reportsmenu")!;
    reportmenu.style.display = "block";
    const popup = document.querySelector<HTMLElement>(".f_popup_posn_tl")!;
    popup.style.display = "none";
  }
  public loadServiceMenus(): void {
    const servicemenu = document.querySelector<HTMLElement>(".servicesmenu")!;
    servicemenu.style.display = "block";
    const reportmenu = document.querySelector<HTMLElement>(".reportsmenu")!;
    reportmenu.style.display = "none";
    const popup = document.querySelector<HTMLElement>(".f_popup_posn_tl")!;
    popup.style.display = "none";
  }

  openSearch() {
    document.querySelector<HTMLElement>("#mySearchpanel").style.width = "98%";
    document.querySelector<HTMLElement>("#mySearchpanel").style.display = "block";

  }
  closeSearch() {
    document.querySelector<HTMLElement>("#mySearchpanel").style.width = "0";
    document.querySelector<HTMLElement>("#mySearchpanel").style.display = "none";
  }
  public onToggle(): void {
    this.show = !this.show;
  }
  public showMenuInPopup(): void {
    this.showMenuPopup = !this.showMenuPopup;
  }
  public closeMenuPopup(evt): void {

    // sessionStorage.clear();
    this.showMenuPopup = false;
    this.showProfilePopup = false;
    if (evt.target.text == "Sign out") {
      localStorage.clear();
      sessionStorage.removeItem("isReadSelfRegisterMsg");
      sessionStorage.removeItem("loginTime");
      sessionStorage.removeItem("isMFARegistered");
      sessionStorage.removeItem("isMFAHomeVerified");


      this.oidcSecurityService.logoffAndRevokeTokens()
        .subscribe((result) => {
          // console.log(result)
        });
    }
  }
  // private elm: any;
  // onSelect(e, name) {

  //   this.elm = e.target;

  //   console.log(this.elm, 'this.elm');


  // }
  userProfileData: any;
  public showUserProfilePopup(evt: any): void {

    this.showProfilePopup = !this.showProfilePopup;
  }

  avatarImage: any;
  latestDate: any;
  constructor(
    private configServ: AppConfigService,
    private route: Router, private falainaLogoService: FalainaLogoService, private sharedService: SharedService,
    private sanitizer: DomSanitizer, private acrService: AcrService, private userService: UserService, private authService: AuthService,
    public oidcSecurityService: OidcSecurityService, private datePipe: DatePipe, private configmaster: MasterConfigurationService) {

    this.configServ.loadConfigurations().subscribe(x => {
      this.environment = x.DevInProgress
    })
    route.events.forEach((event) => {

      if (event instanceof NavigationEnd) {

        this.themeConfig()
        this.getUserPreference()
      }
    });


    let userId = authServiceDetail.LoggedinUserId

    let subscription = this.sharedService.profileImageEmit.subscribe((data: any) => {
      if (data) {
        this.avatarImage = data
      }

    });
    this.sharedService.isHomeEmittedEvent.subscribe((data: any) => {

      if (data == "true") {
        this.showwidget = true;
      } else {
        this.showwidget = false;
      }

    })
    this.userService.getById(Number(userId)).subscribe((result: any) => {
      if (result.ImageUrl) {
        this.avatarImage = result.ImageUrl
      }

      this.userProfileData = result;
    }, error => console.error(error));


    //this.latestDate = "Last Logon " + this.currentDate.toLocaleString();
    if (window.location.hash.slice(-1).includes("/") ||
      window.location.hash.includes("home")) {
      this.displayDashboardIcon = true;
    }
    this.getLogoData();
  }
  //need to configure this from configuration list/JSON file 
  themeConfig() {
    // if (this.route.url.includes('pam/pasrdp') || this.route.url.includes('/register') || this.route.url.includes('pam/pamtoggle')) {
    //   this.hasGlobalSearch = true;
    //   this.hasHeader = true;
    //   this.hasShortCutIcon = true;
    // }
    if (this.route.url.includes('pam/pasrdp')) {
      this.hasGlobalSearch = false;
      this.hasHeader = false;
      this.hasShortCutIcon = false;

    }
    else if (this.route.url.includes('pam/usersession/passession')) {
      this.hasGlobalSearch = false;
      this.hasHeader = true;
      this.hasShortCutIcon = false;

    }
    else if (this.route.url.includes('/register')) {
      this.hasGlobalSearch = false;
      this.hasShortCutIcon = false;
      this.hasHeader = true;

    }
    else {
      this.hasGlobalSearch = true;
      this.hasShortCutIcon = true;
      this.hasHeader = true;

    }

  }

  getLogoData()
  {
    let data = JSON.parse(FalainaLocalStorage.getItem("logoData"))
    if(data)
    {
      this.setLogoData(data)
      return;
    }
    const arr = [this.getLogoImageData(),this.getLogoname()];
    let multiCall = forkJoin(arr);
  
    multiCall.subscribe(data=>{      
        
        var x={
          logoName:data[1],
          imageData:data[0],
         
        }
        let jsonData=JSON.stringify(x);
        FalainaLocalStorage.setItem("logoData",jsonData)
        this.setLogoData(x)
    })
  }
  setLogoData(data: {logoName:any,imageData:any}) {
    if (data?.logoName) {
     let appLogoTxt = data?.logoName["ConfigValue"];
      if (appLogoTxt) {
        if (typeof(appLogoTxt)==  'string') {
          this.hasLogoTxt = true;
          this.appLogoTxt=appLogoTxt;
        }
      }
      else {
        this.hasLogoTxt = false;
      }
    }
    if (data?.imageData) {            
      let objectURL = "data:image/png;base64," + data?.imageData["ConfigValue"];
      this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    
    }
  }

  image: any;
  getLogoImageData(): any {
  return  this.falainaLogoService.getImageData()     
       
  }

  
  hasLogoTxt: boolean = false;

  getLogoname(): any {
   
   return this.falainaLogoService.getLogoValue()

  }



  showWidgetPopup() {
    this.sharedService.UserDashboardPreferenceEmitted(true);
    document.getElementById("dashboardWidgetPopup")?.classList.remove("display_none");
  }
  public ngOnInit(): void {
    this.getGlobalLanguage();
    this.themeConfig()

    this.getInitialCartCount();
    this.getCartCount();
    this.getUserPreference();
    this.GetProductInfo();
  }

  getGlobalLanguage() {
  
    this.userService.getGlobalLanguages().subscribe(result => {

      this.languages = [];
      if (result?.length) {
        result.forEach(element => {
          this.languages.push({value:element?.Code,text:element?.Name});
        });
      }
    });
  }
  getUserPreference() {
    this.userService.GetPreferenceByUserId(authServiceDetail.LoggedinUserId).subscribe(result => {
      
      if(result !=null){
        this.userPrefId= result.UserId ==0 ? 0:result.Id

          this.selecteddateItem={ "value": result['DateFormat'], "text": result['DateFormat'] };
         /// this.selectedtimeZone={ "value": result['CultureName'], "text": result['CultureName'] };       
          let dataItem =  this.languages.filter((x : any) => { return x.value == result["Language"]});
        this.selectedLanguageItem = dataItem[0];
        if(this.selectedLanguageItem == undefined)
        this.changeLanguage.emit('en');
        else
        this.changeLanguage.emit(this.selectedLanguageItem?.value);
        this.panelTitle = "Display Language (" + dataItem[0]?.text + ")";
        this.setLocalStorageForDateFormat()
        this.latestDate = FalainaUtils.ConvertToLocalTimeZone(result?.LastLogon, this.datePipe)    
      } else {

        this.configmaster.GetConfigByKey(this.IACMConfigKey).subscribe(x => {
          if (x != null) {
            this.selecteddateItem={ "value": "short", "text":"short" };
           // this.selectedtimeZone={ "value": x.ConfigValue, "text": x.ConfigValue };              
            this.selectedLanguageItem = { "value": "en", "text": "English"};
              
          }
          else {
               this.selectedLanguageItem = { "value": "en", "text": "English"};              
               this.selecteddateItem={ "value": "short", "text":"short" };
             //  this.selectedtimeZone={ "value": "UTC+08:00", "text": "UTC+08:00" }; 
          }
        });
        this.setLocalStorageForDateFormat()
        this.latestDate =  FalainaUtils.ConvertToLocalTimeZone(new Date(), this.datePipe)
      }
     
    })
  }

  setLocalStorageForDateFormat() {
    
   // FalainaLocalStorage.setItem('timezone', this.selectedtimeZone.value)
   if(this.selecteddateItem?.value != null)
      FalainaLocalStorage.setItem('datezone', this.selecteddateItem?.value)
   if(this.selectedLanguageItem?.value != null)
    FalainaLocalStorage.setItem('language', this.selectedLanguageItem?.value)
  }
  // @HostListener("document:click", ["$event"])
  // public documentClick(event: any): void {
  //   if (!this.contains(event.target)) {
  //     this.toggle(false);
  //   }
  // }
  public toggle(show?: boolean): void {
    this.show = show !== undefined ? show : !this.show;
    this.toggleText = this.show ? "Hide" : "Show";
  }

  private contains(target: any): boolean {
    return this.anchor.nativeElement.contains(target)
      ||
      (this.popup ? this.popup.nativeElement.contains(target)
        : false);
  }

  showSettingsPopup: boolean = false;
  showSettingPopup(evt: any): void {
    this.showSettingsPopup = true;
  }
  closepopup(): void {
    this.showSettingsPopup = false;
  }
  public handleFilter(val: any) {
    this.languages = this.languages.filter((s) => s.value.toLowerCase().indexOf(val.toLowerCase()) !== -1);
  }

  selectedItemChange(e: any) {

  }

  @Output()
  changeLanguage: EventEmitter<any> = new EventEmitter();

  closeBtnClick(e) {
    this.showProfilePopup = false;
    this.showMenuPopup = false;
    this.showSettingsPopup = false;
    this.show = false;
  }

  //autocompleteValue: any = "en";
  // public handletimezoneFilter(dataItem: any): void {
  //   this.selectedtimeZone = dataItem;
  //   let data = {
  //     timeZone: this.selectedtimeZone,
  //     dateFormat: this.selecteddateItem,
  //     isTimeZoneEmitted:true,
  //     isDateFormatEmitted:false
  //   }
  //   this.sharedService.dateTimeZoneEmitFn(data);
    
  //   this.saveUserPreference()
  // }
  public handledateFilter(dataItem: any): void {
    this.selecteddateItem = dataItem;
    let data = {
    //  timeZone: this.selectedtimeZone,
      dateFormat: this.selecteddateItem,
      isDateFormatEmitted:true,
      isTimeZoneEmitted:false
    }
    this.sharedService.dateTimeZoneEmitFn(data);    
    this.saveUserPreference()

  }
  saveUserPreference() {
    let data: userDatePreference;
    let userId = authServiceDetail.LoggedinUserId;
    let datezone=this.selecteddateItem?.value
    let lang = this.selectedLanguageItem?.value
   // let timezonevalue=this.selectedtimeZone.value
    data= {Id:this.userPrefId,UserId:Number(userId), CultureName:this.cultureName,DateFormat:datezone,TimeFormat:datezone,LongDateFormat:datezone,
      Language : lang ,LongTimeFormat:datezone,CreatedBy:userId,LastModifiedBy:"",LastModifiedDate:''}

    this.userService.saveUserdateprefernce(data).subscribe(result => {
      this.getUserPreference();
    })
  }
  public handleLanguageFilter(dataItem: any): void {
   
    this.selectedLanguageItem = dataItem;
    this.panelTitle = "Display Language (" + dataItem?.text + ")";
    this.changeLanguage.emit(dataItem.value);
    this.saveLanguage(dataItem.value);
  }

  saveLanguage(e: any) {
    let data: userDatePreference;
    let userId = authServiceDetail.LoggedinUserId;
    let datezone=this.selecteddateItem.value
    //let timezonevalue=this.selectedtimeZone.value
    data= {Id:this.userPrefId,UserId:Number(userId), CultureName:this.cultureName,DateFormat:datezone,TimeFormat:datezone,LongDateFormat:datezone,
      Language : e,LongTimeFormat:datezone,CreatedBy:userId,LastModifiedBy:"",LastModifiedDate:''}

    this.userService.saveUserdateprefernce(data).subscribe(result => {
      this.getUserPreference();
    }, error => console.error(error))

  }


  onGlobalSearchChange(evt) {

  }
  showCustomUsage() {
    this.route.navigate(['/customcontrols']);
  }

  public getCartCount() {
    this.sharedService.cartcountEmittedEvent.subscribe(x => {
      this.cartCount = x;
      if (this.cartCount == 0) {
        this.cartdisabled = true;
      }
    })
  }

  public getInitialCartCount() {
    this.authService.userData.subscribe(res => {
      this.userData = res;
      this.acrService.getcartcountitemscount(+this.userData.sub, CheckoutType.Cart).subscribe(x => { //replace with user Id
        this.cartCount = x;
      })
    })

  }
  notifyButtonClick($event) {
    this.route.navigate(['/request/accessrequest/cart']);
  }
  onSelect(e) {
    if (e.item.text === "Document") {
      this.showCustomUsage();
    } else if (e.item.text === "Cart") {
      this.notifyButtonClick(e);
    } else if (e.item.text === "Image") {
      this.showWidgetPopup()
    } else if (e.item.text === "Alert") {

    } else if (e.item.text === "Settings") {
      this.showSettingPopup(e)

    } else if (e.item.text === "Question") {
      this.closeMenuPopup(e)
    }


  }
  GetProductInfo() {
    this.configmaster.GetProductInfo().subscribe(x => {
      this.clientName = x.ClientName;
      this.ProductVersion = x.Version;
      this.ProductActive = x.Activated;
    });
  }
}
