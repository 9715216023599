import { Pipe, PipeTransform } from "@angular/core";
import { themes } from "@models/theme/falainatheme";

@Pipe({
    name: 'randomtheme'
  })
export class Randomtheme  implements PipeTransform{

   transform(dummy,p1?,): any{
     if(p1)
     {      
       p1 =p1.toLowerCase();
       let a= p1.charCodeAt(0) -97 ==0?1:p1.charCodeAt(0) -97
       let b= isNaN(p1.charCodeAt(1) -97)==true?1:p1.charCodeAt(1) -97
       var random =0;
       if((a+b) % 2 == 0)
       {
       random= (a+b)*234567
       }
       else
       {
        random= (a+b)*98765
       }
       random= Math.abs(random);
       random = +('0.'+random.toString())
       let index =Math.floor(random * themes.length)
      return themes[index]
     }
    return themes[Math.floor(Math.random() * themes.length)];
    }

  constructor( ) {

}
    
}