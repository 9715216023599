import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FalainafileuploadComponent} from "./falainafileupload.component"
import { UploadsModule } from "@progress/kendo-angular-upload";
import { FormsModule } from "@angular/forms";
@NgModule({
  declarations: [FalainafileuploadComponent],
  imports: [
    CommonModule,
    UploadsModule,
    FormsModule
  ],
  exports: [
    FalainafileuploadComponent
  ]
})
export class FalainafileuploadModule { }
