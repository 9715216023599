import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropDownListComponent } from "@progress/kendo-angular-dropdowns";
import { FlowsService } from "angular-auth-oidc-client/lib/flows/flows.service";
@Component({
  selector: "falaina-autocomplete",
  templateUrl: "./falainaautocomplete.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FalainaautocompleteComponent),
      multi: true
    }
  ]
})
export class FalainaautocompleteComponent implements ControlValueAccessor, OnInit {
  autocompleteStyle: any;

  noDataFoundText: any = "Please Enter * or text to search";
  formControlName: any;
  @Input()
  autocompleteClass: any;
  @Input()
  autocompleteData: any[];
  listItemsMultiAutocomplete: any[] = [];
  @Input()
  readonly = true

  listItemsSingleAutocomplete: any[] = [];
  @Input()
  autocompletePlaceholder: string = "Enter * to view full list";
  @Input()
  autocompleteValueField: string = "value";
  @Input()
  autocompleteFilterable: boolean = true;
  autocompleteSuggest: boolean = true;
  @Input()
  autocompleteHeader: boolean = false;
  @Input()
  autocompleteHeaderText: string = "Header text";
  @Input()
  autocompleteFooter: boolean = false;
  @Input()
  autocompleteFooterText: string = "Footer";
  @Input()
  autocompleteTextField: string = "text";
  @Input()
  autocompleteLabelText: string = "";
  @Input()
  autocompleteMulti: boolean = false;


  @Input() isautocompleteDisabled: boolean = false;
  @Input()  isAutoCompDataServiceEnabled: boolean = false;

  @Input()
  isValuePrimitive: boolean = false;
  // @Input()
  // autocompleteMultivalue: string = "";
  @Input()
  isClearbuttonEnabled: boolean = false;
  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {


  }

  isFiltered: boolean = false;
  ngOnChanges(changes: SimpleChanges): void {


    if (changes["autocompleteList"]) {

      this.autocompleteSingleData = changes["autocompleteList"]?.currentValue;
      this.removeData(this.autocompleteSingleData)
      this.listItemsSingleAutocomplete = this.autocompleteSingleData;
      this.limitSingleData(this.listItemsSingleAutocomplete);
    }
    if (changes['autocompleteVal']?.currentValue) {    
      this.limitSingleData(this.listItemsSingleAutocomplete);
    }

    if (changes["autocompleteData"]) {
      this.autocompleteData = changes["autocompleteData"]?.currentValue;
      this.removeData(this.autocompleteData)
      this.listItemsMultiAutocomplete = this.autocompleteData;
    }

    // this.cd.detectChanges();
  }
  limitSingleData(data:any[]=[],isSearchNull=true) {
    let value = this.autocompleteVal
    let takeCount = 100;
    let result=data;
   
    if(isSearchNull && value && this.isValuePrimitive)
    {
      let filterValue = data.filter(x=>x[this.autocompleteTextField] == value || x[this.autocompleteValueField] == value)
      if(filterValue.length>0)
      {
        result =[];
      filterValue.forEach(x=>{result.push(x) })   
      }
  
    }
    else
    {
      result=data.slice(0,takeCount)
    }
   
    this.autocompleteSingleData=result;
  }

  public handleFilter(value: any) {
    if (value == "*") {
      this.autocompleteData = this.listItemsMultiAutocomplete;  
    } else {
      this.autocompleteData = this.listItemsMultiAutocomplete.filter((s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }
  }

  // @Output()
  // filterChange: EventEmitter<any> = new EventEmitter();
  // autocompleteSelected(dataItem){
  //   this.filterChange.emit(dataItem)
  // }

  @Output()
  singleAutoSelChange: EventEmitter<any> = new EventEmitter();
  selectedItemChange(val: any) {

    this.singleAutoSelChange.emit(val);
  }

  @Output()
  singleAutoFilterChange: EventEmitter<any> = new EventEmitter();
  searchItemChange(val: any) {
      this.isFiltered = true;
      let listIteam = this.listItemsSingleAutocomplete
      //All data handled on client side-28072022 
    if ( listIteam?.length > 0) {
      this.autocompleteSingleData = [];
      if (val == "*" || val=='') {
       let isSearchNull  = val =='*' ? false :true;
         this.limitSingleData(listIteam,isSearchNull);
      }
      else {
         this.autocompleteSingleData=listIteam.filter((x: any) => { return x[this.autocompleteTextField].toLowerCase().indexOf(val.toLowerCase()) !== -1 });
        if (this.autocompleteSingleData.length == 0) {
          this.noDataFoundText = "No Data Found";
        }
        else
        {
          this.limitSingleData(this.autocompleteSingleData,false);
        }
      }

    }
    else {

      this.singleAutoFilterChange.emit('*');
      if (this.autocompleteSingleData.length == 0) {
        this.noDataFoundText = "No Data Found";
      }
    }


    //this.cd.detectChanges();
    // this.autocompleteSingleData =[{ text: "Baseball", value: 1 },{ text: "BasketBall", value: 2 }];

  }

  @Input('autocompleteSingleData') autocompleteList: any;


  get autocompleteSingleData() {

    return this.autocompleteList;

  }

  @Input() sortData: string = 'Ascending';
  set autocompleteSingleData(val) {

    if (val != undefined) {
      if (this.sortData == 'Ascending') {
        val.sort(function (a, b) {
          if (a.text?.toLowerCase() < b.text?.toLowerCase())
            return -1;
          if (a.text?.toLowerCase() > b.text?.toLowerCase())
            return 1;
          return 0;
        })
      }
      else {
        val.sort(function (a, b) {
          if (b.text.toLowerCase() < a.text.toLowerCase())
            return -1;
          if (b.text.toLowerCase() > a.text.toLowerCase())
            return 1;
          return 0;
        })
      }
    }

   
     this.autocompleteList = val;

  }

  @Input('autocompleteValue') autocompleteVal: any;
  onChange: any = () => { };
  onTouched: any = () => { };
  get autocompleteValue() {

    return this.autocompleteVal;
  }


  set autocompleteValue(val) {
    // setTimeout(() => {
    this.autocompleteVal = val;
    this.onChange(val);
    this.onTouched();
    // }, 1000);

  }

  @Input('autocompleteMultivalue') autocompleteMultiVal;

  get autocompleteMultivalue() {

    return this.autocompleteMultiVal;
  }


  set autocompleteMultivalue(val) {

    this.autocompleteMultiVal = val;
     this.onChange(val);
     this.onTouched();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {

   
      this.autocompleteValue = value;
    
  }

  registerOnTouched(fn) {

    this.onTouched = fn;
  }

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter();
  public onValueChange(value: any) {

    // this.onChange(value);
    // this.onTouched();
    this.valueChange.emit(value);
  }
  @ViewChild("dropdownlist") public dropdownlist: DropDownListComponent;
  public OnCls(event: any) {
    event.preventDefault();
    setTimeout(() => {
      if (
        !this.dropdownlist.wrapper.nativeElement.contains(
          document.activeElement
        )
      ) {
        //document.getElementsByClassName('k-select').
        this.dropdownlist.toggle(false);
      }

    });
  }
  clearSelection(event: any) {
    this.autocompleteValue = '';
    this.singleAutoSelChange.emit(this.autocompleteValue);
    this.cd.detectChanges();
  }
  removeData(arryData) {
    if(!arryData)
    return;
    let a = arryData as Array<any>
    let index = a?.findIndex(x => { return x[this.autocompleteValueField] === '$type' })
    if (index != -1)
      a.splice(index, 1)

    let index1 = a?.findIndex(x => { return x[this.autocompleteTextField] === '$type' })
    if (index1 != -1)
      a.splice(index1, 1)
  }
}

