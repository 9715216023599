import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { AppConfigService } from "./app-config-service";
import { AppConfig } from "@models/config/appconfig";


const headers = new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');

@Injectable({
  providedIn: "root"
})

export class customHttpclient {
  myApiUrl: string 
  myReportUrl: string 
  myServiceUrl: string 

  mymfaUrl : string 
  private _clientId: string;
  private _clientSecreat: string;
  constructor(private http: HttpClient,private config : AppConfigService) {
  var a:Observable<AppConfig>= this.config.loadConfigurations();
   a.subscribe(x=>{
    this.myApiUrl =x.baseUrl
    this.myReportUrl=x.reportUrl
    this.myServiceUrl=x.domainaServiceUrl
    this.mymfaUrl=x.MFAAPI
    this._clientId=x.APIClientId
    this._clientSecreat=x.APIClientSecret
   })

  }

  //#region domain api
  public getdomainapi(controlername: string, actioname: string, params: HttpParams) {
    let routepath = controlername;
    if (actioname !== undefined && actioname !== '') {
      routepath = routepath + '/' + actioname;
    }
    return this.http.get<any>(this.myApiUrl + routepath,
      { params, 'headers': headers })
      .pipe(map(res => res),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getdomainservice(controlername: string, actioname: string, params: HttpParams) {
    let routepath = controlername;
    if (actioname !== undefined && actioname !== '') {
      routepath = routepath + '/' + actioname;
    }
    return this.http.get<any>(this.myServiceUrl + routepath,
      { params, 'headers': headers })
      .pipe(map(res => res),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  
  public postdomainservice(controlername: string, actioname: string, body: any, params: HttpParams,) {

    let routepath = controlername;
    if (actioname !== undefined && actioname !== '') {
      routepath = routepath + '/' + actioname;
    }

    return this.http.post<any>(this.myServiceUrl + routepath,
      body,
      { params, 'headers': headers })
      .pipe(map(res => res),
        catchError(err => {
          return throwError(err);
        })
      );
  }


  public postdomainapi(controlername: string, actioname: string, body: any, params: HttpParams,) {

    let routepath = controlername;
    if (actioname !== undefined && actioname !== '') {
      routepath = routepath + '/' + actioname;
    }

    return this.http.post<any>(this.myApiUrl + routepath,
      body,
      { params, 'headers': headers })
      .pipe(map(res => res),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public deletedomainapi(controlername: string, actioname: string, body: any, params: HttpParams,) {

    let routepath = controlername;
    if (actioname !== undefined && actioname !== '') {
      routepath = routepath + '/' + actioname;
    }
    return this.http.delete<any>(this.myApiUrl + routepath,
      { params, 'headers': headers })
      .pipe(map(res => res),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  //#endregion

  //#region report api

  public getreportapi(controlername: string, actioname: string, params: HttpParams) {

    let routepath = controlername;
    if (actioname !== undefined && actioname !== '') {
      routepath = routepath + '/' + actioname;
    }

    return this.http.get<any>(this.myReportUrl + routepath,
      { params, 'headers': headers })
      .pipe(map(res => res),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public postreportapi(controlername: string, actioname: string, body: any, params: HttpParams,) {

    let routepath = controlername;
    if (actioname !== undefined && actioname !== '') {
      routepath = routepath + '/' + actioname;
    }

    return this.http.post<any>(this.myReportUrl + routepath,
      body,
      { params, 'headers': headers })
      .pipe(map(res => res),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  //#endregion


  //#region  mfa api 


  public getmfaapi(controlername: string, actioname: string, params: HttpParams) {

    const headers= new HttpHeaders()
    .set('ClientId', this._clientId)
    .set('ClientSecret', this._clientSecreat)

    let routepath = controlername;
    if (actioname !== undefined && actioname !== '') {
      routepath = routepath + '/' + actioname;
    }

    return this.http.get<any>(this.mymfaUrl + routepath,
      { params, 'headers': headers })
      .pipe(map(res => res),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public postmfaapi(controlername: string, actioname: string, body: any, params: HttpParams) {

    const headers= new HttpHeaders()
    .set('ClientId', this._clientId)
    .set('ClientSecret', this._clientSecreat)
    .set('Content-Type','application/x-www-form-urlencoded')

    let routepath = controlername;
    if (actioname !== undefined && actioname !== '') {
      routepath = routepath + '/' + actioname;
    }

    return this.http.post<any>(this.mymfaUrl + routepath,
      body,
      { params, 'headers': headers })
      .pipe(map(res => res),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  //#endregion
}
