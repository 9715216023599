import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { authServiceDetail } from 'app/core/authentication/auth.service';
import { FalainaSessionStorage } from 'app/shared/utils/falainaSessionStorage';
import { MFASharedService } from '../mfashared.service';

@Component({
  selector: 'falaina-register-prerequisite',
  templateUrl: './register-prerequisite.component.html',
  styleUrls: ['./register-prerequisite.component.css']
})
export class RegisterPrerequisiteComponent implements OnInit {
  showPopup : boolean = false;
  popupHeader: string;
  config: any;
 
  constructor(private mfaSharedService:MFASharedService , private router: Router) {
    this.subMfaSharedService();
   }

  ngOnInit(): void {
  }
  closebtnclick(e:any)
  {
  this.showPopup = false;
  }
  cancel()
  {
    this.closebtnclick(true);
  }
  subMfaSharedService()
  {
    this.mfaSharedService.mfaConfigEvent.subscribe(x=>{
        if(x.mandateSelfPWD && x.validateSelfPWD)
        x.validateSelfPWD=false
        this.config=x;
     let isread= FalainaSessionStorage.getItem("isReadSelfRegisterMsg");
      if(x?.validateSelfPWD && x?.url.includes("/home") && isread == null)
      {  
        let isRegMFA= FalainaSessionStorage.getItem("isMFARegistered");
       
        if(isRegMFA ==null || isRegMFA == "false" )
        {
          this.isUserMFAPwdReg(x?.url);
        }                     
      }
      else if(x?.mandateSelfPWD && !x.url.includes("security/mysecurityfactor"))
      {
        let isRegMFA= FalainaSessionStorage.getItem("isMFARegistered");
       
        if(isRegMFA ==null || isRegMFA == "false" )
        {
          this.isUserMFAPwdReg(x?.url);
        }
       }
    })
  }

  confirmValidateSelfMsg()
  {
      FalainaSessionStorage.setItem("SelfPWDPopUpVisible", "false");
      this.showPopup=false;
      FalainaSessionStorage.setItem("isReadSelfRegisterMsg","true");
      
  }
  register()
  {
    FalainaSessionStorage.setItem("SelfPWDPopUpVisible", "false");
    this.showPopup=false;
    this.router.navigate(['security/mysecurityfactor']);
    
    
  }
  //check user registered MFA 
  isUserMFAPwdReg(url)
  {
    this.mfaSharedService.isUserRegisteredMFA(authServiceDetail.LoggedinUserId).subscribe(res=>{  
         
      if(!res && this.config?.validateSelfPWD)
      {
        FalainaSessionStorage.setItem("SelfPWDPopUpVisible", "true");
        this.showPopup=true;
        this.popupHeader="ValidateSelfPWD popup header" 
      }
      else if(!res)
      {
        FalainaSessionStorage.setItem("SelfPWDPopUpVisible", "true");
        this.router.navigate(["/home"]);
        this.showPopup=true;
        this.popupHeader="MandateSelfPWD popup header" //translate
      }  
      else if(res){        
        
        FalainaSessionStorage.setItem("isMFARegistered",res.toString());
      }     
    })
  }
}
