import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { FalainanotificationComponent } from './falainanotification.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ButtonsModule } from '@progress/kendo-angular-buttons';



@NgModule({
  declarations: [FalainanotificationComponent],
  imports: [
    CommonModule,IndicatorsModule,LayoutModule,ButtonsModule
  ],
  exports:[FalainanotificationComponent]
})
export class FalainanotificationModule { }
