import { Component, ComponentFactoryResolver, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { SharedService } from "@services/shared/shared.service";
//import * as $ from 'jquery'
declare var $: any;
var  m_pos;
@Component({
  selector: "falaina-popup",
  templateUrl: "./falainapopup.component.html"
})

export class FalainapopupComponent implements OnInit {
  bordersize=10
  
  @Input()
  popupMenu: boolean = false;
  @Input()
  userAccessElements: Array<{ icon?: any, text: any, permissionId?: any }> = [
    {
      icon: "ms-Icon ms-Icon--EntryView f_forecolor_black f_font_size_18 f_margin_top_5 ",
      permissionId: 2,
      text: "Detail"
    },
    {
      icon: "ms-Icon ms-Icon--ExcelDocument f_forecolor_black f_font_size_18 f_margin_top_5 ",
      permissionId: 12,
      text: "Export"
    }
  ];
  @Input() popupId: string = "popupId"

  @Input()
  isTranslateEnabled : boolean = true;
  showFullScreen: boolean = true;
  popupClass: string = "";
  @Input()
  popupShow: boolean = false;
  @Input()
  enableChildPopup: boolean = false;
  @Input()
  @Input()
  popupWidth: any = "width: calc(100vw - 1130px);";
  @Input()
  popupShowwaffle: boolean = true;
  @Input()
  showFullWidth: boolean = false;
  @Input()
  popupShowrefresh: boolean = true;
  @Input()
  popupShowclose: boolean = true;
  @Input()
  popupHeadertext: string = "Popup header";
  @Input()
  popupShowHeader: boolean = true;
  @Input()
  popupShowfooter: boolean = false;
  @Input()
  popupShowStepper: boolean = false;
  @Input()
  popupShowCustomfooter: boolean = false;
  @Input()
  popupSavebtntext: string = "Save";
  @Input()
  popupCancelbtntext: string = "Cancel";
  @Input() popupContentcomponent: any;
  @ViewChild("dynamicComponent", { read: ViewContainerRef }) dynamicComponent: any;
  @Input()
  popupShowshadow: boolean = true;

  @Input()
  enableDragResize : boolean = true;
  @ContentChild("popupcontent") popupcontentRef: TemplateRef<any>;
  @ContentChild("popuptopsection") popuptopsectionRef: TemplateRef<any>;
  @ContentChild("popupcustomfootersection") popupcustomfootersectionRef: TemplateRef<any>;

  constructor(private componentResolver: ComponentFactoryResolver, private el: ElementRef, private sharedService: SharedService) {

  }

  ngOnInit(): void {

    if (this.popupMenu)
      this.popupClass = "f_popup_posn_tl";
    else
      this.popupClass = "f_popup_posn_tr kendo_popup";
  }
  ngAfterViewInit(): void {
  }

  @Output()
  savebtnclick: EventEmitter<any> = new EventEmitter();
  savebtnClick(e) {
    this.savebtnclick.emit(e);
  }

  @Output()
  cancelbtnclick: EventEmitter<any> = new EventEmitter();
  cancelbtnClick(e) {
    this.cancelbtnclick.emit(e);
  }


  @Output()
  refreshBtnClick: EventEmitter<any> = new EventEmitter();
  refreshPopup(e) {
    this.sharedService.detailpopupEmitted(true)
    this.refreshBtnClick.emit(e);
  }

  resizeWindow(event: any) {
    let menuWrapperElm = document.querySelector(".f_sidebar_category_lst").classList;
    if (menuWrapperElm.contains("firstLevelMenuCollapsed")) {
      let elm = this.el.nativeElement.querySelector("#" + this.popupId);
      if (elm.classList.contains("popupSizeV1") || elm.classList.contains("popupSizeV2") || elm.classList.contains("popupSizeV3"))
      {
        document.querySelector("#" + this.popupId)?.classList.remove("popupSizeV1");
        document.querySelector("#" + this.popupId)?.classList.remove("popupSizeV2");
        document.querySelector("#" + this.popupId)?.classList.remove("popupSizeV3");
      }
       

      else
        document.querySelector("#" + this.popupId)?.classList.add("popupSizeV1");
    }
    else {
      let elm = this.el.nativeElement.querySelector("#" + this.popupId);
      if (elm.classList.contains("popupSizeV2") || elm.classList.contains("popupSizeV3"))
      {
        document.querySelector("#" + this.popupId)?.classList.remove("popupSizeV2");
        document.querySelector("#" + this.popupId)?.classList.remove("popupSizeV3");
      }
        
      else
        document.querySelector("#" + this.popupId)?.classList.add("popupSizeV3");
    }


  }

  @Output()
  closeBtnClick: EventEmitter<any> = new EventEmitter();
  public closepopup(e): void {
    // this.popupShowshadow = false;
    this.closeBtnClick.emit(e);
  }
  @Output() dropdownItemClick: EventEmitter<any> = new EventEmitter();
  public exportItemClick(e): void {
    //console.log('test3');
    // this.popupShowshadow = false;
    this.dropdownItemClick.emit(e);
  }
  @Output() dropdownEItemClick: EventEmitter<any> = new EventEmitter();
  public pickSessionItemClick(e): void {
    //console.log('test2');
    // this.popupShowshadow = false;
    this.dropdownEItemClick.emit(e);
  }
  @Output() btnClick: EventEmitter<any> = new EventEmitter();
  public onActionClick(e): void {
    //console.log('test');

    this.btnClick.emit(e);
  }
  
  resizenew(e){
    let panel = document.getElementsByClassName("kendo_popup")[0] as HTMLElement;
    const dx = m_pos - e.x;
    m_pos = e.x;
    panel.style.width = (parseInt(getComputedStyle(panel, '').width) + dx) + "px";
  }

  popopResizeNew(ele){
    let bordersize=4
   
    ele.addEventListener("mousedown", function(e){
   
      if (e.offsetX < bordersize) {
      
        m_pos = e.x;
          document.addEventListener("mousemove", resizenew, false);
      }
    }, false);
      document.addEventListener("mouseup", function(){
        document.removeEventListener("mousemove", resizenew, false);
    }, false);

    const resizenew = function(e){
      const dx = m_pos - e.x;
      m_pos = e.x;
      ele.style.width = (parseInt(getComputedStyle(ele, '').width) + dx) + "px";
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["enableDragResize"]) {
      this.enableDragResize = changes["enableDragResize"].currentValue;
     
    }
    if (changes["popupShow"]) {
      this.popupShow = changes["popupShow"].currentValue;
      setTimeout(() => {
      
        let ele = document.getElementById(this.popupId);
        ele ? ele?.classList.remove("draggablePopup") : ele;
        if(ele !=null && ele !=undefined && this.enableDragResize){
          ele?.classList.add("draggablePopup");
          if(ele?.classList.contains("draggablePopup"))
          {
            this.popopResizeNew(ele);
          }
         
        } 
       
      }, 1000);
    }
    if (changes["popupHeadertext"])
    {
      this.popupHeadertext=changes["popupHeadertext"].currentValue;
    }
  }
  resizeonClick(e: any, div: any) { 
    
    if (this.showFullScreen) {
      let elm = document.getElementById(this.popupId);
     let parentElm = elm.querySelector("#popupresizediv");
     parentElm.classList.add("f_fullscreendiv");
      this.showFullScreen = false;
    }
    else {
      let elm = document.getElementById(this.popupId);
     let parentElm = elm.querySelector("#popupresizediv");
     parentElm.classList.remove("f_fullscreendiv");
      this.showFullScreen = true;
    }
  }
}
