import { ListViewDataResult } from '@progress/kendo-angular-listview';
import { Component, ElementRef, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { GridComponent, PageChangeEvent, ScrollMode, SelectableMode, SelectableSettings, SelectAllCheckboxState } from "@progress/kendo-angular-grid";
import { ContextMenuComponent } from "@progress/kendo-angular-menu";
import { EventEmitter } from "@angular/core";
import { SortDescriptor, orderBy, process } from "@progress/kendo-data-query";
import { SharedService } from "@services/shared/shared.service";
import { ExportService } from '@services/export/export.service';
import { PaginationType } from '@models/customcontrols/falainalistview';
import { ColumnSetting } from '@models/miscellaneous/ColumnSetting';
import { FalainaSearchSize } from '../falainafilter/falainasearchsize';
import { SelectAllState } from '../falainagridcontrol/falainagridv2/falainagridv2.component';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Export } from '@models/export/Export';
@Component({
  selector: 'falaina-multicolumn-template-grid',
  templateUrl: './falainamulticolumntemplategrid.component.html'
})
export class FalainamulticolumntemplategridComponent implements OnInit {
  @Input() itemTemplate: TemplateRef<HTMLElement>;

  @Input() enablePagerResize : boolean = false;
  exportDataItems: any[];
  gridView: ListViewDataResult;
  check = null;
  listArray: any[] = [];
  defaultPaginationItems: any[];
  showFullScreen:boolean=true;
  @Input()
  gridScrollable: ScrollMode = "scrollable";
  @Input()
  gridHeight;
  //   @Input('virtualdata')
  //   set virtualdata(value:ListViewDataResult)
  //  { 
  //      if(value == undefined)
  //      return;
  //    this.isLoaded=false;    
  //    this.scrollableDataItem=value?.data;
  //    if(this.scrollableDataItem)
  //    {this.skip=value?.data.length;
  //      setTimeout(() => {this.isLoaded = true;},1000);
  //    }

  //  }
  @Input()
  virtualdata;
  @ViewChild('dualgrid', { read: ElementRef, static: false }) elementView: ElementRef;
  scrollableDataItem: any[] = [];
  @Input() gridField: ColumnSetting[] = [];
  @Output()
  loadmore: EventEmitter<any> = new EventEmitter();
  @Output()
  sortChange: EventEmitter<any> = new EventEmitter();
  @Input()
  public skip = 0;
  @Input()
  gridEnableRemove: boolean = false;
  @Input()
  gridShowRowSelection: boolean = false;
  @Input()
  paginationType: PaginationType = 'Basic';
  @Input() gridPagesize = 20;
  scrolled: boolean;
  totalPageSize: any = null;
  rmSelectedItm: any[] = [];
  selectedCount: number;
  resetGrid: boolean = false;
  @Input()
  gridLinkableColoumn: any = "";

  public get showPager(): boolean | any {
    if (this.paginationType == "Virtual" || this.paginationType == "Default Virtual")
      return false;
    return this.gridDataitem && this.gridDataitem.total > 0;;
  }

  public get showSortable(): boolean | any {
    return this.gridSortable
  }
  @Input() gridPageable = true;

  @Input()
  gridSortable = true;

  gridReorderable = true;
  gridResizable = true;

  @Input()
  gridSelectedRows: any[] = [];
  @Input()
  gridRowSelection = true;
  @Input() gridDataitem: ListViewDataResult

  @Input()
  gridShowcontextmenu: boolean = true;
  @Input()
  gridRefresh: boolean = true;
  @Input()
  gridColumnchooser: boolean = true;
  @Input()

  showTitleAtrribute: boolean = false;
  @Input()
  gridHeaderColumnchooser: boolean = true;
  @Input()
  gridLinkablecolumn: string = "";
  @Input() gridUniqueField: string = "Id";
  @Input() gridshortCutIconField: string = "icon";
  @Input() gridshortCutTextField: string = "text";


  @Input()
  gridCompactview: boolean = false;

  @Input()
  isToolbarAlignedLeft: boolean = false;

  nGridToolbar: boolean = false;
  @Input() set gridToolbar(value: any) {
    this.nGridToolbar = value;
  }
  get gridToolbar(): any {
    return this.nGridToolbar;
  }



  @Input()
  gridStyle: any = "max-height: calc(100vh - 270px) ";
  
  @Input()
  gridContextItems: any[] = [];
  @Input()
  gridUseraccesselements: any[] = [];
  @Input()
  gridSortlist: SortDescriptor[];

  @Input()
  gridSelectionMode: SelectableMode = "single";

  @Input()
  gridShowSelectAll: boolean = false;

  gridSelectedDataitem: any = [];

  @ViewChild("gridmenu")
  gridContextMenu!: ContextMenuComponent;
  selectableSettings!: SelectableSettings;
  public selectAllState: SelectAllCheckboxState = 'unchecked';
  public dummySelectAllState: SelectAllState = 'unchecked';
  buttonCount = 5;
  info = true;
  type: any = "numeric";

  @Input()
  hasMultipleSort = false;

  inputValue: string = "";
  showpopup: boolean = false;
  public filteritems: any[] = [{ text: "Add", icon: "add" }];
  public filtertypes: Array<string> = ["Select", "Is equal to", "Is not equal to", "Contains", "Does not contain", "Starts with", "Ends with", "Is null", "Is not null", "Is empty", "Is not empty"];
  public selectedfiltertype = "Select";
  public selectedfieldValue = "Select";
  public listItems: Array<string> = [];
  @ViewChild("filterallcolumns") inputfilter;

  public mySelection: number[] = [];

  @Input() gridShortcutKeys: any[] = [];
  @Input() iconButton: boolean = false;

  @Input() isIconbuttonList: boolean = false;
  @Input() gridDefaultPaging: boolean = true;
  @Input() searchSize: FalainaSearchSize = "Xsmall";

  @Input()  gridCustomPageSizes =  [5,10,15,20];
  @Input() hasRefresh: boolean = false;
  refreshitemlist: ListViewDataResult;
  isrefresh: boolean;

  mySubscription :any;

  field : any = [];

  @Output() refreshButtonClick: EventEmitter<any> = new EventEmitter();

  refreshClick(e) {
    this.refreshButtonClick.emit(e);
  }
  constructor(private exportService: ExportService, private sharedService: SharedService) {
    this.sharedService.gridViewChangeEmittedEvent
      .subscribe((data) => {
        this.ChangeGridView(data);
      });

    this.sharedService.exportDataEmittedEvent
      .subscribe((data) => {
        this.exportDocument(data)

      });

    // this.allData = this.allData.bind(this);
  }
  showResizeColumn : boolean = true;
  ngOnChanges(changes: SimpleChanges): void {
    let index = this.gridCustomPageSizes.indexOf(Number(this.gridPagesize));
    if(index !== -1)
    {
      this.gridCustomPageSizes.splice(index,1)
    }  
    this.sharedService.refreshbuttonEmittedEvent.subscribe(x =>{       
      if(x){
        this.gridDataitem=this.refreshitemlist;
        this.skip=0
        this.isrefresh=true;
      }
    })
    if (this.gridDataitem && changes?.exportData?.currentValue['name'] === this.gridDataitem['title']) {
      this.exportDatas(changes.exportData?.currentValue['value']);
    }
    this.heightChange(changes);
    this.DataItmChange(changes)
    this.resetGridChnage(changes);

    if(changes["gridField"])
    {
      let gridField = changes["gridField"].currentValue;
       if(gridField?.length > 0)
       {
              
           let result =  gridField.filter((x: any) => x.field == 'Action');
            
           if(result.length > 0)
          this.showResizeColumn = false;
          else
          this.showResizeColumn = true;
       }
    }

  }
  heightChange(changes: SimpleChanges) {
    let currVal = changes["gridHeight"]?.currentValue
    if (currVal && currVal.includes("calc")) {
      this.gridStyle = "max-height:" + currVal
    }
    else if (currVal) {
      currVal = currVal + "px"
      this.gridStyle = "max-height:" + currVal
    }
  }
  DataItmChange(changes: SimpleChanges) {
    if (changes["gridDataitem"]?.currentValue && this.paginationType.includes('Default')) {
      this.defaultPaginationItems = changes["gridDataitem"].currentValue?.data
      this.selectedCount = this.gridSelectedRows?.length;
      this.changeState();
      this.loadItems();
      this.setGridFields();

    }
    else if (changes["gridDataitem"]?.currentValue && (this.paginationType == 'Virtual' || this.paginationType == 'Basic')) {
      this.setGridFields();
      this.selectedCount = this.gridSelectedRows?.length;
      this.changeState();
      this.updateSelectedDataitem();
    }


  }
  resetGridChnage(changes: SimpleChanges) {
    if (changes['resetGrid']?.currentValue) {
      this.resetGridProperties();
      this.resetGrid = false;
    }
  }
  resetGridProperties() {
    this.selectedCount = 0;
    this.gridSelectedDataitem = [];
    this.gridSelectedRows = [];
    this.dummySelectAllState = 'unchecked';
    this.selectAllState = "unchecked";
  }


  changeState() {
    var len = this.selectedCount
    if (len === 0) {
      this.selectAllState = 'unchecked';
      this.dummySelectAllState = 'unchecked'
    } else if (len > 0 && len < this.gridDataitem.total && this.selectAllState == "checked") {
      this.selectAllState = 'unchecked';
      this.dummySelectAllState = 'checked'
    } else if (this.gridDataitem.total == len) {
      this.selectAllState = 'checked';
      this.dummySelectAllState = 'checked'
    }
  }
  exportDocument(data: any) {
    let grid: GridComponent;
    grid = data["grid"];
    if (data["event"].text == "Export To Excel") {
      this.fileName = "Export.xlsx";
      grid.saveAsExcel();


    }
    else if (data["event"].text == "Export To CSV") {

      this.fileName = "Export.csv";
      grid.saveAsExcel();


    }
    else if (data["event"].text == "Export To PDF") {

      this.fileName = "Export.pdf";
      setTimeout(() => {
        grid.saveAsPDF();
      }, 500);


    }
  }
  ngOnInit(): void {
    
    setTimeout(()=>{
      this.refreshitemlist=this.gridDataitem
      
    },2000)
    var liston, index;
    liston = document.getElementsByClassName("k-header k-touch-action-none k-grid-draggable-header");
    
    setTimeout(() => {
      for (index = 0; index < liston.length; ++index) {
        liston[index].setAttribute('translate','');
      }
    }, 0);
   
    if (this.gridSelectionMode === "multiple")
      this.gridShowSelectAll = true;
    this.setSelectableSettings();
    this.setPageSizeAndScroll();
    this.pageActionEmitter();
  }
  setPageSizeAndScroll() {
    if (this.paginationType == 'Default' || this.paginationType == 'Basic') {
      this.gridScrollable = 'scrollable'
      return;
    }
    this.check = 36
    this.gridScrollable = 'virtual'
  }
  //Set's the grid field when user doesn't pass any grid fields
  setGridFields() {

    if (this.gridField.length > 0 || this.gridDataitem?.data?.length == 0) {

      return
    }

    var myObj = this.gridDataitem.data[0];
    this.gridField = [];
    Object.keys(myObj).forEach(key => {
      if (!(key.includes("$")))
        this.gridField.push(
          {
            field: key,
            title: key.charAt(0).toUpperCase() + key.slice(1),
            type: 'text',

          }
        )

    });

  }
  onVisibilityChange(event){
    this.sharedService.coloumnvisibilityEmitted(event);
  }
  private loadItems(): void {
    //console.log(this.showSortable)
     if(this.showSortable && !this.isrefresh){
      let sortarray = {
        data: this.handleData(),
        total: this.defaultPaginationItems?.length,
       }
      let array = sortarray.data.slice(this.skip, this.skip + this.gridPagesize)
      if(array.length > 0){
      
        this.gridDataitem = {
          data: array,
          total: sortarray.total,
        };
      }
     }else{
   this.gridDataitem = {
       data: ((this.defaultPaginationItems?.slice(this.skip, this.skip + this.gridPagesize))),
       total: this.defaultPaginationItems?.length,
    };
     }
   
    this.updateSelectedDataitem();
  }
  private handleData() {
  
    var pagedData = this.defaultPaginationItems
    if (!this.gridSortlist) { return pagedData; }

    var orderedAndPagedData = orderBy(pagedData, this.gridSortlist);
    return orderedAndPagedData;
}
  // private loadVirtualItems(): void {
  //   if(!this.scrolled)  
  //         this.scrollableDataItem =this.gridDataitem.data.slice(this.skip, this.skip + this.gridPagesize)
  //   else
  //   {
  //     this.scrollableDataItem = [
  //       ...this.scrollableDataItem,
  //       ...this.gridDataitem.data.slice(this.skip, this.skip + this.gridPagesize),
  //     ];
  //   }
  //   this.totalPageSize=this.gridDataitem.total
  //   this.skip = this.scrollableDataItem.length;       
  //   if (this.scrollableDataItem) {
  //     setTimeout(() => { this.isLoaded = true; }, 500);
  //     this.scrolled = false; 
  //   }      
  // }

  ngAfterContentChecked() {
  }
  ngAfterViewInit() {




  }

  setSelectableSettings() {
    this.selectableSettings = {
      enabled: true,
      checkboxOnly: true,
      mode: this.gridSelectionMode,
      drag: false
    };
  }


  public sortChangefn(sort: SortDescriptor[]): void {
    this.gridSortlist = sort;
    this.isrefresh=false;

    if (this.paginationType.includes("Default") &&(this.gridSelectedDataitem?.length == 0)){

      this.loadItems();
    }else{
      this.sortChange.emit(sort);
    }


  }
  onSelect({ item }: any, dataItem: any, rowIndex: any): void {
    if(item['text'] == 'Export'){
      this.contextMenuExport(dataItem);
    }
    this.contextMenuItemSelect.emit({ "contextItem": item, "dataItem": dataItem, "rowIndex": rowIndex });
  }

  @Output() pageChange: EventEmitter<any> = new EventEmitter();
  onPageChange(event: PageChangeEvent): void {

    this.isLoaded = false;
    this.skip = event.skip;
    this.gridPagesize = event.take;
    
    
    if (this.paginationType == 'Default' || this.paginationType == 'Default Virtual') {
      this.loadItems()
    }
    this.fetchData(event);
  }
  public fetchData(event): void {
    this.pageChange.emit(event);
    setTimeout(() => {
      this.isLoaded = true;
    }, 500);
  }

  @Output() contextMenuItemSelect: EventEmitter<any> = new EventEmitter();


  //@Output()  selectedCheckedRows: EventEmitter<any> = new EventEmitter();
  selectedKeysChange(rows: any) {

    if (this.dummySelectAllState == "checked") {
      this.selectedCount = this.gridDataitem.total - (this.gridSelectedDataitem.length - rows.length);
      this.changeState();

      this.gridSelectedRows = rows
      this.rmSelectedItm = this.gridSelectedDataitem.filter(function (obj) { return rows.indexOf(obj) == -1; });

      let a = []
      this.rmSelectedItm.forEach(x => {
        let newObject = {}
        newObject[this.gridUniqueField] = x;
        a.push(newObject);
      })
      let response = {
        removedItem: a,
        selectAllState: "checked"
      }
      this.selectAllEvent.emit(response);
      return;

    }
    if (this.dummySelectAllState == "reset") {
      this.dummySelectAllState = 'unchecked';
      let response = {
        removedItem: [],
        selectAllState: "unchecked"
      }
      this.selectAllEvent.emit(response);
      return;
    }
    if (this.gridSelectionMode == "single") {
      this.gridSelectedRows = rows;
      this.getSelectedItemByIndex(rows);
      this.selectionchange.emit(this.gridSelectedDataitem);
    } else {
      this.gridSelectedRows = rows;
      let response = []
      this.gridSelectedRows.forEach(x => {
        let newObject = {}
        newObject[this.gridUniqueField] = x;
        response.push(newObject);
      })
      this.selectedCount = this.gridSelectedRows.length
      this.changeState();

      this.selectionchange.emit(response);
    }

  }
  getSelectedItemByIndex(rows: any[]) {
    this.gridSelectedDataitem = [];
    rows.forEach((d: any) => {
      this.gridSelectedDataitem.push({
        dataItem: this.gridDataitem.data.filter((x: any) => { return x[this.gridUniqueField] == d })[0],
        rowIndex: this.gridDataitem.data.findIndex((x: any) => x[this.gridUniqueField] == d),
        isOnClickLinkableColumn: false
      });

    });
  }

  public onSelectAllChange(checkedState: SelectAllCheckboxState) {
    if (checkedState === 'checked') {

      this.rmSelectedItm = [];
      this.gridSelectedRows = [];
      this.gridSelectedDataitem = [];
      this.selectAllState = 'checked';
      this.dummySelectAllState = 'checked'
      this.selectedCount = this.gridDataitem.total;
      this.updateSelectedDataitem();

    } else {
      this.gridSelectedDataitem = [];
      this.gridSelectedRows = [];
      this.rmSelectedItm = [];
      this.selectedCount = 0;
      this.selectAllState = 'unchecked';
      this.dummySelectAllState = 'reset'
    }
  }
  updateSelectedDataitem() {
    if (this.dummySelectAllState == "checked") {
      this.gridDataitem.data.map((item) => {
        this.gridSelectedDataitem.push(item[this.gridUniqueField])
        this.gridSelectedRows.push(item[this.gridUniqueField])
      }
      )
      this.gridSelectedDataitem = [...new Set(this.gridSelectedDataitem)];
      this.gridSelectedRows = [...new Set(this.gridSelectedRows)];
      this.rmSelectedItm.forEach(element => {
        let index = this.gridSelectedRows.indexOf(element)
        if (index > -1) {
          this.gridSelectedRows.splice(index, 1);
        }
      });
    }

  }

  private rowItem: any;

  private rowIndex: any;
  onClick(e: any, dataItem, rowIndx): void {
    this.rowItem = dataItem;
    this.rowIndex = rowIndx;
    e.preventDefault();
    this.gridContextMenu.show(e.target);
  }

  @Output()
  selectionchange: EventEmitter<any> = new EventEmitter();
  selectAllEvent: EventEmitter<{
    removedItem: any[]
    selectAllState:string
  }> = new EventEmitter();
  ChangeGridView(e: any) {
    var normalview = (e.text == "Normal List");

    var element = document.querySelectorAll(".k-grid tr");
    for (var i = 0; i < element.length; i++) {
      if (normalview)
        element[i].classList.remove("compactview");
      else
        element[i].classList.add("compactview");
    }
  }
  refreshGrid(e: any) {
    this.skip = 0;
    this.inputfilter.txtboxValue = "";

  }




  isLoaded: boolean = true
  @Output()
  OpenFieldPopup: EventEmitter<any> = new EventEmitter();

  openPopup(e: any, rowIndex: any, dataItem: any) {
    this.gridSelectedDataitem = [];
    this.gridSelectedDataitem.push({
      dataItem: dataItem,
      rowIndex: rowIndex,
      isOnClickLinkableColumn: true
    })
    this.selectionchange.emit(this.gridSelectedDataitem);
  }

  //Call this method on button
  title: string = "";

  @Output() onShortcutButtonClick: EventEmitter<any> = new EventEmitter();
  onshortcutBtnClick(e: any, item: any, dataItem: any, rowIdx: any) {
    this.onShortcutButtonClick.emit({ "Item": item, "dataItem": dataItem, "rowIndex": rowIdx });
  }
  @Output() userPermissionItemSelect: EventEmitter<any> = new EventEmitter();

  onUserPermissionClick(e: any, item: any, dataItem: any, rowIdx: any) {
    this.userPermissionItemSelect.emit({ "Item": item, "dataItem": dataItem, "rowIndex": rowIdx });
  }


  @Output() OnDeteleItemclick: EventEmitter<any> = new EventEmitter();
  onRemoveBtnClick(e: any) {
    // if (this.dummySelectAllState == "checked") {
    //   this.rmSelectedItm.forEach(x => {

    //     let index = this.defaultPaginationItems?.findIndex(i => {

    //       return i[this.gridUniqueField] == x

    //     });
    //     if (index > -1)
    //       this.defaultPaginationItems?.splice(index, 1)
    //   })
    //   this.OnDeteleItemclick.emit(this.defaultPaginationItems);

    // }
    // else {
    //   this.gridSelectedRows.forEach(x => {

    //     let index = this.defaultPaginationItems?.findIndex(i => {

    //       return i[this.gridUniqueField] == x

    //     });
    //     if (index > -1)
    //       this.defaultPaginationItems?.splice(index, 1)
    //   })
    //   this.OnDeteleItemclick.emit(this.defaultPaginationItems);
    // }

    var dummy = []
    this.gridSelectedRows.forEach(x=>{
      
      var index =  this.defaultPaginationItems?.findIndex(i=>{
       
        return i[this.gridUniqueField]==x
      
      });
      dummy.push(this.defaultPaginationItems[index])
      })
      this.OnDeteleItemclick.emit(dummy);

    this.selectedCount = 0;
    this.gridSelectedDataitem = [];
    this.gridSelectedRows = [];
    this.dummySelectAllState = 'unchecked';
    this.selectAllState = "unchecked";
  }


  onRemoveSelectedRowsClick(e: any) {
    e.preventDefault();
    if (this.dummySelectAllState == "checked") {
      let response = {
        removedItem: [],
        selectAllState: "unchecked"
      }
      this.selectAllEvent.emit(response);

    }
    else {
      this.selectionchange.emit([]);
    }

    this.selectedCount = 0;
    this.gridSelectedDataitem = [];
    this.gridSelectedRows = [];
    this.dummySelectAllState = 'unchecked';
    this.selectAllState = "unchecked";

  }

  //#region  filter region 

  // filter input and output 
  //value can be left or right
  @Output()
  switchEvent: EventEmitter<any> = new EventEmitter();
  @Input()
  istoolbarAlignleft: boolean = true
  @Output()
  keypressEnter: EventEmitter<any> = new EventEmitter();
  @Output()
  adhocFilterClick: EventEmitter<any> = new EventEmitter();
  @Output()
  customFilterAction: EventEmitter<any> = new EventEmitter();
  @Input()
  standardFilterData: Array<string> = [];
  @Input()
  hasCustomFilter: boolean = false;
  @Input()
  hasAdhocFilter: boolean = false;
  @Input()
  hasFilter: boolean = false;
  @Input()
  hasSwitchView: boolean = false;

  onAdhocFilterClick(event) {
    this.adhocFilterClick.emit()
  }
  onCustomFilterAction(event) {
    this.customFilterAction.emit(event)
  }
  onEnter(event) {
    this.selectedCount = 0;
    this.gridSelectedDataitem = [];
    this.gridSelectedRows = [];
    this.dummySelectAllState = 'unchecked';
    this.selectAllState = "unchecked";
    this.keypressEnter.emit(event)
  }
  onViewSwitch(event) {
    this.switchEvent.emit(event)
  }
  //#endregion

  //#region  export
  @Input() gridExport: boolean = false;


  fileName: any = "";


  exportItemClick(e: any, grid: GridComponent) {
    if (e.text == "Export To Excel") {
      this.fileName = "Export.xlsx";
      this.exportFunction("Excel", grid);


    }
    else if (e.text == "Export To CSV") {

      this.fileName = "Export.csv";
      this.exportFunction("Excel", grid);


    }
    else if (e.text == "Export To PDF") {

      this.fileName = "Export.pdf";
      setTimeout(() => {
        this.exportFunction("PDF", grid);
      }, 500);


    }
  }


  public exportFunction(type: any, grid: GridComponent): void {
    if (type == "Excel") {

      grid.saveAsExcel();
    }
    else {
      grid.saveAsPDF();
    }

  }
  resizeonClick(e: any, div: any) {
    let targetElm = e.target;
    //console.log(targetElm.closest("#gridresizediv"));
    if (this.showFullScreen) {
      // let elm = document.getElementById("gridresizediv");
      // elm.classList.add("f_fullscreendiv");
      targetElm.closest("#gridresizediv").classList.add("f_fullscreendiv");
      this.showFullScreen = false;
    }
    else {
      targetElm.closest("#gridresizediv").classList.remove("f_fullscreendiv");
      // let elm = document.getElementById("gridresizediv");
      // elm.classList.remove("f_fullscreendiv");
      this.showFullScreen = true;
    }
  }


  //   public allData(): ExcelExportData { 
  //     const result: ExcelExportData =  {
  //         data: this.gridDataitem.data
  //     };
  //     return result;
  // }
  //#endregion

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: this.defaultPaginationItems
    };
    return result;
  }

  
  contextMenuExport(dataItem)
  {
    let array=[];
    array.push(dataItem)
    let dataBody = []
    let dataHead = []
    this.listArray = []
    this.gridField.forEach((element, index) => {
  
      dataHead.push(element['title'])
  
    });
    array.forEach(element => {
      let gridDataitemData = [];
      this.gridField.forEach((data, i) => {
        gridDataitemData = [...gridDataitemData, element[data['field']]]
      })
      dataBody.push(gridDataitemData)
  
    });
  
  
    dataBody.forEach(bodyArray => {
      let mockData = {};
      bodyArray.forEach((element, i) => {
  
        mockData[dataHead[i]] = element;
  
      });
      this.listArray.push(mockData);
    })
  
    this.exportService.exportToEXCEL(this.listArray, "ExportFile.xlsx");
  
  }

  exportDatas(e) {

    if (e == "Export To Excel") {

      this.exportToEXCEL()
    }
    else if (e == "Export To CSV") {

      this.exportToCSV()
    }
    else if (e == "Export To PDF") {
      this.exportToPDF()
    }
    else if (e == "Delete") {
     this.gridSelectedDataitem =[];
    }
  }
  exportToEXCEL() {
 
    let dataBody = []
    let dataHead = []
    this.listArray = []
    
    this.gridField.forEach((element, index) => {


      dataHead.push(element['title'])

    });
    this.exportDataItems.forEach(element => {
      let gridDataitemData = [];
      this.gridField.forEach((data, i) => {
        gridDataitemData = [...gridDataitemData, element[data['field']]]
      })
      dataBody.push(gridDataitemData)



    });
    // this.gridDataitem.data.forEach(element => {
    //   let gridDataitemData = [];
    //   this.gridField.forEach((data, i) => {
    //     gridDataitemData = [...gridDataitemData, element[data['field']]]
    //   })
    //   dataBody.push(gridDataitemData)



    // });

    dataBody.forEach(bodyArray => {
      let mockData = {};
      bodyArray.forEach((element, i) => {

        mockData[dataHead[i]] = element;

      });
      this.listArray.push(mockData);
    })




    this.exportService.exportToEXCEL(this.listArray, "ExportFile.xlsx");
  }
  exportToPDF() {

    let dataBody = []
    let dataHead = []
    var widthCell = {};
    this.gridField.forEach((element, index) => {
       dataHead.push(element['title'])

    });
    dataHead = [dataHead]
    // this.gridDataitem.data.forEach(element => {
    //   let gridDataitemData = [];
    //   this.gridField.forEach((data, i) => {
    //     gridDataitemData = [...gridDataitemData, element[data['field']]]
    //   })
    //   dataBody.push(gridDataitemData)



    // });
    this.exportDataItems.forEach(element => {
      let gridDataitemData = [];
      this.gridField.forEach((data, i) => {
        gridDataitemData = [...gridDataitemData, element[data['field']]]
      })
      dataBody.push(gridDataitemData)



    });



    this.exportService.exportToPDF("ExportFile.pdf", dataHead, dataBody, widthCell);
  }

  exportToCSV() {
    // let exportItems = { data: this.gridDataitem.data, total: this.gridDataitem.data.length };
    // this.field = this.gridField;
    // const exportData = new Export(
    //   exportItems.data, this.field, []
    // );
    // this.exportService.exportToCSV(exportData, "ExportFile.csv");
    let exportItems = { data: this.exportDataItems, total: this.exportDataItems.length };
    this.field = this.gridField;
    const exportData = new Export(
      exportItems.data, this.field, []
    );
    this.exportService.exportToCSV(exportData, "ExportFile.csv");
  }
  pageActionEmitter()
{
  this.mySubscription = this.sharedService.actionEmittedEvent.subscribe((e: string) => { 
   if(this.gridSelectedDataitem.length > 0){
      this.exportDataItems=[]
      for(let i=0;i< this.gridSelectedDataitem.length;i++){
        this.exportDataItems.push(this.gridSelectedDataitem[i].dataItem)
      }
     this.exportDatas(e);
    } 
   else if (this.paginationType.includes("Default") && this.gridExport) {     
    
      this.exportDataItems=[...this.defaultPaginationItems]
      this.exportDatas(e);
    }
  })
  if (this.gridLinkablecolumn) {
    this.gridRowSelection = true;
    //this.gridUniqueField = this.gridLinkablecolumn;}
  }
}
ngOnDestroy() { 
      
  if (this.mySubscription) {
    this.mySubscription.unsubscribe();
  }
}
}
