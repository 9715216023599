import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'falaina-appbar-section',
  template: `<div class="k-appbar-section {{appbarSectionClass}}">
                    <ng-content></ng-content>    
                </div>`
})
export class FalainaAppbarSectionComponent implements OnInit {
    @Input() appbarSectionClass="";
  constructor() { }
  ngOnInit(): void {
  }

}
