import { Injectable, NgZone, ViewChild, ViewContainerRef,ComponentFactoryResolver, ElementRef } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { interval, throwError } from 'rxjs';
import { customHttpclient } from '@services/shared/customhttpclient';
import {  HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@environment/environment';
import { UILog } from './UILogInfo';
import { UserService } from '@services/user.service';
import { authServiceDetail } from '../authentication/auth.service';

const headers = new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');
@Injectable({
    providedIn: 'root'
})
export class AppNotificationService {
 
  @ViewChild('notification', { read: ViewContainerRef }) vcRef: ViewContainerRef;
  count=2000;
  constructor(private zone: NgZone, private notificationService: NotificationService,private userService: UserService,
    private customhttp: customHttpclient
   ) {
 
    }

  showSuccess(message: string): void {
    document.getElementById("notification").style.display="block";
    // Had an issue with the snackbar being ran outside of angular's zone.
    this.zone.run(() => {
      var d1 = document.getElementById("notification");
      d1.innerHTML='<kendo-notification dir="ltr" class="k-notification-container f_fullwidth"><div class="f_fullwidth k-widget k-notification k-notification-success k-notification-closable button-notification" ng-reflect-ng-class="button-notification"><div class="k-notification-wrap"><span class="ms-Icon ms-Icon--Completed f_font_size_16"></span><div class="k-notification-content">'+message+'</div><a (click)="onDecline()" class="ms-Icon ms-Icon--StatusCircleErrorX f_font_size_18" title="Close"></a></div></div></kendo-notification>'
      d1.addEventListener('click', this.onDecline);
    });

 setTimeout(() => { document.getElementById("notification").style.display="none";    }, 2000);


    // this.notificationService.show({
    //   content: message,
    //   animation: {
    //     type: 'slide',
    //     duration: 500,
    //   },
    //   type: { style: "success" , icon: true},
    //   position: { horizontal: 'left', vertical: 'top' },
    
    // });

   
  }

  showWarning(message: string): void {
    document.getElementById("notification").style.display="block";
    // Had an issue with the snackbar being ran outside of angular's zone.
    this.zone.run(() => {
      var d1 = document.getElementById("notification");
      d1.innerHTML= '<kendo-notification dir="ltr" class="k-notification-container f_fullwidth"><div class="f_fullwidth k-widget k-notification k-notification-warning k-notification-closable button-notification" ng-reflect-ng-class="button-notification"><div class="k-notification-wrap"><span class="ms-Icon ms-Icon--Warning f_font_size_16"></span><div class="k-notification-content">'+message+'</div><a (click)="onDecline()" class="ms-Icon ms-Icon--StatusCircleErrorX f_font_size_18" title="Close"></a></div></div></kendo-notification>'
      d1.addEventListener('click', this.onDecline);
    });
    
 setTimeout(() => { document.getElementById("notification").style.display="none";    }, 2000);

    // this.notificationService.show({
    //   content: message,
    //   animation: {
    //     type: 'slide',
    //     duration: 500,
    //   },
    //   type: { style: "warning" },
    //   position: { horizontal: 'left', vertical: 'top' },
    // });
  }

  showInfo(message: string): void {
 
    // Had an issue with the snackbar being ran outside of angular's zone.
  //   this.notificationService.show({
  //     content: message,
  //     animation: {
  //       type: 'slide',
  //       duration: 500,
  //     },
  //     type: { style: 'info', icon: true },
  //     position: { horizontal: 'left', vertical: 'top' },
  // });
  document.getElementById("notification").style.display="block";
  var d1 = document.getElementById("notification");
  d1.innerHTML = '<kendo-notification dir="ltr" class="k-notification-container f_fullwidth"><div class="f_fullwidth k-widget k-notification k-notification-info k-notification-closable button-notification" ng-reflect-ng-class="button-notification"><div class="k-notification-wrap"><span class="ms-Icon ms-Icon--Info f_font_size_16"></span><div class="k-notification-content">'+message+'</div><a (click)="onDecline()" class="ms-Icon ms-Icon--StatusCircleErrorX f_font_size_18" title="Close"></a></div></div></kendo-notification>'
  d1.addEventListener('click', this.onDecline);
  
 setTimeout(() => { document.getElementById("notification").style.display="none";    }, 2000);

  }
  onDecline(){
    document.getElementById("notification").style.display="none";
  }
 sleep(millis) {
    return new Promise(resolve => setTimeout(resolve, millis));
}
  async getGlobalError(messagearray){

for (var i = 0; i < messagearray.length; i++) {
   
    document.getElementById("notification").style.display="block";
    var d1 = document.getElementById("notification");
    d1.innerHTML = '<kendo-notification dir="ltr" class="k-notification-container f_fullwidth"><div class="f_fullwidth k-widget k-notification k-notification-error k-notification-closable button-notification" ng-reflect-ng-class="button-notification"><div class="k-notification-wrap"><span class="ms-Icon ms-Icon--ErrorBadge f_font_size_16"></span><div class="k-notification-content">'+messagearray[i]+'</div><a (click)="onDecline()" class="ms-Icon ms-Icon--StatusCircleErrorX f_font_size_18" title="Close"></a></div></div></kendo-notification>'
    d1.addEventListener('click', this.onDecline);
    await this.sleep(5000);
    let data : UILog;
    let userId = authServiceDetail.LoggedinUserId;
    data= {Id:1,UserId:Number(userId), ExceptionMessage:messagearray[i],ExceptionSource:"Http response",StackTrace:"Http response",UserName:"",LogLevel:"error",IP:"",
    Name:"",Message:messagearray[i],InnerExceptionMessage:messagearray[i],LogDate:'',CreatedBy:userId,LastModifiedBy:"",LastModifiedDate:''}

     this.userService.saveUILog(data).subscribe(result => {})
     document.getElementById("notification").style.display="none";
}



}
async showError(message: string): Promise<void> { 
    document.getElementById("notification").style.display="block";
   
      //   this.notificationService.show({
      //     content: message,
      //     animation: {
      //       type: 'slide',
      //       duration: 400,
      //     },
      //     type: { style: 'error', icon: true },
      //     position: { horizontal: 'left', vertical: 'top' },
      //    closable:true
      // });
      
    this.zone.run(() => {    
       var d1 = document.getElementById("notification");
      d1.innerHTML= '<kendo-notification dir="ltr" class="k-notification-container f_fullwidth"><div class="f_fullwidth k-widget k-notification k-notification-error k-notification-closable button-notification" ng-reflect-ng-class="button-notification"><div class="k-notification-wrap"><span class="ms-Icon ms-Icon--ErrorBadge f_font_size_16"></span><div class="k-notification-content">'+message+'</div><a (click)="onDecline()" class="ms-Icon ms-Icon--StatusCircleErrorX f_font_size_18" title="Close"></a></div></div></kendo-notification>'
      d1.addEventListener('click', this.onDecline);
    });
    await this.sleep(5000);
    document.getElementById("notification").style.display="none";
    // var d1 = document.getElementById("notification");
    //   d1.insertAdjacentHTML('beforeend', '<kendo-notification dir="ltr" class="k-notification-container f_fullwidth"><div class="f_fullwidth k-widget k-notification k-notification-error k-notification-closable button-notification" ng-reflect-ng-class="button-notification"><div class="k-notification-wrap"><span class="ms-Icon ms-Icon--ErrorBadge f_font_size_16"></span><div class="k-notification-content">'+message+'</div><a class="ms-Icon ms-Icon--StatusCircleErrorX f_font_size_18" title="Close"></a></div></div></kendo-notification>');
    // this.zone.run(() => {
      
    //   this.notificationService.show({
    //     content: message,
    //     cssClass: 'button-notification',
    //     animation: { type: 'slide', duration: 400 },
    //     position: { horizontal: 'left', vertical: 'top' },
    //     type: { style: 'error', icon: true },
    //     closable: true
    //   });

    // });
  }
}
