
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/authentication/auth-guard.service';
import { OrgchartComponent } from './modules/orgchart/orgchart.component';
import { EndpointModule } from './modules/iacm/endpoint/endpoint.module';
import { AccountsModule } from './modules/iacm/accounts/accounts.module';

import { RegisterModule } from './modules/activeuser/register/register.module';
import { MyschedulehistoryModule } from './modules/myschedulehistory/myschedulehistory.module';


const reportModule = () => import('./modules/report/report.module').then(x => x.ReportModule);
const customControlModule = () => import('./modules/customcontrolusage/customcontrolusage.module').then(x => x.CustomcontrolusageModule);

const userTimelineModule = () => import('./modules/activeuser/usertimeline/usertimeline.module').then(x => x.UsertimelineModule);
const ActiveuserModule = () => import('./modules/activeuser/activeuser.module').then(x => x.ActiveuserModule);
const HrmsuserModule = () => import('./modules/activeuser/hrmsuser/hrmsuser.module').then(x => x.HrmsuserModule);
const HomeModule = () => import('./modules/home/home.module').then(x => x.HomeModule);
const ComplianceModule = () => import('./modules/compliance/compliance.module').then(x => x.ComplianceModule);
const JobsModule = () => import('./modules/jobs/jobs.module').then(x => x.JobsModule);
const WorkflowModule = () => import('./modules/iacm/workflow/workflow.module').then(x=>x.WorkflowModule)

const OrgChartModule = () => import('./modules/orgchart/orgchart.module').then(x => x.OrgchartModule);
const DynamicCRUDModule = () => import('./modules/dynamic-crud/dynamic-crud.module').then(x => x.DynamicCRUDModule);
const ProvisioningModule = () => import('./modules/iacm/provisioning/provisioning.module').then(x=>x.ProvisioningModule);
const PoliciesModule = () => import('./modules/iacm/policies/policies.module').then(x=>x.PoliciesModule);
// const MfaModule = () => import('./modules/mfa/mfa.module').then(x=>x.MfaModule)
  const pf = () => import('./shared/falainausercontrols/provisioningDynField/provisioning-dyn-field.module').then(x=>x.ProvisioningDynFieldModule)
const MobileappModule = () => import('./modules/mobileapp/mobileapp.module').then(x=>x.MobileappModule);
const MfaModule = () => import('./modules/mfa/mfa.module').then(x=>x.MfaModule);
const RequestModule = () => import('./modules/Request/request.module').then(x => x.requestModule);
const AccesscertificationModule = () => import('./modules/accessgovernance/accesscertification/accesscertification.module').then(x => x.AccesscertificationModule);
const accesscampgainModule = () => import('./modules/accessgovernance/accesscampgainreport/accesscampgain.module').then(x => x.AccesscampgainModule)
const activecampgainModule =() =>import('./modules/accessgovernance/activecampaign/activecampgain.module').then(x => x.ActivecampgainModule)
const taskModule =() =>import('./modules/task/task.module').then(x => x.taskModule);
const PamModule = () => import('./modules/pam/pam.module').then(x => x.PamModule);
 //const configurationModule = () => import('./core/configuration/configuration.module').then(x => x.ConfigurationModule)
const DashboardModule = () => import('./modules/dashboard/dashboard.module').then(x => x.DashboardModule);

const endpointModule = () => import('./modules/iacm/endpoint/endpoint.module').then(x=>x.EndpointModule);


const reconcilationModule =() =>import('./modules/reconcilationexception/reconcilationexception.module').then(x => x.reconcilationexceptionModule)
const configurationModule = () => import('./modules/iacm/configuration/configuration.module').then(x=>x.ConfigurationModule)

const AuditlogModule = () => import('./modules/auditlog/auditlog.module').then(x => x.AuditlogModule);
const endpointaccounttypemodule =() => import('./modules/iacm/endpointaccounttype/endpointaccounttype.module').then(x=> x.EndpointModule)
const HrmsconfigModule = () => import('./modules/iacm/hrmsconfig/hrmsconfig.module').then(x => x.HrmsconfigModule);

const emailmodule =() => import('./modules/iacm/email/email.module').then(x=> x.EmailModule)

const reportsmodule =() => import('./modules/reports/reports.module').then(x=> x.ReportsModule)
const AccessReviewModule =() => import('./modules/accessgovernance/accessreview/accessreview.module').then(x=> x.AccessReviewModule)
const UcfModule =() => import('./modules/ucf/ucf.module').then(x=> x.UcfModule)

const accountsModule = () => import('./modules/iacm/accounts/accounts.module').then(x=>x.AccountsModule);
const securityModule = () => import('./modules/security/security.module').then(x=>x.SecurityModule);

const OrganisationModule = () => import('./modules/iacm/organisation/organisation.module').then(x=>x.OrganisationModule)

const TrustedsourceeventModule = () => import('./modules/iacm/trustedsourceevents/trustedsourceevent.module').then(x=>x.TrustedsourceeventModule)

const passwordsyncModule = () => import('./modules/iacm/passwordmanagement/passwordreset.module').then(x=>x.passwordresetModule)

const UploadModule = () => import('./modules/upload/upload.module').then(x => x.UploadModule);
const SsoModule = () => import('./modules/sso/sso.module').then(x => x.SsoModule);
const registerModule = () => import('./modules/activeuser/register/register.module').then(x => x.RegisterModule);
const LogModule =() => import('./modules/iacm/log/log.module').then(x=> x.LogModule)
const DACMAccessReviewModule =() => import('./modules/accessgovernance/dacmaccessreview/dacmaccessreview.module').then(x=> x.DacmaccessreviewModule)

const MywebapplicationModule =() => import('./modules/iacm/mywebapplication/mywebapplication.module').then(x=> x.mywebapplicationModule)
const applicationcategoryModule =() => import('./modules/iacm/applicationcategory/applicationcategory.module').then(x=> x.applicationcategoryModule)
const licenseModule = () => import('./modules/license/license.module').then(x => x.LicenseModule);

const dataManagementModule = () => import('./modules/iacm/datamanagement/datamanagement.module').then(x => x.DatamanagementModule);

const myschedulehistoryModule = () => import('./modules/myschedulehistory/myschedulehistory.module').then(x => x.MyschedulehistoryModule);
const aclModule = () => import('./modules/iacm/acl/acl.module').then(x => x.AclModule);
const notificationlistModule =()  => import('./modules/iacm/mynotification/notificationlist.module').then(x=>x.notificationlistModule);

 const TechnicalRoleModule = () => import('./modules/iacm/technicalrole/technicalrole.module').then(x => x.TechnicalRoleModule);
const BusinessRoleModule = () => import('./modules/iacm/businessrole/businessrole.module').then(x => x.BusinessRoleModule);
const RoleManagementModule = () => import('./modules/rolemanagement/rolemanagement.module').then(x => x.RolemanagementModule);


const manageagentpageModule = () => import('./modules/agent/manageagentpage/manageagentpage.module').then(x => x.manageagentpageModule);
const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  //{ path: '', component: RedirectComponent, pathMatch: 'full' },
  { path: 'register', loadChildren: registerModule },
  { path: 'usertimeline', loadChildren: userTimelineModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'customcontrols', loadChildren: customControlModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'orgchart', loadChildren: OrgChartModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard]},
  { path: 'home', loadChildren: HomeModule  ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'users',  loadChildren: ActiveuserModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'crud/:pagecode', loadChildren: DynamicCRUDModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'workflow',  loadChildren: WorkflowModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'provisioning',  loadChildren: ProvisioningModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'policies',  loadChildren: PoliciesModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'request', loadChildren: RequestModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'accesscertificate', loadChildren: AccesscertificationModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  {path: 'compliance', loadChildren: ComplianceModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'jobs', loadChildren: JobsModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'hrmsconfig', loadChildren: HrmsconfigModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'mfa',  loadChildren: MfaModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'mobileapp',  loadChildren: MobileappModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'accesscampgainreport', loadChildren: accesscampgainModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'activecampaign',   loadChildren:activecampgainModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] }, 
  //{ path: 'configuration',   loadChildren:configurationModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'hrmsuser',   loadChildren:HrmsuserModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'task',   loadChildren:taskModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'configuration',   loadChildren:configurationModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'endpoint',   loadChildren:endpointModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },  
  {path: 'reconexception', loadChildren:reconcilationModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'pam', loadChildren: PamModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'auditlog', loadChildren: AuditlogModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'dashboard', loadChildren: DashboardModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  {path:'endpointaccounttype', loadChildren:endpointaccounttypemodule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  {path:'mail', loadChildren:emailmodule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  {path:'reports', loadChildren:reportsmodule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  {path:'accessreview', loadChildren:AccessReviewModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  {path:'ucf', loadChildren:UcfModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'accounts', loadChildren: accountsModule, canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'security', loadChildren: securityModule, canActivate: [AuthGuard],canActivateChild:[AuthGuard] },  
  { path: 'upload', loadChildren: UploadModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard]},
  { path: 'organisation', loadChildren: OrganisationModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'trustedsourceevents', loadChildren: TrustedsourceeventModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  {path:'log', loadChildren:LogModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'passwordsync', loadChildren: passwordsyncModule , canActivate: [AuthGuard],canActivateChild:[AuthGuard]},
  { path: 'sso', loadChildren: SsoModule , canActivate: [AuthGuard],canActivateChild:[AuthGuard]},
  { path: 'report', loadChildren: reportModule , canActivate: [AuthGuard],canActivateChild:[AuthGuard]},
  { path: 'license', loadChildren: licenseModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'mywebaccess', loadChildren: MywebapplicationModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  {path:'dacmaccessreview', loadChildren:DACMAccessReviewModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'applicationcategory', loadChildren: applicationcategoryModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  {path:'datamanagement', loadChildren:dataManagementModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
   {path:'pf',loadChildren:pf,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
   { path: 'acl',   loadChildren:aclModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
   { path: 'rolemanagement',   loadChildren:RoleManagementModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
   { path: 'manageagentpage',   loadChildren:manageagentpageModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
   

    //Please keep this route at the end always
  {path:'myschedulehistory', loadChildren:myschedulehistoryModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  {path: 'notificationlist', loadChildren:notificationlistModule,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'technicalrole', loadChildren: TechnicalRoleModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  { path: 'businessrole', loadChildren: BusinessRoleModule ,canActivate: [AuthGuard],canActivateChild:[AuthGuard] },
  //Please keep this route at the end always
  {path:'**',redirectTo:'home'},
 
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  
}
