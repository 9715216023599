import { EventEmitter, Injectable, Output, Pipe, PipeTransform } from '@angular/core';
import { HomeService } from 'app/modules/home/service/homeservice.service';
import { svgIconJson } from 'assets/images/AzureSvgIcons/svgIconList';
import { truncate } from 'fs';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Pipe({
    name: 'loadsvgicon',
    pure: true
})
export class LoadSVGIconsPipe implements PipeTransform {

    constructor() {

    } 
    transform(imageUrl: any): any {


        if(imageUrl == null || imageUrl == '' || imageUrl == undefined)
        imageUrl = 'accessControl';

        return imageUrl 

    }


}


