import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FalainaloadingsymbolComponent } from './falainaloadingsymbol.component';
import { FalainaloadingComponent } from './falainaloading/falainaloading.component'; 


@NgModule({
  declarations: [FalainaloadingsymbolComponent, FalainaloadingComponent],
  imports: [
    CommonModule,
  ],
  exports : [FalainaloadingsymbolComponent,FalainaloadingComponent]
})
export class FalainaloadingsymbolModule { }
